import React, { useState } from 'react';
import { TbDotsVertical } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { FaRegTrashAlt } from "react-icons/fa";
import { FiEdit3 } from "react-icons/fi";
export const NoticeItem = (props) => {
  let navigator = useNavigate();
  // let noticeList = {
  //   "NoticePostId": 1,
  //   "Title": "공지입니다.",
  //   "Contents": `공지글`,
  //   "OwnerId": "kassid"
  // }
  const[btnOpen, setBtnOpen] = useState(false);

  const edit = ()=>{
    navigator('/cpo/notice/edit', {state:{Title:props.data.Title, Contents:props.data.Contents}})
  }
  return (
    <>
      <div className={"cpo-notice-item-container"} onClick={()=>{navigator(`./detail/${props.data.NoticePostId}`, {state: {data:props.data}});}}>
        <div className='cpo-notice-item-owner-container'>
          <div className="cpo-notice-item-owner"><p>공지</p></div>
        </div>
        <div className="cpo-notice-item-category-title-container"><p>{`${props.data.Title}`}</p></div>
        <div className="cpo-faq-item-function-button-container" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); setBtnOpen(!btnOpen);}}>
          <div className={"cpo-fap-item-function-button-list "+ `${btnOpen ? "open":""}`}>

            <div>
              <div className="cpo-fap-item-function-button" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); edit();}}>
                <div><FiEdit3/></div>
              </div>
            </div>

            <div>
              <div className="cpo-fap-item-function-button delete" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
                <div><FaRegTrashAlt/></div>
              </div>
            </div>

          </div>
          <TbDotsVertical/>
        </div>
      </div>
    </>
  )
}
export default NoticeItem;