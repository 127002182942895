import React from 'react';
import { CiWarning } from "react-icons/ci";

export const AlertMsgModal = (props) => {

  return (
    <div className="cpo-alert-msg-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-alert-msg-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-alert-msg-modal-header">
          <title>알림</title>
        </div>
        <div className='cpo-alert-msg-modal-content'>
          {
            <>
              <>
                <div className='cpo-alert-msg-modal-content delete'>
                  <div className='cpo-alert-msg-modal-delete-alert'>
                    <CiWarning/>
                  </div>
                  <div className='cpo-alert-msg-modal-delete-msg'><p>{props.data.Message}</p></div>
                  {/* <div className='cpo-alert-msg-modal-delete-info'><p>{}</p></div> */}
                </div>
              </>
            </>
            
          }
          <div className='cpo-alert-msg-modal-delete-button' onClick={(e)=>{e.stopPropagation();e.preventDefault();props.setOnOff(false);}}><p>확인</p></div>

        </div>
        

      </div>
    </div>
  )
}
export default AlertMsgModal
