import React from 'react';
import { useNavigate } from 'react-router-dom';

import { TfiClose } from "react-icons/tfi";

export const PaymentMethodAlert = (props) => {
  let navigator = useNavigate();

  return (
    <div className="cpo-payment-method-alert-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); }}>
      <div className="cpo-payment-method-alert-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-payment-method-alert-header">
          <title>결제수단 확인</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <>
          <div className='cpo-payment-method-alert-alert-msg'>
            등록된 결제수단이 없습니다.
          </div>
          {/* <div className='cpo-payment-method-alert-grid'>
            <div>
              <p>등록된 충전기 수</p>
              <p>{props.total}</p>
            </div>
            <div>
              <p>최대 충전기 수</p>
              <p>{props.limit}</p>
            </div>
          </div> */}
          <div>
            결제수단 등록 후 충전기를 운용해주세요.
          </div>
          <div className='cpo-payment-method-alert-button-container'>
            <div className={'cpo-payment-method-alert-button'} onClick={()=>{navigator("/cpo/payment-method");}}><p>결제수단 페이지로</p></div>
          </div>
          </>
      </div>
    </div>
  )
}
export default PaymentMethodAlert