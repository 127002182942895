import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import {setNum} from '../../store.js'
import axios from 'axios';
import { setCookie, getCookie } from '../../util/util';

import CpoInstallListItem from '../../components/list/CpoInstallListItem.js';
import CpoInstallEnrollModal from '../../components/modals/CpoInstallEnrollModal.js';
import CpoInstallAdminEnrollModal from '../../components/modals/CpoInstallAdminEnrollModal.js';
const CpoInstallManagement = () => {
  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setNum({id:4}));
  },[])
  const signedToken =  getCookie('signedToken')

  let installCompanyData=[
    {
        "name": "설치업체A",
        "category": "윈치설치",
        "adminInfo": [
            {
                "adminName": "오하윤",
                "role": "관리자",
                "phoneNumber": "010-1084-4306",
                "department": "기술팀"
            },
            {
                "adminName": "이재윤",
                "role": "관리자",
                "phoneNumber": "010-1084-4306",
                "department": "기술팀"
            },
        ]
    },
    {
        "name": "설치업체B",
        "category": "충전기설치",
        "adminInfo": [
            {
                "adminName": "장지우",
                "role": "관리자",
                "phoneNumber": "010-9407-4937",
                "department": "재무팀"
            },
            {
                "adminName": "오지훈",
                "role": "관리자",
                "phoneNumber": "010-8304-4937",
                "department": "재무팀"
            },
            {
                "adminName": "이해준",
                "role": "관리자",
                "phoneNumber": "010-9868-7694",
                "department": "재무팀"
            },
        ]
    },
    {
        "name": "설치업체D",
        "category": "캐노피설치",
        "adminInfo": [
            {
                "adminName": "이도윤",
                "role": "관리자",
                "phoneNumber": "010-1584-9058",
                "department": "기술팀"
            },
            {
                "adminName": "이소훈",
                "role": "관리자",
                "phoneNumber": "010-9404-9058",
                "department": "기술팀"
            }
        ]
    },
    {
        "name": "설치업체E",
        "category": "충전기설치",
        "adminInfo": [
            {
                "adminName": "정태양",
                "role": "관리자",
                "phoneNumber": "010-1569-6642",
                "department": "마케팅팀"
            }
        ]
    }
  ]


  const [deleteMode, setDeleteMode] = useState(false);
  const [companyEnrollModalOn, setCompanyEnrollModalOn] = useState(false);
  const [adminEnrollModalOn, setAdminEnrollModalOn] = useState(false);


  // check한 charger list
  const [checkItemList, setCheckedItemList] = useState([]);



  const deleteItem = async()=>{
    console.log(checkItemList);
    // setDeleteMode(!deleteMode);
  }

  return (
    <div>
      <div className='cpo-install-management-container'>
        {companyEnrollModalOn&&<CpoInstallEnrollModal setOnOff={setCompanyEnrollModalOn} />}
        {adminEnrollModalOn&&<CpoInstallAdminEnrollModal setOnOff={setAdminEnrollModalOn} />}
        <div className="cpo-install-management-inner">
          <div className="cpo-install-function-button-container">
            <div className='cpo-install-function-button enroll' onClick={()=>{setCompanyEnrollModalOn(true)}}>업체등록</div>
            <div className={'cpo-install-function-button delete ' + `${deleteMode ? "activate" :""}`} onClick={()=>{if(!deleteMode){setDeleteMode(true)}else{deleteItem();}}}>삭제</div>
            {deleteMode&&<div className='cpo-install-function-button cancel' onClick={()=>{setCheckedItemList([]); setDeleteMode(false)}}>취소</div>}
            {deleteMode&&<p>삭제할 설치업체를 선택해주세요</p>}
          </div>
          <div className="cpo-install-list-container">
            {
              installCompanyData.map((it, idx)=>{
                return(<CpoInstallListItem data={it} idx={idx} deleteMode={deleteMode} checkItemList={checkItemList} setCheckedItemList={setCheckedItemList} setAdminEnrollModalOn={setAdminEnrollModalOn}/>)
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoInstallManagement;