import {Cookies} from 'react-cookie';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { useEffect } from 'react';
const cookies = new Cookies();

export const setCookie = (name, value, options) => {
  return cookies.set(name, value, {...options}); 
}

export const getCookie = (name) => {
  return cookies.get(name); 
}
export const removeCookie = (name) => {
  cookies.remove('signedToken', { path: '/' });
}

export const chargerTypeConverter = (type)=>{
  switch (type) {
    case "FAST_CHARGER":
      return '고속';
  
    case "SLOW_CHARGER":
      return '완속';
    // TODO 중속은??????
    default:
      return "-"
  }
}

export const connectorTypeConverter = (type)=>{
  switch (type) {
    case "DC_Chademo":
      return ['DC차데모'];
    case "AC_Slow":
      return ['AC완속'];
    case "DC_Chademo_AC_ThreePhase":
      return ['DC차데모','AC3상'];
    case "DC_Combo":
      return ['DC콤보'];
    case "DC_Chademo_DC_Combo":
      return ['DC차데모','DC콤보'];
    case "DC_Chademo_AC_ThreePhase_DC_Combo":
      return ['DC차데모','AC3상','DC콤보'];
    case "AC_ThreePhase":
      return ['AC3상'];
    case "DC_Combo_Slow":
      return ['DC콤보(완속)'];
    default:
      return type;
  }

}

export const chargerStatusConverter = (status)=>{
  switch (status) {
    case 'NotUsed':
      return '연결해제'
    case 'ReadyForConnection':
      return '연결대기'
    case 'Connected':
      return '연결됨'
    case 'Available':
      return '사용가능'
    case 'Preparing':
      return '충전준비'
    case 'Charging':
      return '충전중'
    case 'SuspendedEVSE':
      return '충전중지'
    case 'SuspendedEV':
      return '충전중지'
    case 'Finishing':
      return '충전종료'
    case 'Reserved':
      return '예약중'
    case 'Unavailable':
      return '사용중지'
    case 'Faulted':
      return '고장'
    case 'Deleted':
      return '삭제'
    default:
      return '연결해제';
  }
}

export const occpConverter = (val)=>{
  return val.slice(5).replaceAll('_',".");
}



export const refresh = async()=>{
  if(getCookie('signedToken'))return;
  await axios.post('/account/api/NearCpoAuth/refresh-token',{withCredentials : true})
  .then((res)=>{
    console.log(res);
    setCookie('signedToken', res.data.SignedToken, {
      path: '/',
      expires: new Date(res.data.ExpiryTime)
    });
  })
  .catch((err)=>{
    console.log(err);
    let a = getCookie('refreshToken');
    console.log(a);
  })
}





export const refresh2 = async()=>{
  await axios.post('/account/api/NearCpoAuth/refresh-token',{withCredentials : true})
  .then((res)=>{
    console.log(res);
    setCookie('signedToken', res.data.SignedToken, {
      path: '/',
      expires: new Date(res.data.ExpiryTime)
    });
  })
  .catch((err)=>{
    console.log(err);
    window.location.replace('/login');
  })
}
export const signedOut = async()=>{
  let signedToken = getCookie('signedToken');

  await axios.post(`/account/api/NearCpoAuth/signout`,{},{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    console.log(res);
    removeCookie();
    localStorage.removeItem('LocalRealName');
    window.location.replace('/');
  })
  .catch((err)=>{
    console.log(err);
  })
}

export const getCpoId = ()=>{
  let signedToken = getCookie('signedToken');
  if(!signedToken)return;
  const claims = jwtDecode(signedToken);
  return claims.CpoId;
}


export function timeInput(time) {
  time.value = time.value.replace(/[^0-9]/g, '');
  var replaceTime = time.value.replace(/\:/g, "");
  var hours = replaceTime.substring(0, 2); // 2400->24
  var minute = replaceTime.substring(2, 4); //2400->00

  if(replaceTime.length >= 4) {
      time.value = hours + " : " + minute;
      
      if(hours + minute >= 2400) {
        time.value = "00:00";
          return false;
      }else if(minute >= 60) { 
          time.value = hours + ":00";
          return false;
      }
  }
}

export function numberInput(target) {
  const inputValue = target.value;

  // 양의 실수인지 확인하는 정규 표현식
  const validNumberRegex = /^(?!0\d)\d*\.?\d*$/;

  // 입력값이 양의 실수가 아니면 빈 문자열로 대체
  if (!validNumberRegex.test(inputValue)) {
    target.value = inputValue.slice(0, -1); // 마지막 입력 문자를 제거
  }
}

export const getTimeZone = (curr)=>{
  const utc = curr.getTime() + (curr.getTimezoneOffset() * 60 * 1000);
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
  return new Date(utc + KR_TIME_DIFF + KR_TIME_DIFF);
}
export const setTimeZone = (curr)=>{
  const utc = curr.getTime() + (curr.getTimezoneOffset() * 60 * 1000);
  return new Date(utc);
}
export const dateFormat = (date)=> {
  try {
    date = getTimeZone(date);
    let dateFormat2 = date.getFullYear() +
      '-' + ( (date.getMonth()+1) < 10 ? "0" + (date.getMonth()+1) : (date.getMonth()+1) )+
      '-' + ( (date.getDate()) < 10 ? "0" + (date.getDate()) : (date.getDate()) );
    return dateFormat2;
  } catch (error) {
    return ""
  }
}
export const dateFormat2 = (date)=> {
  try {
    let dateFormat2 = date.getFullYear() +
      '-' + ( (date.getMonth()+1) < 10 ? "0" + (date.getMonth()+1) : (date.getMonth()+1) )+
      '-' + ( (date.getDate()) < 10 ? "0" + (date.getDate()) : (date.getDate()) );
    return dateFormat2;
  } catch (error) {
    return ""
  }
}

export const timestampFormat = (date)=> {
  date = getTimeZone(date);
  try {
    let dateFormat2 = date.getFullYear() +
      '-' + ( (date.getMonth()+1) < 10 ? "0" + (date.getMonth()+1) : (date.getMonth()+1) )+
      '-' + ( (date.getDate()) < 10 ? "0" + (date.getDate()) : (date.getDate()) ) +
      ' ' + ( (date.getHours()) < 10 ? "0" + (date.getHours()) : (date.getHours()) ) +
      ':' + ( (date.getMinutes()) < 10 ? "0" + (date.getMinutes()) : (date.getMinutes()) );
    return dateFormat2;
  } catch (error) {
    return ""
  }
}
export const timestampFormat2 = (date)=> {
  date = getTimeZone(date);
  try {   
    let dateFormat2 = date.getFullYear() +
    '-' + ( (date.getMonth()+1) < 10 ? "0" + (date.getMonth()+1) : (date.getMonth()+1) )+
    '-' + ( (date.getDate()) < 10 ? "0" + (date.getDate()) : (date.getDate()) ) +
    ' ' + ( (date.getHours()) < 10 ? "0" + (date.getHours()) : (date.getHours()) ) +
    ':' + ( (date.getMinutes()) < 10 ? "0" + (date.getMinutes()) : (date.getMinutes()) )+
    ':' + ( (date.getSeconds()) < 10 ? "0" + (date.getSeconds()) : (date.getSeconds()) )+
    ':' + ( (date.getMilliseconds()) < 10 ? "0" + (date.getMilliseconds()) : (date.getMilliseconds()) )
    ;
  return dateFormat2;
  } catch (error) {
    return ""
  }
}




// Email Validation Pattern
export const emailInputValidation = (value)=>{
  const pattern = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-za-z0-9\-]+/;
  if(pattern.test(value) === false) {
    return false
  }
  else {
    return true
  }
}

export const exceptDeletedStation = (stationList)=>{
  return [...stationList.data.Results].filter(it=>it.OpStatus !== "삭제됨");
}



