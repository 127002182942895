// /subscription/api/cpouser/Subscriptions/ChargerLimit
import axios from 'axios';
import { getCookie } from '../../util/util';

const signedToken =  getCookie('signedToken')


// cpo 관리가능 충전기 개수 조회
export const getChargerLimit = async()=>{  
  let temp;
  const signedToken =  getCookie('signedToken')
  await axios.get(`/subscription/api/cpouser/Subscriptions/ChargerLimit`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res.data;
  })
  .catch(()=>{})
  return temp;
}
