import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { FaCheck, FaCaretDown } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

import Selector2 from '../buttons/Selector2';

import { TfiClose } from "react-icons/tfi";


import { getAllCharger, postCharger } from '../../api/infra/infraApi.js';
import { getChargerLimit } from '../../api/subscription/subscriptionApi.js';

export const ChargerEnrollModal = (props) => {
  let navigator = useNavigate();

  const [chargerSerial, setChargerSerial] = useState("");

  useEffect(()=>{
    console.log(props.stationInfo.ChargingStationId);
    reqGetChargerLimit();
  },[])
  
  const reqGetChargerLimit = async()=>{
    let chargerResult = await getAllCharger();
    let limitCount = await getChargerLimit();
    console.log(chargerResult.Total, limitCount); //{count : 3}
    setChargerTotal(chargerResult.Total);
    setChargerLimit(limitCount.count);
  }
  const [chargerTotal, setChargerTotal] = useState();
  const [chargerLimit, setChargerLimit] = useState();

  // 선택자 클릭 여부
  const [occpActive, setOccpActive] = useState('');
  const [occpSelect, setOccpSelect] = useState('none');
  const occpList = [{val:"OCPP_1_6",name:"OCPP 1.6"},{val:"OCPP_2_0_1",name:"OCPP 2.0.1"}];
  const activeReset=()=>{
    setOccpActive(false);
  }
  const reqAddCharger = async()=>{
    await postCharger(
      { 
        chargingStationId : props.stationInfo.ChargingStationId,
        chargePointSerialNumber: chargerSerial,
        protocol: occpSelect.val,
      }
    );
    props.setOnOff(false);
    await props.getStationList();
  }


  return (
    <div className="cpo-charger-enroll-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); }}>
      <div className="cpo-charger-enroll-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-charger-enroll-modal-header">
          <title>충전기 등록</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        {
          chargerTotal&&chargerLimit&&chargerTotal>=chargerLimit ?
          <>
          <div className='cpo-charger-enroll-modal-alert-msg'>
            등록 가능한 충전기 수 한계에 도달하였습니다.
          </div>
          <div className='cpo-charger-enroll-modal-grid'>
            <div>
              <p>등록된 충전기 수</p>
              <p>{chargerTotal}</p>
            </div>
            <div>
              <p>최대 충전기 수</p>
              <p>{chargerLimit}</p>
            </div>
          </div>
          <div>
            충전기를 삭제하거나 상위 구독으로 등록 개수를 늘려주세요.
          </div>
          <div>
            <div></div>
            <div className={'cpo-charger-modal-enroll-button subscription'} onClick={()=>{navigator("/cpo/subscription");}}><p>구독상품 보러가기</p></div>
          </div>
          </>
          :
          <>
            <div><p>충전기 하드웨어 일련번호</p></div>
            <div className="cpo-charger-enroll-modal-input-container">
              <input type="text" placeholder='' value={chargerSerial} onChange={(e)=>{ setChargerSerial(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
            <div><p>OCPP 프로토콜</p></div>
            <Selector2 name={'name'} dataList={occpList} select={occpSelect} setSelect={setOccpSelect} active={occpActive} setActive={setOccpActive} activeReset={activeReset}/>
            <div>
              <div></div>
              <div className={'cpo-charger-modal-enroll-button '+ `${chargerSerial == "" || occpSelect=="none" ?"none":""}`} onClick={()=>{reqAddCharger()}}><p>등록</p></div>
            </div>
          </>  
        }
      </div>
    </div>
  )
}
export default ChargerEnrollModal

// {"ErrorCode":"CHARGER_LIMIT_EXCEEDED","Message":"충전기 한도를 초과했습니다."}