import React, { useState, useEffect } from 'react';
const CustomChasItemList = (props)=>{

  const [checked, setChecked] = useState(false);
  
  useEffect(()=>{
    // 리스트에서 인덱스 찾기
    let itemIdx = props.checkList.indexOf(props.idx);
    // check true인 경우
    if(checked){
      if(itemIdx !== -1){
        return;
      }
      if(props.settingMode === "edit"){
        props.setCheckList([props.idx]);
      }
      else{
        props.setCheckList([...props.checkList, props.idx]);
      }
    }
    else{
      // false 인 경우
      
      // 만약 없는 경우
      if(itemIdx === -1){
        return;
      }
      // 있는 경우
      else{
        let temp = [...props.checkList];
        temp.splice(itemIdx,1);
        props.setCheckList(temp);
      }
    }
  },[checked])

  useEffect(()=>{
    let itemIdx = props.checkList.indexOf(props.idx);
    if(itemIdx !== -1 && !checked){
      setChecked(true);
    }
    else if(itemIdx === -1 && checked){
      setChecked(false);
    }
  },[props.checkList])
  return(
    <>
      <div className={`cpo-custom-chas-content-item ${props.settingMode?"edit":""} ${checked?"selected":""}`} onClick={()=>{setChecked(!checked)}}>
        {
          props.settingMode!==null &&
          <div>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
              <span className="checkbox_icon"></span>
            </label>
          </div>
        }
        <div><p>{props.data.name}</p></div>
        <div><p>{props.data.version?props.data.version:"0.0.1"}</p></div>
        <div><p>{props.data.ocpp?props.data.ocpp:"1.6"}</p></div>
        <div>
          <div className="cpo-custom-chas-content-toggle">

          </div>
        </div>
      </div>
    </>
  );
}

export default CustomChasItemList;