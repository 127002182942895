import React, { useState, useEffect } from 'react';
import { BsFillPersonPlusFill } from "react-icons/bs";
import { FaChevronDown } from "react-icons/fa6";

import CpoInstallAdminItem from './CpoInstallAdminItem';

export const CpoInstallListItem = (props) => {

  const [tabOpen, setTabOpen] = useState(false)

  // checked 상태
  const [checked, setChecked] = useState(false);
  
  useEffect(()=>{
    // 리스트에서 인덱스 찾기
    let itemIdx = props.checkItemList.indexOf(props.idx);
    // check true인 경우
    if(checked){
      if(itemIdx !== -1){
        return;
      }
      props.setCheckedItemList([...props.checkItemList, props.idx]);
    }
    else{
      // false 인 경우
      
      // 만약 없는 경우
      if(itemIdx === -1){
        return;
      }
      // 있는 경우
      else{
        let temp = [...props.checkItemList];
        temp.splice(itemIdx,1);
        props.setCheckedItemList(temp);
      }
    }
  },[checked])
  
  useEffect(()=>{
    let itemIdx = props.checkItemList.indexOf(props.idx);
    if(itemIdx !== -1 && !checked){
      setChecked(true);
    }
    else if(itemIdx === -1 && checked){
      setChecked(false);
    }
  },[props.checkItemList])

  return (
    <div className={'cpo-install-list-item-container ' + `${tabOpen ? "open":""}`}>
      <div className="cpo-install-list-item-header" onClick={(e)=>{e.stopPropagation(); setTabOpen(!tabOpen);}}>
        <div className="cpo-install-list-item-title">
          <div className={`${props.deleteMode ? "visible":""}`}>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
              <span className="checkbox_icon"></span>
            </label>
          </div>
          <p className='cpo-install-list-item-company-name'>{props.data.name}</p>
          <p className='cpo-install-list-item-company-category'>{props.data.category}</p>
        </div>
        <div className="cpo-install-list-item-admin-enroll-container">
          <div className="cpo-install-list-item-admin-enroll-button" onClick={(e)=>{e.stopPropagation(); props.setAdminEnrollModalOn(true)}}>
            <BsFillPersonPlusFill/>
            <p>담당자 추가</p>
          </div>
        </div>
        <div className="cpo-install-list-item-chevron-container"><FaChevronDown/></div>
      </div>
      <div className="cpo-install-list-item-content">
        <div className="cpo-install-list-item-admin-item-column">
          <p>담당자 이름</p>
          <p>직책</p>
          <p>휴대전화번호</p>
          <p>부서이름</p>
        </div>
        {
          props.data.adminInfo.map((it, idx)=>{
            return(
              <>
                <CpoInstallAdminItem data={it}/>
              </>
            );
          })
        }
      </div>
    </div>
  )
}
export default CpoInstallListItem;