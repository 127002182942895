import axios from 'axios';

import { getCookie, refresh } from '../../util/util';

const signedToken =  getCookie('signedToken')


export const getChargingTransactions = async(page, count, query)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/controlhub/api/cpouser/v1/ChargingTransactions?Size=${count}&Page=${page}${query}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res.data;
  })
  .catch((err)=>{
    console.log(err);
  })
  return temp;
}

export const getOccpMessageHistoriesList = async(page, query)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/controlhub/api/cpouser/v1/OcppMessageHistories?Size=15&Page=${page}${query?query:""}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res.data;
  })
  .catch(()=>{})
  return temp;
}
export const getOccpActionMessageHistoriesList = async(id)=>{
  const signedToken =  getCookie('signedToken')
  let temp = [];
  await axios.get(`/controlhub/api/cpouser/v1/OcppActionHistories?ocppTransactionId=${id}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res.data;
  })
  .catch(()=>{})
  return temp;
}

export const getOccpMessageHistoriesByUniqueId = async(id)=>{
  const signedToken =  getCookie('signedToken')

  let temp = [];
  await axios.get(`/controlhub/api/cpouser/v1/OcppMessageHistories?UniqueId=${id}&SortOrder=Asc`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res.data;
  })
  .catch(()=>{})
  return temp;
}

export const getChargerHub = async(id)=>{
  const signedToken =  getCookie('signedToken')
  let temp;
  await axios.get(`/controlhub/api/cpouser/v1/Chargers?chargingStationId=${id}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp=res;
  })
  .catch((err)=>{
    temp=err.response;
  })
  return temp;
}

// chargerHub 정보 API GET
export const getChargerHubList = async(stationList)=>{
  const signedToken =  getCookie('signedToken')
  let temp = [];
  try {
    for await(const it of stationList){
      await axios.get(`/controlhub/api/cpouser/v1/Chargers?chargingStationId=${it.ChargingStationId}`,{
        headers: {
          Authorization: `Bearer ${signedToken}`
        }
      })
      .then((res)=>{
        temp.push(res.data);
      })
      .catch(()=>{
      })
    }
  } catch (err) {
    temp = err.response;
  }
  return temp;
}


export const getTagAuthorization = async(page, query)=>{
  const signedToken =  getCookie('signedToken')
  let temp = [];
  console.log(query);

  await axios.get(`/controlhub/api/cpouser/v1/TagAuthorization?Size=15&Page=${page}${query?query:""}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  }).then((res)=>{
    temp = res.data;
  })
  .catch(()=>{})
  return temp;
}

export const getChargerStatusHistories = async(page , query)=>{
  const signedToken =  getCookie('signedToken')
  console.log(page)
  let temp = [];

  await axios.get(`/controlhub/api/cpouser/v1/ChargerStatusHistories?Size=15&Page=${page}${query?query:""}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  }).then((res)=>{
    temp = res.data;
  })
  .catch(()=>{})
  return temp;
}
export const getChargerErrorHistories = async(page , query)=>{
  const signedToken =  getCookie('signedToken')
  let temp = [];

  await axios.get(`/controlhub/api/cpouser/v1/ChargerStatusHistories?Status=Faulted&Size=15&Page=${page}${query?query:""}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  }).then((res)=>{
    temp = res.data;
  })
  .catch(()=>{})
  return temp;
}


// Chas 리스트 get
export const getChasList = async()=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/controlhub/api/cpouser/v1/ActionSets`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}

// Chas 추가
export const postChas = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {name, description} = data;
  await axios.post(`/controlhub/api/cpouser/v1/ActionSets`,{
    "name": name,
    "description": description
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    // console.log(res.data)
    // props.getCHASList();
    // props.setOnOff(false);
    temp = res;
  })
  .catch((err)=>{temp = err.response})
  return temp;
}


// 이름으로 Chas Get
export const getChasByName = async(chasName)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/controlhub/api/cpouser/v1/ActionSets/Validation-Name?name=${chasName}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    console.log(res);
    temp = res;
  })
  .catch((err)=>{temp = err.response})
  return temp;
}

// 특정 ActionSet GET
export const getChas = async(actionSetId)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/controlhub/api/cpouser/v1/ActionSets/${actionSetId}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{temp = err.response})
  return temp;
}
// 모든 Action GET
export const getActions = async()=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get('/controlhub/api/ControlHubActions',{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{temp = err.response})
  return temp;
}
// 액션셋>특정 메세지의 액션리스트 GET
export const getActionOfMessageList = async(actionSetId, messageType)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/controlhub/api/cpouser/v1/ActionSets/${actionSetId}/${messageType}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{temp = err.response})
  return temp;
}

// 액션셋>DataTransfer 액션리스트 PUT
export const putActionOfDataTransferMessage = async(actionSetId, messageType, data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let {controlHubActionInfoIds, dataTransferMessageId} = data;
  let temp;
  await axios.put(`/controlhub/api/cpouser/v1/ActionSets/${actionSetId}/${messageType}`,{
    "controlHubActionInfoIds": controlHubActionInfoIds,
    "dataTransferMessageId": dataTransferMessageId,
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{temp = err.response})
  return temp;
}

// 액션셋>특정 메세지의 액션리스트 PUT
export const putActionOfMessageList = async(actionSetId, messageType, data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let {controlHubActionInfoIds} = data;
  let temp;
  await axios.put(`/controlhub/api/cpouser/v1/ActionSets/${actionSetId}/${messageType}`,{
    "controlHubActionInfoIds": controlHubActionInfoIds
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{temp = err.response})
  return temp;
}

// 특정 ActionSet Delete
export const deleteChas = async(actionSetId)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.delete(`/controlhub/api/cpouser/v1/ActionSets/${actionSetId}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{temp = err.response})
  return temp;
}