import React, {useState} from 'react'

import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import topBg from '../../img/ocppServiceHeader.png'
import cpoService_1 from '../../img/cpoService_1.svg'
import cpoService_2 from '../../img/cpoService_2.svg'
import cpoService_3 from '../../img/cpoService_3.svg'
import cpoService_4 from '../../img/cpoService_4.svg'
import cpoService_5 from '../../img/cpoService_5.png'
import cpoService_6 from '../../img/cpoService_6.png'
import cpoService_7 from '../../img/cpoService_7.png'
import cpoService_8 from '../../img/cpoService_8.png'
import cpoService_9 from '../../img/cpoService_9.png'
import cpoService_10 from '../../img/cpoService_10.png'
import cpoServiceBg from '../../img/cpoServiceBg.png'

import cpoServiceSlider_1 from '../../img/cpoServiceSlider_1.png'
import cpoServiceSlider_2 from '../../img/cpoServiceSlider_2.png'
import cpoServiceSlider_3 from '../../img/cpoServiceSlider_3.png'
import cpoService_diagram from '../../img/cpoService_diagram.png'


import ocppServiceFlow from '../../img/ocppServiceFlow.svg'
import ocppCheck from '../../img/ocppCheck.svg'
import ocppServiceUsage from '../../img/ocppServiceUsage.svg'


export const OcppService = () => {

  const [sliderIdx, setSliderIdx] = useState(0);
  
  const [sliderImageList, setSliderImageList] = useState([cpoServiceSlider_1, cpoServiceSlider_2, cpoServiceSlider_3]);
  let navigator = useNavigate();
  return (
    <div className='main-introduce-container'>
      {/* header */}
      <div className='main-introduce-header'>
        <div className="main-introduce-bg">
          <img src={topBg} alt="" />
        </div>
        <div className="main-introduce-title-container">
          <h1>OCPP 프록시 서비스</h1>
          <p>OCPP Proxy Service</p>
          <div className="main-introduce-subscription-button" onClick={()=>{navigator("/service-pricing");}}><p>구독 상품 보러 가기</p></div>
        </div>
      </div>
      {/* content */}
      <div className="main-introduce-content-section">

        <div className="main-introduce-text-title-block-container">
          <p>OCPP 프록시 서비스</p>
          <h2>
          충전기와 다른 통신규격을 제공하는 CSMS를<br/>SW수정 없이 연결하는 가장 빠른 방법.
          </h2>

        </div>
        <div className='main-introduce-ocpp-flow-container'>
          <img src={ocppServiceFlow} alt="" />

          <div className='main-introduce-ocpp-info-wrapper'>
            <div className='main-introduce-ocpp-info-container'>
              <img src={ocppCheck} alt="" />
              <div>
                <p>설정 주입을 통한 <color>메시지 교환</color></p>
                <pre>{'OCPP 프록시의 행위는 소스코드 수정없이 설정파일을 수정하거나\n교체하는 것으로 쉽게 수정할 수 있습니다.'}</pre>
              </div>
            </div>
            <div className='main-introduce-ocpp-info-container'>
              <img src={ocppCheck} alt="" />
              <div>
                <p>메시지 <color>중계 및 변환</color></p>
                <pre>{'충전기와 CSMS사이의 메시지를 실시간으로 중개하고,\n필요한 경우 메시지의 형식이나 내용을 변환하여 전달합니다.'}</pre>
              </div>
            </div>
            <div className='main-introduce-ocpp-info-container'>
              <img src={ocppCheck} alt="" />
              <div>
                <p>자동 <color>메시지 전송</color></p>
                <pre>{'충전기 또는 CSMS가 보내지 못하는 메시지라도 OCPP 프록시는\n정해진 조건에 따라 자동으로 메시지를 생성하여 송신할 수 있습니다.'}</pre>
              </div>
            </div>
          </div>

        </div>

        <div className='main-introduce-spacer'></div>
        <div className='main-introduce-spacer'></div>



        <div className='main-introduce-gray-bg-wrapper'>
          <div className="main-introduce-text-title-block-container">
            <p>왜 OCPP 프록시 서비스를 사용해야 할까?</p>
            <h2>
              특정 충전사업자를 위해서 개발된 충전기를<br/>
              수정 없이 다양한 CSMS와 연결할 수 있어<br/>
              <color>수정에 대한 제한 없이 사업영역을 확대.</color>
            </h2>
          </div>

          <div className="main-introduce-ocpp-service-usage-container">
            <img src={ocppServiceUsage} alt="" />
          </div>
        
          <div className='main-introduce-spacer'></div>
          <div className='main-introduce-spacer'></div>

          <div className='main-introduce-go-to-subscription-container'>
            <pre>{'당신에게 딱 맞는 구독서비스를\n지금 선택해보세요.'}</pre>
            <div className='main-introduce-go-to-subscription-button' onClick={()=>{navigator("/service-pricing");}}>구독 상품 확인</div>
          </div>
        </div>


      </div>
    </div>
  )
}
export default OcppService;



export const ImageBlock = (props)=>{
  return(
    <div className={'main-introduce-image-block-container '+`${props.oneLine ? "oneline":""}`}>
      <img src={props.data.imgUrl} />
      <>{props.data.content}</>
    </div>
  )
}


export const ServiceBlock = (props)=>{
  return(
    <div className={'main-introduce-service-block-container '}>
      <div className='main-introduce-service-block-image-header'>
        <img src={props.data.imgUrl} />
      </div>
      <div className='main-introduce-service-block-content-container'>
        <p>{props.data.title}</p>
        <pre>{props.data.content}</pre>
      </div>
    </div>
  )
}