import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import {setNum} from '../../store.js'
import axios from 'axios';
import { setCookie, getCookie } from '../../util/util';

import UploadBox from '../../components/buttons/UploadBox.js';

export const CpoBusinessAuthorize = () => {
  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setNum({id:13, idx:1}));
  },[])

  const [file1, setFile1] = useState(null);

  return (
    <div className='cpo-account-management-container'>
      <div className="cpo-account-management-inner">
        <div className="cpo-business-authorize-container">
          <div>
            <title>현재 정보</title>
            <div className="cpo-business-authorize-content-container">
              <p>사업자명</p>
              <div>
                <p>기능 업데이트 예정입니다.</p>
              </div>
              <p>사업자 번호</p>
              <div>
                <p>기능 업데이트 예정입니다.</p>
              </div>
              <p>주소</p>
              <div>
                <p>기능 업데이트 예정입니다.</p>
              </div>
            </div>
          </div>
          <div>
            <title>사업자 등록 수정</title>
            <div className="cpo-business-authorize-content-container">
              <p>파일을 업로드하세요</p>
              <div className='cpo-business-authorize-file-input-container'>
                <UploadBox setFile={setFile1}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoBusinessAuthorize;