import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom";

import {setNum} from '../../store.js'
import CustomChasItemList from '../../components/list/CustomChasItemList.js';
import CustomChasAppendModal from '../../components/modals/CustomChasAppendModal.js';
import { getChasList, deleteChas } from '../../api/controlhub/controlhubApi.js';

export const CpoCustomChas = () => {
  const navigate = useNavigate();
  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setNum({id:12, idx:2}));
  },[])  

  const [CHASList, setCHASList] = useState([]);

  const reqGetChasList = async()=>{
    let result = await getChasList();
    if(result.status === 200){
      setCHASList([...result.data]);
    }
    else{

    }
  }
  useEffect(()=>{
    reqGetChasList();
  },[])
  

  let customChasList = [
    {
        "actionSetId": 31,
        "name": "신세계향 액션셋",
        "description": "신세계향 충전기 연동 액션셋",
        "cpoId": "61db09c0-ee06-4f68-a5d2-592c8a7c2581",
        "createdDate": "2024-10-29 05:14:27",
        "lastModifiedDate": "2024-10-29 06:38:35",
        "version" : "0.0.1",
        "ocpp" : "1.6",
    },
    {
        "actionSetId": 32,
        "name": "LG e-centric 액션셋",
        "description": "LG e-centric 액션셋",
        "cpoId": "61db09c0-ee06-4f68-a5d2-592c8a7c2581",
        "createdDate": "2024-10-31 08:03:31",
        "lastModifiedDate": "2024-10-31 08:08:28",
        "version" : "0.0.1",
        "ocpp" : "1.6",
    },
  ];

  const [deleteMode, setDeleteMode] = useState(false);
  const [editMode, setEditMode] = useState(false);

  
  const [checked, setChecked] = useState(false);
  const [checkedItemList, setCheckedItemList] = useState([]);
  
  // checked state (모든 아이템 check)
  useEffect(()=>{
    if(checked){
      let temp = new Array(CHASList.length).fill(0).map((_, i) => i)
      setCheckedItemList(temp);
    }
    else{
      setCheckedItemList([]);
    }
  },[checked])

  const reqDeleteChas = async()=>{
    for await(const item of checkedItemList){
      let id = CHASList[item]?.actionSetId;
      let result = await deleteChas(id);
      if(result.status === 200 || result.status === 201){
        
      }
      else{
        //TODO
      }
    }
    await reqGetChasList();
    setDeleteMode(false);
  }

  const [chasAppendModalOn, setChasAppendModalOn] = useState(false);

  return (
    <div className="cpo-custom-chas-container">
      {chasAppendModalOn&&<CustomChasAppendModal setOnOff={setChasAppendModalOn}/>}
      <div className="cpo-custom-chas-inner">
        <div className='cpo-custom-chas-function-container'>
          {!editMode&&!deleteMode&&<div className='cpo-custom-chas-function-button' onClick={()=>{setChasAppendModalOn(true);}}>CHAS 추가</div>}
          {!deleteMode&&<div className={`cpo-custom-chas-function-button edit ${editMode && checkedItemList.length===0 ? "disabled":""}`} onClick={()=>{if(editMode){
            navigate('./edit',{ state: { mode:"edit", ocpp: "OCPP 1.6", actionSetId:CHASList[checkedItemList[0]]?.actionSetId } })
          }else{setEditMode(true)}}}>수정</div>}
          {!editMode&&<div className={`cpo-custom-chas-function-button delete ${editMode && checkedItemList.length===0 ? "disabled":""}`} onClick={()=>{if(deleteMode){reqDeleteChas();}else{setDeleteMode(true)}}}>삭제</div>}
          {(editMode||deleteMode)&&<div className='cpo-custom-chas-function-button cancel' onClick={()=>{setEditMode(false);setDeleteMode(false);setCheckedItemList([]);setChecked(false);}}>취소</div>}
        </div>
        <div className="cpo-custom-chas-content-container">
          <div className="cpo-custom-chas-content-item-list-container">
            <div className={`cpo-custom-chas-content-item-column ${(editMode||deleteMode)?"edit":""}`}>
              {
                deleteMode &&
                <div>
                  <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                    <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
                    <span className="checkbox_icon"></span>
                  </label>
                </div>
              }
              { editMode && <div></div>}
              <div>CHAS명</div>
              <div>버전</div>
              <div>OCPP</div>
              <div>설정 상태</div>
            </div>

            {
              CHASList&&CHASList.map((it, idx)=>{
                return(
                  <CustomChasItemList data={it} settingMode={editMode?"edit":deleteMode?"delete":null} idx={idx} checkList={checkedItemList} setCheckList={setCheckedItemList}/>
                );
              })
            }
            
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoCustomChas;