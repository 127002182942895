import React from 'react'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import {setNum} from '../../store.js'
import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';
import { dateFormat2 } from '../../util/util.js';

import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import LineChart from '../../components/charts/LineChart.js';


export const CpoDashBoardTest = () => {
  let dispatch  = useDispatch();

  useEffect(()=> {
      dispatch(setNum({id:102}))
  },[dispatch]);
  
  let cellSpacing = [2,1];
  
  let Cell0 = ()=>{
    return(
      <div className="cpo-dashboard-charger-info-grid">
      <div className='cpo-dashboard-charger-info-all-charger'>
        <p className='cpo-dashboard-charger-info-all-charger-title'>전체</p>
        <div className='cpo-dashboard-charger-info-all-charger-content'>
          <p>100</p>
          <p>대</p>
        </div>
      </div>
      <div className='cpo-dashboard-charger-info-category-wrapper'>
        <div>
          <p className='cpo-dashboard-charger-info-category-title able'>충전가능</p>
          <div className='cpo-dashboard-charger-info-category-content'>
            <p>60</p>
            <p>대</p>
          </div>
        </div>
        <div>
          <p className='cpo-dashboard-charger-info-category-title charging'>충전중</p>
          <div className='cpo-dashboard-charger-info-category-content'>
            <p>10</p>
            <p>대</p>
          </div>
        </div>
        <div>
          <p className='cpo-dashboard-charger-info-category-title disable'>고장</p>
          <div className='cpo-dashboard-charger-info-category-content'>
            <p>20</p>
            <p>대</p>
          </div>
        </div>
      </div>
    </div>
    )
  }
  let Cell1 = ()=>{
    return(
      <div className='cpo-dashboard-pay-report-info-tody-container'>
        <div className='cpo-dashboard-pay-report-info-tody-header'>
          <p>오늘의 현황</p>
          <p>{dateFormat2(new Date())}</p>
        </div>
        <div className='cpo-dashboard-pay-report-info-tody-content-section'>
          <div className="cpo-dashboard-pay-report-info-tody-inner">
            <div>
              <p className='cpo-dashboard-pay-report-info-tody-title'>수익</p>
              <div className='cpo-dashboard-pay-report-info-tody-content'>
                <div className='cpo-dashboard-pay-report-info-tody-content-image'>
                  <img className="rectCoin" alt="" />
                </div>
                <div>
                  <p>3690</p>
                  <p>원</p>
                </div>
              </div>
            </div>
            <div>
              <p className='cpo-dashboard-pay-report-info-tody-title'>충전량</p>
              <div className='cpo-dashboard-pay-report-info-tody-content'>
                <div className='cpo-dashboard-pay-report-info-tody-content-image'>
                  <img className="rectBattery" alt="" />
                </div>
                <div>
                  <p>0.37</p>
                  <p>kWh</p>
                </div>
              </div>
            </div>
            <div>
              <p className='cpo-dashboard-pay-report-info-tody-title'>수익</p>
              <div className='cpo-dashboard-pay-report-info-tody-content'>
                <div className='cpo-dashboard-pay-report-info-tody-content-image'>
                  <img className="rectOutlet" alt="" />
                </div>
                <div>
                  <p>19</p>
                  <p>회</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  let Cell2 = ()=>{
    const [CurrentTab, setCurrentTab] = useState(0);

    return(<div className='cpo-dashboard-pay-report-info-category-container'>
      <div className="cpo-dashboard-pay-report-info-category-header">
        <div className={'cpo-dashboard-pay-report-info-category-button '+`${CurrentTab==0 ? "clicked":""}`} onClick={()=>{setCurrentTab(0)}}>주간 현황</div>
        <div className={'cpo-dashboard-pay-report-info-category-button '+`${CurrentTab==1 ? "clicked":""}`} onClick={()=>{setCurrentTab(1)}}>월간 현황</div>
        <div className={'cpo-dashboard-pay-report-info-category-button '+`${CurrentTab==2 ? "clicked":""}`} onClick={()=>{setCurrentTab(2)}}>연간 현황</div>
      </div>
      <div className="cpo-dashboard-pay-report-info-category-content-section">
        <div className='cpo-dashboard-pay-report-info-category-content-container top'>
          <div className='cpo-dashboard-pay-report-info-category-content-title'>
            <p>수익</p>
            <p>90.5</p>
            <p>%</p>
            <TiArrowSortedUp className="increase"/>
          </div>
          <div className='cpo-dashboard-pay-report-info-category-content top'>
            <p>73490</p>
            <p>원</p>
          </div>
        </div>
        <div className='cpo-dashboard-pay-report-info-category-content-container left'>
          <div className='cpo-dashboard-pay-report-info-category-content-title'>
            <p>충전량</p>
            <p>6.45</p>
            <p>%</p>
            <TiArrowSortedDown className="decrease"/>
            </div>
          <div className='cpo-dashboard-pay-report-info-category-content'>
            <p>73490</p>
            <p>kWh</p>
          </div>              </div>
        <div className='cpo-dashboard-pay-report-info-category-content-container right'>
          <div className='cpo-dashboard-pay-report-info-category-content-title'>
            <p>충전 횟수</p>
            <p>62.5</p>
            <p>%</p>
            <TiArrowSortedUp className="increase"/>
          </div>
          <div className='cpo-dashboard-pay-report-info-category-content'>
            <p>432</p>
            <p>회</p>
          </div>
        </div>
      </div>
    </div>
    );
  }
  let Cell3 = ()=>{
    const [salesTab, setSalesTab] = useState(0);
  const [salesData, setSalesData] = useState([]);
  useEffect(()=>{
    if(salesTab === 0){
      setSalesData([fastSalesData]);
    }
    else if(salesTab == 1){
      setSalesData([lowSalesData]);
    }
    else{
      setSalesData([fastSalesData,lowSalesData]);
    }
  },[salesTab])
  const [fastSalesData, setFastSalesData] = useState(
    {
      "id": "급속",
      "color": "hsl(139, 70%, 50%)",
      "data":[
        {"x":'24-08-16',"y":0},
        {"x":'24-08-17',"y":0},
        {"x":'24-08-18',"y":0},
        {"x":'24-08-19',"y":8000},
        {"x":'24-08-20',"y":2000},
        {"x":'24-08-21',"y":4000},
        {"x":'24-08-15',"y":2000},
        {"x":'24-08-22',"y":6000},
        {"x":'24-08-23',"y":4000},
        {"x":'24-08-24',"y":9000},
        {"x":'24-08-25',"y":3000},
        {"x":'24-08-26',"y":0},
        {"x":'24-08-27',"y":0},
        {"x":'24-08-28',"y":4000},
        {"x":'24-08-29',"y":6000},
        {"x":'24-08-30',"y":10000},
        {"x":'24-08-31',"y":12000},
        {"x":'24-09-01',"y":10000},
        {"x":'24-09-02',"y":10000},
        {"x":'24-09-03',"y":10000},
        {"x":'24-09-04',"y":8000},
        {"x":'24-09-05',"y":6000},
        {"x":'24-09-06',"y":4000},
        {"x":'24-09-07',"y":1000},
        {"x":'24-09-08',"y":5000},
        {"x":'24-09-09',"y":6000},
        {"x":'24-09-10',"y":6000},
        {"x":'24-09-11',"y":5000},
        {"x":'24-09-12',"y":7000},
        {"x":'24-09-13',"y":8000},
      ]
    }
  );
  const [lowSalesData, setLowSalesData] = useState({
    "id": "완속",
    "color": "hsl(205, 70%, 50%)",
    "data" : [
      {"x":'24-08-16',"y":8000},
      {"x":'24-08-17',"y":2000},
      {"x":'24-08-18',"y":3000},
      {"x":'24-08-19',"y":5000},
      {"x":'24-08-20',"y":2000},
      {"x":'24-08-21',"y":5000},
      {"x":'24-08-15',"y":10000},
      {"x":'24-08-22',"y":12000},
      {"x":'24-08-23',"y":13000},
      {"x":'24-08-24',"y":10000},
      {"x":'24-08-25',"y":5000},
      {"x":'24-08-26',"y":20000},
      {"x":'24-08-27',"y":0},
      {"x":'24-08-28',"y":6000},
      {"x":'24-08-29',"y":13000},
      {"x":'24-08-30',"y":10000},
      {"x":'24-08-31',"y":11000},
      {"x":'24-09-01',"y":10000},
      {"x":'24-09-02',"y":10000},
      {"x":'24-09-03',"y":10000},
      {"x":'24-09-04',"y":8000},
      {"x":'24-09-05',"y":4000},
      {"x":'24-09-06',"y":5000},
      {"x":'24-09-07',"y":3000},
      {"x":'24-09-08',"y":2000},
      {"x":'24-09-09',"y":8000},
      {"x":'24-09-10',"y":6000},
      {"x":'24-09-11',"y":3000},
      {"x":'24-09-12',"y":12000},
      {"x":'24-09-13',"y":8000},
    ]
  });
    return(
      <div className='cpo-dashboard-pay-report-graph-grid'>
        <div className="cpo-dashboard-pay-report-graph-grid-header">
          <div className={'cpo-dashboard-pay-report-graph-grid-button '+`${salesTab == 0 ?'clicked':""}`} onClick={()=>{setSalesTab(0)}}>급속 매출 추이</div>
          <div className={'cpo-dashboard-pay-report-graph-grid-button '+`${salesTab == 1 ?'clicked':""}`} onClick={()=>{setSalesTab(1)}}>완속 매출 추이</div>
          <div className={'cpo-dashboard-pay-report-graph-grid-button '+`${salesTab == 2 ?'clicked':""}`} onClick={()=>{setSalesTab(2)}}>전체 매출 추이</div>
        </div>
        <div className="cpo-dashboard-pay-report-graph-grid-content-section">
          <div></div>
          <div>
            <LineChart data={salesData} axiosName={{x:"날짜", y:"매출"}} unit={{x:"",y:"원"}}/>
          </div>
        </div>
      </div>
    )
  }
  let Cell4 = ()=>{
    const [PowerSupplyTab, setPowerSupplyTab] = useState(0);
    const [PowerSupplyData, setPowerSupplyData] = useState([]);
    const [fastPowerSupplyData, setFastPowerSupplyData] = useState(
      {
        "id": "급속",
        "color": "hsl(287, 89%, 71%)",
        "data":[
          {"x":'24-08-16',"y":0},
          {"x":'24-08-17',"y":0},
          {"x":'24-08-18',"y":0},
          {"x":'24-08-19',"y":8000},
          {"x":'24-08-20',"y":2000},
          {"x":'24-08-21',"y":4000},
          {"x":'24-08-15',"y":2000},
          {"x":'24-08-22',"y":6000},
          {"x":'24-08-23',"y":4000},
          {"x":'24-08-24',"y":9000},
          {"x":'24-08-25',"y":3000},
          {"x":'24-08-26',"y":0},
          {"x":'24-08-27',"y":0},
          {"x":'24-08-28',"y":4000},
          {"x":'24-08-29',"y":6000},
          {"x":'24-08-30',"y":10000},
          {"x":'24-08-31',"y":12000},
          {"x":'24-09-01',"y":10000},
          {"x":'24-09-02',"y":10000},
          {"x":'24-09-03',"y":10000},
          {"x":'24-09-04',"y":8000},
          {"x":'24-09-05',"y":6000},
          {"x":'24-09-06',"y":4000},
          {"x":'24-09-07',"y":1000},
          {"x":'24-09-08',"y":5000},
          {"x":'24-09-09',"y":6000},
          {"x":'24-09-10',"y":6000},
          {"x":'24-09-11',"y":5000},
          {"x":'24-09-12',"y":7000},
          {"x":'24-09-13',"y":8000},
        ]
      }
    );
    const [lowPowerSupplyData, setLowPowerSupplyData] = useState({
      "id": "완속",
      "color": "hsl(40, 92%, 69%)",
      "data" : [
        {"x":'24-08-16',"y":8000},
        {"x":'24-08-17',"y":2000},
        {"x":'24-08-18',"y":3000},
        {"x":'24-08-19',"y":5000},
        {"x":'24-08-20',"y":2000},
        {"x":'24-08-21',"y":5000},
        {"x":'24-08-15',"y":10000},
        {"x":'24-08-22',"y":12000},
        {"x":'24-08-23',"y":13000},
        {"x":'24-08-24',"y":10000},
        {"x":'24-08-25',"y":5000},
        {"x":'24-08-26',"y":20000},
        {"x":'24-08-27',"y":0},
        {"x":'24-08-28',"y":6000},
        {"x":'24-08-29',"y":13000},
        {"x":'24-08-30',"y":10000},
        {"x":'24-08-31',"y":11000},
        {"x":'24-09-01',"y":10000},
        {"x":'24-09-02',"y":10000},
        {"x":'24-09-03',"y":10000},
        {"x":'24-09-04',"y":8000},
        {"x":'24-09-05',"y":4000},
        {"x":'24-09-06',"y":5000},
        {"x":'24-09-07',"y":3000},
        {"x":'24-09-08',"y":2000},
        {"x":'24-09-09',"y":8000},
        {"x":'24-09-10',"y":6000},
        {"x":'24-09-11',"y":3000},
        {"x":'24-09-12',"y":12000},
        {"x":'24-09-13',"y":8000},
      ]
    });
    useEffect(()=>{
      if(PowerSupplyTab === 0){
        setPowerSupplyData([fastPowerSupplyData]);
      }
      else if(PowerSupplyTab == 1){
        setPowerSupplyData([lowPowerSupplyData]);
      }
      else{
        setPowerSupplyData([fastPowerSupplyData,lowPowerSupplyData]);
      }
    },[PowerSupplyTab])
    return(
      <div className='cpo-dashboard-pay-report-graph-grid'>
        <div className="cpo-dashboard-pay-report-graph-grid-header">
          <div className={'cpo-dashboard-pay-report-graph-grid-button '+`${PowerSupplyTab == 0 ?'clicked':""}`} onClick={()=>{setPowerSupplyTab(0)}}>급속 전력공급량</div>
          <div className={'cpo-dashboard-pay-report-graph-grid-button '+`${PowerSupplyTab == 1 ?'clicked':""}`} onClick={()=>{setPowerSupplyTab(1)}}>완속 전력공급량</div>
          <div className={'cpo-dashboard-pay-report-graph-grid-button '+`${PowerSupplyTab == 2 ?'clicked':""}`} onClick={()=>{setPowerSupplyTab(2)}}>전체 전력공급량</div>
        </div>
        <div className="cpo-dashboard-pay-report-graph-grid-content-section">
          <div></div>
          <div>
            <LineChart data={PowerSupplyData} axiosName={{x:"날짜", y:"공급량"}} unit={{x:"",y:"kWh"}}/>
          </div>
        </div>
      </div>
    )
  }
  
  let Cell5 = ()=>{
    return(
      <div className="cpo-dashboard-average-report-grid">
          <div className='cpo-dashboard-average-report-grid-header'>
            <p>월간 평균 통계</p>
            <div>
              <p>회원</p>
              <p>195</p>
            </div>
          </div>


          <div className='cpo-dashboard-average-report-grid-section left'>
            <div className='cpo-dashboard-average-report-header'>
              <p>급속</p>
              <p>602</p>
            </div>
            <div className='cpo-dashboard-average-report-content-container'>
              <div className='cpo-dashboard-average-report-image'>
                <img className="coinBlue" alt="" />
              </div>
              <div className='cpo-dashboard-average-report-title'>
                <p>평균 수익</p>
                <p>90.5</p>
                <TiArrowSortedUp className="increase"/>
              </div>
              <div className='cpo-dashboard-average-report-context'>
                <p className='revenue'>73,490</p>
              </div>
            </div>
            <div className='cpo-dashboard-average-report-content-container'>
              <div className='cpo-dashboard-average-report-image'>
                <img className="sandClockBlue" alt="" />
              </div>
              <div className='cpo-dashboard-average-report-title'>
                <p>평균 충전 시간</p>
                <p>90.5</p>
                <TiArrowSortedUp className="increase"/>
              </div>
              <div className='cpo-dashboard-average-report-context'>
                <p className='time'>7</p>
              </div>
            </div>
          </div>



          <div className='cpo-dashboard-average-report-grid-section right'>
            <div className='cpo-dashboard-average-report-header'>
              <p>완속</p>
              <p>602</p>
            </div>
            <div className='cpo-dashboard-average-report-content-container'>
              <div className='cpo-dashboard-average-report-image'>
                <img className="coinGreen" alt="" />
              </div>
              <div className='cpo-dashboard-average-report-title'>
                <p>평균 수익</p>
                <p>90.5</p>
                <TiArrowSortedDown className="decrease"/>
              </div>
              <div className='cpo-dashboard-average-report-context'>
                <p className='revenue'>73,490</p>
              </div>
            </div>
            <div className='cpo-dashboard-average-report-content-container'>
              <div className='cpo-dashboard-average-report-image'>
                <img className="sandClockGreen" alt="" />
              </div>
              <div className='cpo-dashboard-average-report-title'>
                <p>평균 충전 시간</p>
                <p>90.5</p>
                <TiArrowSortedDown className="decrease"/>
              </div>
              <div className='cpo-dashboard-average-report-context'>
                <p className='time'>7</p>
              </div>
            </div>
          </div>
        </div>
    );
  }

  let dashboardObj;
  let restoreModel = [];
  function onRestore(args) {
      dashboardObj.panels = restoreModel;
  }
  let onSave = async(args) => {
    restoreModel = dashboardObj.serialize();
    let tempArr = [Cell0, Cell1, Cell2, Cell3, Cell4, Cell5];
    let idx = 0;
    for await(const item of tempArr){
      restoreModel[idx].content = item;
      idx += 1;
    }
  }
  let panels = [
    { "sizeX": 4, "sizeY": 1, "row": 0, "col": 0, content: Cell0},
    { "sizeX": 2, "sizeY": 3, "row": 1, "col": 0, content: Cell1},
    { "sizeX": 2, "sizeY": 3, "row": 1, "col": 2, content: Cell2},
    { "sizeX": 4, "sizeY": 3, "row": 4, "col": 0, content: Cell3},
    { "sizeX": 4, "sizeY": 3, "row": 7, "col": 0, content: Cell4},
    { "sizeX": 4, "sizeY": 3, "row": 10, "col": 0, content: Cell5},
  ];
  return (<div>
    <div className="cpo-dashboard-container">
      <div className="cpo-dashboard-inner">
        <div className="inline" id="properties">
            <button className="e-btn e-primary" onClick={onSave}>Save</button>
            <button className="e-btn e-flat e-outline" id="Restore" onClick={onRestore}>Restore</button>
          </div>
        <div className="control-section">
          <DashboardLayoutComponent id='defaultLayout' created={onSave} ref={s => (dashboardObj = s)} cellSpacing={cellSpacing} cellAspectRatio={100 / 50} panels={panels} columns={4}/>
        </div>
        
      </div>
    </div>
  </div>);
}
export default CpoDashBoardTest;