import { useState, useRef, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum, setDetailData} from '../../store.js'
import DaumPostcode from 'react-daum-postcode';

import { Container as MapDiv, NaverMap, Marker, useNavermaps, useListener } from 'react-naver-maps'
import MarkerShape from '../marker/MarkerShape.js';
import { TfiClose } from "react-icons/tfi";

const StationDetailTab2 = (props) => {
  // https://naveropenapi.apigw.ntruss.com/map-geocode/v2/geocode?query={주소}
  const navermaps = useNavermaps();
  let [map, setMap] = useState();
  // TODO props로 받아온 데이터로 초기화 예정
  const [address, setAddress] = useState(null);
  const [reg, setReg] = useState(null);
  const [pos, setPos] = useState(null);

  useListener(navermaps, 'click', () => window.alert('서울시청 click'))


  const[DetailedAddress, setDetailedAddress]=useState(null);

  function makeAddress(item) {
    if (!item) {
      return;
    }

    var name = item.name, region = item.region, land = item.land, isRoadAddress = name === 'roadaddr';
    var sido = '', sigugun = '', dongmyun = '', ri = '', rest = '';

    if (hasArea(region.area1)) {
      sido = region.area1.name;
    }
    if (hasArea(region.area2)) {
      sigugun = region.area2.name;
    }
    if (hasArea(region.area3)) {
      dongmyun = region.area3.name;
    }
    if (hasArea(region.area4)) {
      ri = region.area4.name;
    }
    if (land) {
      if (hasData(land.number1)) {
        if (hasData(land.type) && land.type === '2') {
          rest += '산';
        }
        rest += land.number1;
        if (hasData(land.number2)) {
          rest += ('-' + land.number2);
        }
      }
      if (isRoadAddress === true) {
        if (checkLastString(dongmyun, '면')) {
          ri = land.name;
        } else {
          dongmyun = land.name;
          ri = '';
        }
        if (hasAddition(land.addition0)) {
          rest += ' ' + land.addition0.value;
        }
      }
    }
    return [sido, sigugun, dongmyun, ri, rest].join(' ');
  }
  function searchAddressToCoordinate(address) {
    if(address=='')return;
    navermaps.Service.geocode({
        query: address
    }, function(status, response) {
        if (status === navermaps.Service.Status.ERROR) {
          return alert('Something Wrong!');
        }
        var htmlAddresses = [],
          item = response.v2.addresses[0],
          point = new navermaps.LatLng(item.y, item.x);
        if (item.roadAddress) {
          htmlAddresses.push(item.roadAddress);
        }
        setAddress(htmlAddresses[0]);
        setPos(point);
        map?.setCenter(point);
    });
  }
  function hasArea(area) {
    return !!(area && area.name && area.name !== '');
  }

  function hasData(data) {
    return !!(data && data !== '');
  }

  function checkLastString (word, lastString) {
    return new RegExp(lastString + '$').test(word);
  }

  function hasAddition (addition) {
    return !!(addition && addition.value);
  }

  const changeCenter = (e) =>{
    console.log("hhhhhhh");
    setPos(new navermaps.LatLng(e.y, e.x));
    navermaps.Service.reverseGeocode({
      coords: new navermaps.LatLng(e.y, e.x),
        orders: [
            navermaps.Service.OrderType.ROAD_ADDR
        ].join(',')
    }, function(status, response) {
        if (status !== navermaps.Service.Status.OK) {
            return alert('Something wrong!');
        }
        let res = response.v2 // 검색 결과의 컨테이너
        //     address = res.address; // 검색 결과로 만든 주소
        // console.log(e.y, e.x);
        // console.log(address)
        var items = response.v2.results,
            address = '',
            region= '',
            htmlAddresses = [];

        let item = items[0];
        address = makeAddress(item) || '';
        region = item?.region.area1.alias;
        htmlAddresses.push(address);
        if(htmlAddresses == '') return;
        setAddress(htmlAddresses[0]);
        setReg(regionConvert(region));
    });
  }
  const regionConvert = (region)=>{
    if(region == '경기' || region =='서울'||region =='인천'){
      return 'A(서울, 인천, 경기)';
    }
    else if(region =='충남' || region =='충북' ||region == '세종'||region =='광주'||region =='전남'||region =='전북'||region =='대전'){
      return 'B(충청, 전라)';
    }
    else if(region =='강원'||region =='경북'||region =='경남'||region =='대구'||region =='부산'||region =='울산'){  
      return 'C(강원, 경상)';
    }
    else if(region =='제주'){
      return 'D(제주)';
    }
    else{
      return '';
    }
  }

  useEffect(()=>{
    if(!props.open)return;
    let temp = localStorage.getItem(`${props.name}`+'_StationDetailTab2');
    if(temp){
      temp = JSON.parse(temp);
      setAddress(JSON.parse(temp['address']));
      setReg(JSON.parse(temp['reg']));
      console.log("num : 1");
      setPos(JSON.parse(temp['pos']));
      setDetailedAddress(JSON.parse(temp['DetailedAddress']));
    }
    else{
      if(props.data?.Address)setAddress(props.data.Address);
      if(props.data?.RegionName)setReg(props.data.RegionName);
      if(props.data?.Latitude && props.data?.Longitude){
        console.log("num : 2");
        setPos(new navermaps.LatLng(props.data?.Latitude, props.data?.Longitude));}
      if(props.data?.DetailedAddress)setDetailedAddress(props.data.DetailedAddress);
    }
  },[])


  useEffect(()=>{
    if(!props.open)return;
    console.log("pos1");
    if(pos == null || (pos.y == props.data?.Latitude && pos.x == props.data?.Longitude) )return;
    console.log("pos2");
    localStorage.setItem(`${props.name}`+'_StationDetailTab2', JSON.stringify( {address:JSON.stringify(address), reg:JSON.stringify(reg), pos:JSON.stringify(pos), DetailedAddress:JSON.stringify(DetailedAddress)}))
    console.log("pospos");
    props.setSaveBtnStatus(true);
  },[pos])

  // useEffect(()=>{
  //   if(!props.open)return;
  //   if(address == null)return;
  //   if(address == props.data?.Address){console.log(pos); return;}
  //   localStorage.setItem(`${props.name}`+'_StationDetailTab2', JSON.stringify( {address:JSON.stringify(address), reg:JSON.stringify(reg), pos:JSON.stringify(pos), DetailedAddress:JSON.stringify(DetailedAddress)}))
  //   props.setSaveBtnStatus(true);
  // },[address])

  useEffect(()=>{
    if(!props.open)return;
    if(DetailedAddress == null)return;
    if(DetailedAddress == props.data?.DetailedAddress){ return;}
    localStorage.setItem(`${props.name}`+'_StationDetailTab2', JSON.stringify( {address:JSON.stringify(address), reg:JSON.stringify(reg), pos:JSON.stringify(pos), DetailedAddress:JSON.stringify(DetailedAddress)}))
    props.setSaveBtnStatus(true);
  },[DetailedAddress])

  const [modalOn, setModalOn] = useState(false);

  return (
    <div className='station-detail-tab tab2'>
      {modalOn&& <AddressInputModal setOnOff={setModalOn} setAddress={setAddress} searchAddressToCoordinate={searchAddressToCoordinate}/>}
      <div className='station-detail-location-left-container'>
        <div>
          
          <div className='station-detail-address-container'>
            <p className='station-detail-input-title address'>주소</p>
            <div className='station-detail-location-convert-button-container'>
              <div className='station-detail-location-convert-button' onClick={()=>{setModalOn(true)}}>
                <p>주소 찾기</p>
              </div>
            </div>
            
            <div className="station-detail-info-input-container mapTab">
              <input type="text" placeholder='' onChange={(e)=>{setAddress(e.target.value)}} value={address} onClick={(e)=>{e.stopPropagation(); }}readOnly/>
            </div>
          </div>
          <div>
            <p className='station-detail-input-title'>상세주소</p>
            <div className="station-detail-info-input-container mapTab">
              <input type="text" placeholder='' value={DetailedAddress} onChange={(e)=>{setDetailedAddress(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/> 
            </div>
          </div>
          
        </div>
        <div>
          <div className='station-detail-info-input-wrapper mapTab2'>
            <p>권역</p>
            <div className="station-detail-info-input-container mapTab2">
              <input type="text" placeholder='' onClick={(e)=>{e.stopPropagation(); }} value={reg} readOnly/>
            </div>
          </div>
          <div className='station-detail-info-input-wrapper mapTab2'>
            <p>위도</p>
            <div className="station-detail-info-input-container mapTab2">
              <input type="text" placeholder='' value={pos?.y} readOnly/>
            </div>
          </div>
          <div className='station-detail-info-input-wrapper mapTab2'>
            <p>경도</p>
            <div className="station-detail-info-input-container mapTab2">
              <input type="text" placeholder='' value={pos?.x} readOnly/>
            </div>
          </div>
        </div>

        





      </div>
      <div className='station-detail-location-right-container'>
        <div className='station-detail-guide-container'>
          <pre>
            <color>① 주소 찾기 버튼</color>을 눌러 주소를 설정<br/>
            <color>② 지도</color>를 움직여 정확한 충전소 위치를 설정<br/>
            <color>③ 저장</color> 버튼을 눌러 설정 완료
          </pre>
        </div>
        <MapDiv className='map'
          ncpclientid='f88olicqny'
          error={<p>Maps Load Error</p>}
          loading={<p>Maps Loading...</p>}
        >
          <NaverMap
            ref={setMap}
            defaultCenter={pos}
            defaultZoom={17}
            disableKineticPan={false}
            scaleControl={true}
            onCenterChanged={(e)=>{changeCenter(e)}}
            onBoundsChanged={(e)=>{}}
            minZoom={9}
            maxZoom={21}
          >
            {pos&&<Marker position={pos}
            icon= {{content: MarkerShape(),size: new navermaps.Size(28, 28),anchor: new navermaps.Point(19, 58),}}
            />}
          </NaverMap>
        </MapDiv>
      </div>
    </div>
  )
}
export default StationDetailTab2;



const AddressInputModal = (props) => {
  const completeHandler = (data) => {
    const { address } = data;
    console.log(data);
    props.setAddress(address);
    props.searchAddressToCoordinate(address);
    props.setOnOff(false);
  };
  return (
    <div className="cpo-address-input-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
      <div className="cpo-address-input-modal-header">
        <title>주소 찾기</title>
        <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
      </div>
      <div>
        <DaumPostcode onComplete={completeHandler} />
      </div>
    </div>
  );
}