import React, { useEffect } from 'react';

import { TfiClose } from "react-icons/tfi";
import { CiWarning } from "react-icons/ci";

import { deleteStation } from '../../api/infra/infraApi.js';

export const StationDeleteModal = (props) => {


  useEffect(()=>{
    console.log(props.stationInfo)
    console.log(props.checkStationList)
    
    
  },[])


  const reqDeleteStations = async()=>{
    for await(const stationIdx of props.checkStationList){
      let tempStation = props.stationInfo[stationIdx];
      if(tempStation.OpStatus !== "삭제됨"){
        await deleteStation(tempStation.ChargingStationId);
      }
    }
    props.setOnOff(false);
    await props.getStationList();
  }



  return (
    <div className="cpo-charger-delete-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-charger-delete-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-charger-delete-modal-header">
          <title>충전소 삭제</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className='cpo-charger-delete-modal-list-container'>
          <div className='cpo-alert-msg-modal-delete-alert'>
            <CiWarning/>
          </div>
          <p className='cpo-charger-delete-modal-msg'>{props.checkStationList?.length}개의 충전소를 삭제하시겠습니까?</p>
          <div className='cpo-charger-delete-modal-list-wrapper'>
            {
              props.checkStationList && props.stationInfo && props.checkStationList?.map((it, idx)=>{
                if(props.stationInfo[it].OpStatus == "삭제됨"){
                  return;
                }
                return(
                  <>
                    <div className='cpo-charger-delete-modal-item'>{props.stationInfo[it].ChargingStationName}</div>

                  </>
                )
              })
            }
          </div>
        </div>
        <div>
          <div className={'cpo-charger-modal-delete-button '} onClick={()=>{reqDeleteStations()}}><p>삭제</p></div>
        </div>
      </div>
    </div>
  )
}
export default StationDeleteModal

