import { useState, useRef, useEffect} from 'react';
import axios from 'axios';

import { useLocation } from 'react-router-dom';
import wave from '../../img/wave.png'
import logo from '../../img/EMOOV_logo_tran_4.png'


export const InvitationVerification = (props) => {
  const email = localStorage.getItem("user-email");

  return (
    <>
      <div className='sign-up-page-container'>
        <div className="invitation-wrapper">
          <div>
            <title>
              <div className="invitation-logo">
                <img src={logo} alt="" />
              </div>
              CPO 초대
            </title>
            <pre>
              {`일반 회원의 경우 CPO 관리자 회원의 초대가 필요합니다.\n초대 메일은<${email}>로 전송됩니다. 초대 수락 후 서비스를 이용해주세요!`}
            </pre>
          </div>
          
          <div className="invitation-bg">
            <img src={wave} alt="" />
          </div>
        </div>
      </div>
    </>
  )
}
export default InvitationVerification;

