import Lottie from "react-lottie";
import animationSplash from '../../lotties/loading2.json'

export function Loading() {
  const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationSplash,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};	
return (
  <Lottie options={defaultOptions} />
)
}