import React, { useState } from 'react';
import { CiWarning } from "react-icons/ci";
import { TfiClose } from "react-icons/tfi";

import { deleteCard, putCardName, putDefaultCard } from '../../api/payment/paymentApi';

import { Loading } from '../alerts/Loading';

export const PaymentFunctionModal = (props) => {

  const [name, setName] = useState(null);

  const reqDeleteCard = async(cardId)=>{
    setLoadingOn(true);
    let result = await deleteCard(cardId);
    if(parseInt(result.status/100)===2){
      props.getCardList();
      props.setOnOff(false);
    }
    else{
      setReqMsg("카드 삭제에 실패하였습니다.");
      setTimeout(() => {
        setReqMsg(null);
      }, 3000);
    }
    setLoadingOn(false);
  }
  const reqPutCardName = async(cardId, newName)=>{
    setLoadingOn(true);
    let result = await putCardName(cardId, newName);
    if(parseInt(result.status/100)===2){
      props.getCardList();
      props.setOnOff(false);
    }
    else{
      setReqMsg("카드 이름 수정에 실패하였습니다.");
      setTimeout(() => {
        setReqMsg(null);
      }, 3000);
    }
    setLoadingOn(false);
  }
  
  const reqPutDefaultCard = async(cardId)=>{
    setLoadingOn(true);
    let result = await putDefaultCard(cardId);
    if(parseInt(result.status/100)===2){
      props.getCardList();
      props.setOnOff(false);
    }
    else{
      setReqMsg("기본 카드 설정에 실패하였습니다.");
      setTimeout(() => {
        setReqMsg(null);
      }, 3000);
    }
    setLoadingOn(false);
  }
  const [reqMsg, setReqMsg] = useState();
  const [loadingOn, setLoadingOn] = useState();

  return (
    <div className="cpo-payment-function-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
      <div className="cpo-payment-function-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-payment-function-modal-header">
          <title>{props.data.category == 'delete'? "":props.data.category == 'edit'?"카드별명 수정":"기본카드 설정"}</title>
          <div className='cpo-payment-function-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        {
          props.data.category === 'delete'?
          <>
            <div className='cpo-payment-function-modal-content delete'>
              <div className='cpo-payment-function-modal-delete-alert'>
                <CiWarning/>
              </div>
              <div className='cpo-payment-function-modal-delete-msg'><p>카드를 삭제하시겠습니까?</p></div>
              <div className='cpo-payment-function-modal-delete-info'><p>{props.data.name+" ("+props.data.number+")"}</p></div>
              <p>{reqMsg}</p>
              <div className='cpo-payment-function-modal-delete-button' onClick={(e)=>{e.stopPropagation();e.preventDefault(); reqDeleteCard(props.data.paymentCardId);}}>
                {loadingOn? <Loading/> : <p>삭제</p>}
              </div>
            </div>
          </>
          :
          props.data.category === 'edit'?
          <>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
            <div className='cpo-payment-function-modal-content edit'>
              <div className='cpo-payment-function-modal-edit-info'><p>{props.data.name+" ("+props.data.number+")"}</p></div>
              <div  className='cpo-payment-function-modal-edit-title'>
                <p>새로운 카드별명</p>
              </div>
              <div className='cpo-payment-function-modal-edit-input-container'>
                <input type="text" value={name} onChange={(e)=>{setName(e.target.value)}} />
              </div>
              <p>{reqMsg}</p>
              <div className={'cpo-payment-function-modal-edit-button ' + `${!name ? "disable":""}`} onClick={()=>{reqPutCardName(props.data.paymentCardId, name)}}>
                {loadingOn ? <Loading/> : <p>저장하기</p>}
              </div>
            </div>
          </>
          :
          <>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
            <div className='cpo-payment-function-modal-content default'>
              <div className='cpo-payment-function-modal-default-msg'><p>해당 카드를 기본 결제카드로 설정하시겠습니까?</p></div>
              <div className='cpo-payment-function-modal-edit-info'><p>{props.data.name+" ("+props.data.number+")"}</p></div>
              <p>{reqMsg}</p>
              <div className={'cpo-payment-function-modal-edit-button '} onClick={()=>{reqPutDefaultCard(props.data.paymentCardId)}}>
                {loadingOn ? <Loading/> : <p>기본 결제카드 설정</p>}
              </div>
            </div>
          </>

        }
      </div>
    </div>
  )
}
export default PaymentFunctionModal
