import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, Routes, Route, Link, Switch, useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import {setNum} from '../../store.js'
import { FaChevronLeft } from "react-icons/fa";

import { TbDotsVertical } from "react-icons/tb";

import { FaRegTrashAlt } from "react-icons/fa";
import { FiEdit3 } from "react-icons/fi";

export const CpoNoticeDetail = ({match}) => {
  let navigator = useNavigate();
  const params = useParams();
  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();
  
  // from navigator
  const location = useLocation();
  // let data = location?.state?.data;
  const [data, setData] = useState();
  useEffect(()=>{
    if(location.state !== null){
      setData(location.state.data)
    }
    else{
      getPost();
    }
  },[])
  useEffect(()=>{
    dispatch(setNum({id:11, idx:2, detail:true}));
  },[])
  let noticeList = [{
    "NoticePostId": 1,
    "Title": "공지입니다.",
    "Contents": `공지글`,
    "OwnerId": "kassid",
    "PostDate": "2024.07.03"
  }]

  const getPost = async()=>{
     //TODO Notice 생성 시
    //   await axios.get(`/cs/api/Faq?cpoMemberId=${'kassid'}`,{
    // })
    // .then((res)=>{
    //   let temp = [...res.data];
    //   setFaqList(temp);
    // })
    let temp = noticeList.filter(it=>it.NoticePostId == params.id)[0];
    console.log(temp);
    setData(temp);
  }

  // let noticeList = {
  //   "NoticePostId": 1,
  //   "Title": "공지입니다.",
  //   "Contents": `공지글`,
  //   "OwnerId": "kassid",
  //   "PostDate" ""2000.00.00",
  // }
  const edit = ()=>{
    navigator('/cpo/notice/edit', {state:{Title:data?.Title, Contents:data?.Contents}})
  }

  return (
    // <div>CpoNoticeDetail + {`${params.id}`}</div>
    <div className='cpo-customer-service-container'>
      <div className="cpo-customer-service-inner notice">
        <div className='cpo-notice-detail-back-button' onClick={()=>{navigator('/cpo/notice')}}><FaChevronLeft/><p>목록으로 이동</p></div>
        <div className="cpo-notice-container">
          <div className={"cpo-notice-detail-header"}>
            <div className="cpo-notice-detail-title">
              <p onClick={()=>{console.log(data)}}>공지</p>
              <h2>{data?.Title}</h2>
              <p>{data?.PostDate}</p>
            </div>
            <div>
              <div className={"cpo-fap-item-function-button-list open detail" }>
                <div>
                  <div className="cpo-fap-item-function-button" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); edit();}}>
                    <div><FiEdit3/></div>
                  </div>
                </div>
                <div>
                  <div className="cpo-fap-item-function-button delete" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
                    <div><FaRegTrashAlt/></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cpo-notice-detail-content-container">
            <pre>
              {
                data?.Contents
              }
            </pre>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoNoticeDetail;