import { useState, useRef, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'
import {chargerTypeConverter, connectorTypeConverter} from "../../util/util.js"


const ChargerCardEventTab2 = (props) => {

  return (
    <div className='chargerCardEventTab2'>
      기능 업데이트 예정입니다.
    </div>
  )
}
export default ChargerCardEventTab2;
