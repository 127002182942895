import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { CiUser } from "react-icons/ci";
import { FaRegBuilding } from "react-icons/fa";
import { TfiClose } from "react-icons/tfi";

import { setCookie, getCookie } from '../../util/util';
import { FaChevronLeft } from "react-icons/fa";

import { postCard } from '../../api/payment/paymentApi';
import { Loading } from '../alerts/Loading.js'
export const PaymentCardEnrollModal = (props) => {
  
  
  
  const [cardName, setCardName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardYY, setCardYY] = useState("");
  const [cardMM, setCardMM] = useState("");
  const [cvc, setCvc] = useState("");
  const [customerIdentityNumber, setCustomerIdentityNumber] = useState("");
  const [partialPassword, setPartialPassword] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");

  
  const reqPostCard = async()=>{
    setLoadingOn(true);
    let reqObj = {
      "cardNumber": cardNumber,
      "cardYY": cardYY,
      "cardMM": cardMM,
      "cvc": cvc,
      "customerIdentityNumber": customerIdentityNumber,
      "partialPassword": partialPassword,
      "customerName": customerName,
      "customerEmail": customerEmail,
      "cardName": cardName,
      "defaultCard": props.cardCount === 0 ? true : false
    };
    let result = await postCard(reqObj);
    if(result.status === 200 || result.status === 201){
      props.getCardList();
      props.setOnOff(false);
    }
    else{
      setReqMsg("카드 등록에 실패했습니다.");
      setTimeout(() => {
        setReqMsg(null);
      }, 3000);
    }
    setLoadingOn(false);
  }

  const cardNumInput = (target)=>{
    target.value = target.value.replace(/[^0-9]/g, '');
  }
  const [enrollTab, setEnrollTab] = useState(0);

  // 이메일 패턴 검사
  const [emailPatternValidate, setEmailPatternValidate] = useState(null); // false : 유효하지않은 이메일 형식, true: 이메일 형식

  // Validation Pattern
  const emailInput = (target)=>{
    const pattern = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-za-z0-9\-]+/;
    if(pattern.test(target.value) === false) {setEmailPatternValidate(false); }
    else { setEmailPatternValidate(true); }
  }

  const [loadingOn, setLoadingOn] = useState(false);
  const [reqMsg, setReqMsg] = useState(null);
  return (
    <div className="cpo-payment-card-enroll-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
      <div className="cpo-payment-card-enroll-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className={"cpo-payment-card-enroll-modal-header " + `${enrollTab == 0 ? "flex":""}`}>
          {enrollTab == 0 ?<p>카드의 종류를 선택해주세요</p>: enrollTab == 1 ?<title>개인카드 등록</title> : <title>법인카드 등록</title>}
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>

        </div>
        {
          enrollTab == 0 ?
          <>
            <div className='card-type-select-button-container'>
              <div className='card-type-select-button' onClick={()=>{setEnrollTab(1)}}>
                <CiUser/>
                <p><strong>개인</strong> 카드</p>
              </div>
              <div className='card-type-select-button' onClick={()=>{setEnrollTab(2)}}>
                <FaRegBuilding/>
                <p><strong>법인</strong> 카드</p>
              </div>
            </div>
            <div></div>
          </>
          :
          enrollTab == 1 ?
          <>
            <div className='cpo-payment-card-enroll-modal-flex-wrapper'>
              <div>
                <p className='cpo-payment-card-enroll-modal-title'>카드 정보</p>

                <div className='cpo-payment-card-enroll-input-title required'><p>카드 별명</p></div>
                <div className="cpo-payment-card-enroll-modal-input-container">
                  <input type="text" placeholder='카드별명을 입력해주세요' value={cardName} onChange={(e)=>{ setCardName(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                </div>
                
                <div className='cpo-payment-card-enroll-input-title required'><p>카드 번호</p></div>
                <div className="cpo-payment-card-enroll-modal-input-container">
                  <input type="text" placeholder='카드번호를 입력해주세요("-"제외)' value={cardNumber} onChange={(e)=>{ setCardNumber(e.target.value)}} onInput={(e)=>{cardNumInput(e.target)}} onClick={(e)=>{e.stopPropagation(); }}/>
                </div>
              </div>
              <div>             
                
                <div className='cpo-payment-card-enroll-grid-wrapper'>
                  <div>
                    <div className='cpo-payment-card-enroll-input-title required'><p>유효 일자</p></div>
                    <div className='cpo-payment-card-enroll-date-input-wrapper'>
                      <div className="cpo-payment-card-enroll-modal-input-container">
                        <input type="text" placeholder='MM' maxLength={2} value={cardMM} onChange={(e)=>{ setCardMM(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                      </div>
                      <div className="cpo-payment-card-enroll-modal-input-container">
                        <input type="text" placeholder='YY' maxLength={2} value={cardYY} onChange={(e)=>{ setCardYY(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='cpo-payment-card-enroll-input-title required'><p>CVC</p></div>
                      <div className='cpo-payment-card-enroll-input-wrapper cvc'>
                        <div className="cpo-payment-card-enroll-modal-input-container cvc">
                          <input type="text" placeholder='000' maxLength={3} value={cvc} onChange={(e)=>{ setCvc(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                        </div>
                      </div>
                    </div>
                </div>
                <div className="cpo-payment-card-enroll-grid-wrapper">
                  <div>
                    <div className='cpo-payment-card-enroll-input-title required'><p>카드 비밀번호</p></div>
                    <div className='cpo-payment-card-enroll-input-wrapper password'>
                      <div className="cpo-payment-card-enroll-modal-input-container">
                        <input type="text" placeholder='00' value={partialPassword} max={2} onChange={(e)=>{ setPartialPassword(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                      </div>
                      <p>**</p>
                    </div>
                  </div>
                  <div>
                    <div className='cpo-payment-card-enroll-input-title required'><p>생년월일</p></div>
                    <div className="cpo-payment-card-enroll-modal-input-container">
                      <input type="text" placeholder='YYMMDD' value={customerIdentityNumber} max={6} onChange={(e)=>{ setCustomerIdentityNumber(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p className='cpo-payment-card-enroll-modal-title'>고객 정보<color>(선택사항)</color></p>
                <div className='cpo-payment-card-enroll-input-title'><p>이름</p></div>
                <div className="cpo-payment-card-enroll-modal-input-container">
                  <input type="text" placeholder='이름을 입력해주세요' value={customerName} onChange={(e)=>{ setCustomerName(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                </div>
              </div>
              <div>
                <div className='cpo-payment-card-enroll-input-title'><p>이메일</p></div>
                <div className="cpo-payment-card-enroll-modal-input-container">
                  <input type="text" placeholder='이메일을 입력해주세요' value={customerEmail} onInput={(e)=>{ emailInput(e.target);}} onChange={(e)=>{ setCustomerEmail(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                  {customerEmail!==""&&emailPatternValidate===false && <p className='cpo-payment-card-enroll-modal-alertMsg'>이메일 형식을 입력해주세요</p>}
                </div>

              </div>
            </div>

            <div className='cpo-payment-card-modal-two-button-container'>
              <p>{reqMsg}</p>
              <div className={'cpo-payment-card-modal-back-button '} onClick={()=>{setEnrollTab(0)}}><FaChevronLeft/><p>이전</p></div>
              <div className={'cpo-payment-card-modal-enroll-button '+ `${cardName == "" || cardNumber.length < 15 
                || cardYY.length !==2 || cardMM.length !==2 || cvc.length !== 3 || partialPassword.length !== 2 || (enrollTab == 1 ? customerIdentityNumber.length !== 6 : customerIdentityNumber.length !== 10)   
                || (customerEmail == ""?false:emailPatternValidate===false?true:false)
                ?"none":""}`} onClick={()=>{reqPostCard()}}>
                  {loadingOn ? <Loading/> : <p>등록하기</p>}
                </div>
            </div>
          </>
          :
          <>
            <div className='cpo-payment-card-enroll-modal-flex-wrapper'>
              <div>
                <p className='cpo-payment-card-enroll-modal-title'>카드 정보</p>

                <div className='cpo-payment-card-enroll-input-title required'><p>카드 별명</p></div>
                <div className="cpo-payment-card-enroll-modal-input-container">
                  <input type="text" placeholder='카드별명을 입력해주세요' value={cardName} onChange={(e)=>{ setCardName(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                </div>
                
                <div className='cpo-payment-card-enroll-input-title required'><p>카드 번호</p></div>
                <div className="cpo-payment-card-enroll-modal-input-container">
                  <input type="text" placeholder='카드번호를 입력해주세요("-"제외)' value={cardNumber} onChange={(e)=>{ setCardNumber(e.target.value)}} onInput={(e)=>{cardNumInput(e.target)}} onClick={(e)=>{e.stopPropagation(); }}/>
                </div>


                
              </div>
              <div>
              <div className='cpo-payment-card-enroll-grid-wrapper'>
                  <div>
                    <div className='cpo-payment-card-enroll-input-title required'><p>유효 일자</p></div>
                    <div className='cpo-payment-card-enroll-date-input-wrapper'>
                      <div className="cpo-payment-card-enroll-modal-input-container">
                        <input type="text" placeholder='MM' maxLength={2} value={cardMM} onChange={(e)=>{ setCardMM(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                      </div>
                      <div className="cpo-payment-card-enroll-modal-input-container">
                        <input type="text" placeholder='YY' maxLength={2} value={cardYY} onChange={(e)=>{ setCardYY(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='cpo-payment-card-enroll-input-title required'><p>CVC</p></div>
                      <div className='cpo-payment-card-enroll-input-wrapper cvc'>
                        <div className="cpo-payment-card-enroll-modal-input-container cvc">
                          <input type="text" placeholder='000' maxLength={3} value={cvc} onChange={(e)=>{ setCvc(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                        </div>
                      </div>
                    </div>
                </div>
                <div className="cpo-payment-card-enroll-grid-wrapper">
                  <div>
                    <div className='cpo-payment-card-enroll-input-title required'><p>카드 비밀번호</p></div>
                    <div className='cpo-payment-card-enroll-input-wrapper password'>
                      <div className="cpo-payment-card-enroll-modal-input-container">
                        <input type="text" placeholder='00' value={partialPassword} max={2} onChange={(e)=>{ setPartialPassword(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                      </div>
                      <p>**</p>
                    </div>
                  </div>
                  <div>
                    <div className='cpo-payment-card-enroll-input-title required'><p>사업자등록번호</p></div>
                    <div className="cpo-payment-card-enroll-modal-input-container">
                      <input type="text" placeholder='사업자등록번호 10자리' max={10} value={customerIdentityNumber} onChange={(e)=>{ setCustomerIdentityNumber(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p className='cpo-payment-card-enroll-modal-title'>고객 정보<color>(선택사항)</color></p>
                <div className='cpo-payment-card-enroll-input-title'><p>이름</p></div>
                <div className="cpo-payment-card-enroll-modal-input-container">
                  <input type="text" placeholder='이름을 입력해주세요' value={customerName} onChange={(e)=>{ setCustomerName(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                </div>
              </div>
              <div>
              <div className='cpo-payment-card-enroll-input-title'><p>이메일</p></div>
                <div className="cpo-payment-card-enroll-modal-input-container">
                  <input type="text" placeholder='이메일을 입력해주세요' value={customerEmail} onInput={(e)=>{ emailInput(e.target);}} onChange={(e)=>{ setCustomerEmail(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                </div>
                {customerEmail!==""&&emailPatternValidate===false && <p className='cpo-payment-card-enroll-modal-alertMsg'>이메일 형식을 입력해주세요</p>}
              </div>
            </div>

            <div className='cpo-payment-card-modal-two-button-container'>
              <p>{reqMsg}</p>
              <div className={'cpo-payment-card-modal-back-button '} onClick={()=>{setEnrollTab(0)}}><FaChevronLeft/><p>이전</p></div>
              <div className={'cpo-payment-card-modal-enroll-button '+ `${
                cardName == "" || cardNumber.length < 15 || cardYY.length !==2 || cardMM.length !==2 || cvc.length !== 3 || partialPassword.length !== 2 || (enrollTab == 1 ? customerIdentityNumber.length !== 6 : customerIdentityNumber.length !== 10)
                || (customerEmail == ""?false:emailPatternValidate===false?true:false)
                ?"none":""}`} onClick={()=>{reqPostCard()}}>{loadingOn ? <Loading/> : <p>등록하기</p>}</div>
            </div>
          </>
        }
        
        
        
      </div>
    </div>
  )
}
export default PaymentCardEnrollModal;