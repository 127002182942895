import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom";

import axios from 'axios';
import {setNum} from '../../store.js'
import NoticeItem from "../../components/list/NoticeItem.js";

export const CpoNotice = () => {
  let navigator = useNavigate();

  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();

  // 진입 시 redux에 page 적용
  useEffect(()=>{
    dispatch(setNum({id:11, idx:2}));
  },[])
  let noticeList = [{
    "NoticePostId": 1,
    "Title": "공지입니다.",
    "Contents": `공지글`,
    "OwnerId": "kassid",
    "PostDate": "2024.07.03"
  }]

  return (
    <div className='cpo-customer-service-container'>
      <div className="cpo-customer-service-inner notice">
        <div className="cpo-notice-container">
          <div className={"cpo-notice-header "}>
            <div className='cpo-notice-append-button' onClick={()=>{navigator('./edit')}}>
              <p>새 공지 등록</p>
            </div>
            <div className='cpo-faq-tab-container'>
              <div className={"faq-tab-button selected"}><p>{'공지'}</p></div>
              <div className={'faq-tab-bar '+'tab0'}></div>
            </div>
          </div>
          <div className="cpo-notice-list-container">
            {
              noticeList.map((it, idx)=>{
                return(<NoticeItem key={idx} data={it} />);
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoNotice;