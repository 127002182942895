import React, { useRef, useState, useEffect } from 'react';
import { MdSearch } from "react-icons/md";
import { useDispatch } from "react-redux"
import {setNum} from '../../store.js'
import axios from 'axios';
import { getCookie } from '../../util/util.js';
import { exceptDeletedStation } from '../../util/util.js';
import { getStationList, getChargerByStationId } from '../../api/infra/infraApi.js';

import { getCardList } from '../../api/payment/paymentApi.js';


import Carousel from '../../components/carousel/Carousel';
import ChargerCard from '../../components/list/ChargerCard.js';
import ChasSettingModal from '../../components/modals/ChasSettingModal.js';
import CpoChargerCardEventModal from '../../components/modals/CpoChargerCardEventModal.js';
import PaymentMethodAlert from '../../components/alerts/PaymentMethodAlert.js';

// 충전기 운용제어
const CpoChargerControl = (props) => {
  // redux
  let dispatch = useDispatch();
  let signedToken = getCookie("signedToken");

  // 진입 시 redux에 page 적용
  useEffect(()=>{
    dispatch(setNum({id:8, idx:0}));
  },[dispatch])

  // 선택된 충전소 idx
  const [selectedStation, setSelectedStation] = useState(null);
  // 충전소 데이터 Array<Object>
  const [stationInfo, setStationInfo]=useState(null);
  // 충전기 데이터 Array<Object>
  const [chargerInfo, setChargerInfo]=useState(null);
  // 충전기 controlhub 데이터 Array<Object>
  const [chargerHubInfo, setChargerHubInfo]=useState(null);

  
  const reqGetCardList = async()=>{
    let result = await getCardList();
    if(result.status === 200){
      let carList = [...result.data]
      if(carList.length === 0){
        // TODO
        setPaymentMethodAlertOn(true);
      }
    }
    else{
      // TODO
    }
  }

  
  useEffect(()=>{
    reqGetCardList();
  },[])


  // let cpo = 'f296c394-8e67-4c83-9cff-3240c3286e8d';

  useEffect(()=>{
    reqGetStationList();
  },[])
  
  // cpo가 소유한 station GET
  const reqGetStationList = async(id)=>{  
    let result = await getStationList();
    if(result.status === 200){
      let temp = exceptDeletedStation(result);
      setStationInfo(temp);
    }
    else{

    }
  }

  // station에 종속된 charger
  const reqChargerList = async()=>{
    let result =[];
    for await(const station of stationInfo){
      let tempChargerList = await getChargerByStationId(station.ChargingStationId);
      result.push(tempChargerList.data);
    }
    setChargerInfo(result);
  }

  // chargerHub 정보 API GET
  const getChargerHubList = async(id)=>{
    let temp = [];
    await axios.get(`/controlhub/api/cpouser/v1/Chargers/${id}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      temp.push(res.data);
    })
    return temp;
  }
  
  const reqChargerHubList = async()=>{
    let result = [];
    for await(const chargerList of chargerInfo){
      console.log(chargerList);
      let tempList = [];
      for await(const charger of chargerList.Results){
        console.log(charger);
        let data = await getChargerHubList(charger.ChargerId)
        tempList.push(data);
      }
      result.push(tempList);
    }
    setChargerHubInfo(result);
  }

  // station 데이터 받은 후 chargerHub, charger 정보 GET 트리거
  useEffect(() => {
    if(!stationInfo)return;
    // getChargerHubList();
    reqChargerList();
  }, [stationInfo])
  useEffect(()=>{
    if(!chargerInfo)return;
    reqChargerHubList();
  },[chargerInfo])
  
  // search input ref
  const searchInputRef = useRef(null);
  // 검색 function
  const submitSearch= async(e)=>{
    e.preventDefault();
    console.log(searchInputRef.current.value);
  }
  
  const [modalOn, setModalOn] = useState(false);
  const search = (val)=>{
    setSelectedStation(stationInfo.indexOf(stationInfo.filter(it=>it.ChargingStationName == val)[0]));
  }
  const searchClick = ()=>{

    const val = document.getElementById('searchInput').value;
    const opts = document.getElementById('searchOptions').children;
    for (var i = 0; i < opts.length; i++) {
      if (opts[i].value === val) {
        search(opts[i].value);
        break;
      }
    }
  }

  const [chargerEventModalOn, setChargerEventModalOn] = useState(false);
  const [chargerEventModalIdx, setChargerEventModalIdx] = useState(null);
  const [chargerEventModalData, setChargerEventModalData] = useState(null);

  const [paymentMethodAlertOn, setPaymentMethodAlertOn] = useState(false);
  return (
    <div className='cpo-charger-control-container'>
      {chargerEventModalOn&&<CpoChargerCardEventModal getChargerList={reqChargerList} data={chargerEventModalData}  idx={chargerEventModalIdx} setOnOff={setChargerEventModalOn}/>}
      {paymentMethodAlertOn&&<PaymentMethodAlert setOnOff={setChargerEventModalOn}/>}
      <div className="cpo-charger-control-wrapper">
        {modalOn && chargerHubInfo && stationInfo && chargerInfo ? <ChasSettingModal getChargerHubList={reqChargerHubList} setOnOff={setModalOn} selectedStation={selectedStation} setSelectedStation={setSelectedStation} stationInfo={stationInfo} chargerInfo={chargerInfo} chargerHubInfo={chargerHubInfo}/>:<></>}
        <div className="cpo-charger-control-header">
          <p>
            나의 충전소
          </p>
          <div className="nav-input-container">
            <input id='searchInput' list='searchOptions' autocomplete='on' ref={searchInputRef} type="text" onInput={()=>{searchClick()}} placeholder='충전소를 검색하세요' onKeyDown={(e)=>{if(e.key == "Enter")search(e.target.value)}}/>
            <MdSearch className='search-icon'/>
          </div>
          <datalist id='searchOptions'>
            {stationInfo?.map((it,idx)=>{
              return(<option value={it.ChargingStationName}/>)
            })}
          </datalist>
        </div>
        {
          stationInfo && chargerInfo && chargerInfo.length!==0 ?
          <Carousel items={stationInfo} selected={selectedStation} setSelected={setSelectedStation}/>
          :<></>
        }
        <div className="cpo-charger-control-header second">
          <p onClick={()=>{console.log(selectedStation, stationInfo, chargerInfo, chargerHubInfo)}}>
            충전기
          </p>
          <div className={'cpo-charger-chas-setting-open-button '+`${ (chargerInfo && chargerInfo[selectedStation]?.Results.filter(it=>it.IsDeleted !== true).length == 0) || selectedStation==undefined ?"none":""}`} onClick={()=>{setModalOn(true)}}><p>CHAS 설정</p></div>
        </div>
        <div className="cpo-charger-control-device-container">
          {
            selectedStation==undefined?
              <div className='plz-select-msg'><p>충전소를 선택해주세요.</p></div>
            : chargerInfo[selectedStation]?.Results.map((it, idx)=>{
              if(it.IsDeleted !== true){
                return(
                  <ChargerCard setChargerEventModalData={setChargerEventModalData} setChargerEventModalOn={setChargerEventModalOn} setChargerEventModalIdx={setChargerEventModalIdx} getChargerHubList={reqChargerHubList} getChargerList={reqChargerList} data={it} key={idx} controlhubData={chargerHubInfo[selectedStation][idx][0]}/>
                );
              }
              else{
                return(<></>);
              }
            })
          }
        </div>
      </div>
    </div>
  );
};

export default CpoChargerControl;
