import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';

import { PiSlidersHorizontal } from "react-icons/pi";
import { FaSearch } from "react-icons/fa";


import { setCookie, getCookie } from '../../util/util';

import ChargingFeeModalChargerItem from '../list/ChargingFeeModalStationItem';

import ChargingFeeSearchModal from './ChargingFeeSearchModal';

import { numberInput } from '../../util/util';
import { postPricingEvent } from '../../api/chargerate/chargerateApi';

export const ChargingFeeSettingModal = (props) => {

  const [searchKeyword, setSearchKeyword] = useState();

  const [alertOn, setAlertOn] = useState(false);

  useEffect(()=>{
    if(alertOn){
      setTimeout(() => {
        setAlertOn(false)
      }, 1300);
    }
  },[alertOn])
  const [eventName, setEventName] = useState();
  const [membershipRate, setMembershipRate] = useState();
  const [nonMembershipRate, setNonMembershipRate] = useState();
  const [searchModalOn, setSearchModalOn] = useState(false);

  const [startDateTime, setStartDateTime] = useState();
  const [endDateTime, setEndDateTime] = useState();
  
  const [selectedStationList, setSelectedStationList] = useState([]);

  const reqPostPricingEvent = async()=>{
    let stationIds = [];
    for await(const stationIdx of checkStationList){
      stationIds.push(props.stationInfo[stationIdx]?.ChargingStationId);
    }
    let reqObj = {
      "eventName": eventName,
      "memberPrice": membershipRate,
      "nonMemberPrice": nonMembershipRate,
      "startDateTime": startDateTime,
      "endDateTime": endDateTime,
      "stationIds": stationIds
    };
    let result = await postPricingEvent(reqObj);
    if(result.status === 200){
      console.log(result);
    }
    else{
      console.log(result);
    }
    await props.reqGetAllPricingEvent();
    props.setOnOff(false);
  }

  // check한 station list
  const [checkStationList, setCheckStationList] = useState([]);

  // checked state (모든 아이템 check)
  const [checked, setChecked] = useState(false);
  useEffect(()=>{
    if(checked){
      let temp = new Array(props.stationInfo.length).fill(0).map((_, i) => i)
      setCheckStationList(temp);
    }
    else{
      setCheckStationList([]);
    }
  },[checked])

  return (
    <div className="cpo-charging-fee-setting-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-charging-fee-setting-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); setSearchModalOn(false)}}>
        {alertOn && <div className='cpo-charging-fee-setting-modal-alert'><p>정상적으로 적용이 완료되었습니다.</p></div>}
        <div className="cpo-charging-fee-setting-modal-header">
          <title onClick={()=>{console.log(props.chargerRateList);}}>이벤트요금 설정</title>
          {/* <p>이벤트를 추가할 충전소를 선택 후, 기간을 입력하세요</p> */}
        </div>
        <div className='cpo-charging-fee-setting-modal-period-selection-container'>
          <div>
            <p>이벤트명</p>
            <div className="cpo-setting-default-charging-rate-input-container">
              <input type="text" placeholder='이벤트명을 입력하세요' value={eventName} onChange={(e)=>{setEventName(e.target.value)}} />
            </div>
          </div>
          <div>
            <p>운영기간</p>
            <div className='cpo-charging-fee-setting-modal-period-input-wrapper'>
              <div className='cpo-charging-fee-setting-modal-period-input-container'>
                <input type='date'  max={endDateTime ? endDateTime : "9999-12-31"} placeholder='' value={startDateTime} onChange={(e)=>{setStartDateTime(e.target.value)}} onSubmit={()=>{return false}} onClick={(e)=>{e.stopPropagation(); }}/>
              </div>
              <div>~</div>
              <div className='cpo-charging-fee-setting-modal-period-input-container'>
                <input type='date' min={startDateTime ? startDateTime:null}  max={"9999-12-31"} placeholder='' value={endDateTime} onChange={(e)=>{setEndDateTime(e.target.value)}} onSubmit={()=>{return false}} onClick={(e)=>{e.stopPropagation(); }}/>
              </div>
            </div>
          </div>
        </div>
        <div className='cpo-charging-fee-setting-modal-period-selection-container'>
          <div>
            <p>회원요금</p>
            <div className="cpo-setting-default-charging-rate-input-container">
              <input type="text" placeholder='0' onInput={(e)=>{numberInput(e.target)}} value={membershipRate} onChange={(e)=>{setMembershipRate(e.target.value)}} />
            </div>
          </div>
          <div>
            <p>비회원요금</p>
            <div className="cpo-setting-default-charging-rate-input-container">
              <input type="text" placeholder='0' onInput={(e)=>{numberInput(e.target)}} value={nonMembershipRate} onChange={(e)=>{setNonMembershipRate(e.target.value)}} />
            </div>
          </div>
        </div>
        
        <div className="cpo-charging-fee-setting-modal-search-container">
          {searchModalOn && <ChargingFeeSearchModal/>}
          <div className="cpo-charging-fee-setting-modal-search-filter-button" onClick={(e)=>{e.stopPropagation(); e.preventDefault();setSearchModalOn(!searchModalOn)}}>검색필터<PiSlidersHorizontal/></div>
          <div className="cpo-charging-fee-setting-modal-search-input-container">
            <input type="text" placeholder={'충전소 이름으로 검색하기'} value={searchKeyword} onChange={(e)=>{setSearchKeyword(e.target.value)}}/>
            <FaSearch/>
          </div>
        </div>
        <div className='cpo-charging-fee-setting-modal-table'>
          <div className='cpo-charging-fee-setting-modal-table-column'>
            <div className='cpo-charging-fee-setting-modal-table-column-item'>
              <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
                <span className="checkbox_icon"></span>
              </label>
              <p>전체</p>
            </div>
            <div className='cpo-charging-fee-setting-modal-table-column-item'><p>충전소명</p></div>
            <div className='cpo-charging-fee-setting-modal-table-column-item'><p>주소</p></div>
          </div>
          <div className='cpo-charging-fee-setting-modal-item-list'>
            {
            props.chargerInfo&&
              (props.stationInfo?.map((ia,idx)=>{
                return(
                  <ChargingFeeModalChargerItem checkStationList={checkStationList} setCheckStationList={setCheckStationList} setAlertOn={setAlertOn} data={ia} idx={idx}/>
              )
              }))
            }
          </div>
        </div>
        {/* <div className='cpo-charging-fee-setting-modal-period-selection-container'>
          <div>
            <p>무료운영기간</p>
            <div className='cpo-charging-fee-setting-modal-period-input-wrapper'>
              <div className='cpo-charging-fee-setting-modal-period-input-container'>
                <input type='date'  max={"9999-12-31"} placeholder='' onSubmit={()=>{return false}} onClick={(e)=>{e.stopPropagation(); }}/>
              </div>
              <div>~</div>
              <div className='cpo-charging-fee-setting-modal-period-input-container'>
                <input type='date'  max={"9999-12-31"} placeholder='' onSubmit={()=>{return false}} onClick={(e)=>{e.stopPropagation(); }}/>
              </div>
              <div className='cpo-charging-fee-setting-modal-period-button'>
                적용
              </div>
            </div>
          </div>
          <div>
            <p>할인기간</p>
            <div className='cpo-charging-fee-setting-modal-period-input-wrapper'>
              <div className='cpo-charging-fee-setting-modal-period-input-container'>
                <input type='date'  max={"9999-12-31"} placeholder='' onSubmit={()=>{return false}} onClick={(e)=>{e.stopPropagation(); }}/>
              </div>
              <div>~</div>
              <div className='cpo-charging-fee-setting-modal-period-input-container'>
                <input type='date'  max={"9999-12-31"} placeholder='' onSubmit={()=>{return false}} onClick={(e)=>{e.stopPropagation(); }}/>
              </div>
              <div className='cpo-charging-fee-setting-modal-period-button'>
                적용
              </div>
            </div>
          </div>
        </div> */}
        <div className='cpo-charging-fee-setting-modal-save-button-container'>
          <div onClick={()=>{reqPostPricingEvent()}} className={`cpo-charging-fee-setting-modal-save-button ${!eventName||!startDateTime||!endDateTime||!membershipRate||!nonMembershipRate||checkStationList.length === 0 ? "none":""}`}>저장하기</div>
        </div>
      </div>
    </div>
  )
}
export default ChargingFeeSettingModal;

