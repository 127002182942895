import React from 'react';
import { FaCheck } from "react-icons/fa6";


export const SubscriptionProductionCard = (props) => {

  return (
    <div className={"cpo-subscription-card-container "}>
      <div className='cpo-subscription-item-container front'>
        <div className="cpo-subscription-title-container">
          <p className='cpo-subscription-title'>{props.data.normalizedName}</p>
        </div>

        <div className='cpo-subscription-price-container'>
          <div><p>월간</p><p>{props.data.monthlyPrice}</p><p>원</p></div>
          <div><p>연간</p><p>{props.data.yearlyPrice}</p><p>원</p></div>
        </div>




        <div className='cpo-subscription-content-container'>
          {props.data.emoovFeatures.chargerLimit &&(
            <div>
              <FaCheck/>
              <p>{props.data.emoovFeatures.chargerLimit}개 충전기</p>
            </div>
          )}
        </div>



        

        <div className="cpo-subscription-btn-container">
          <div className='cpo-subscription-btn' onClick={(e)=>{e.stopPropagation(); props.setSubscriptionItem(props.data); props.setModalOn(true)}}>
            <p>
              결제
            </p>
          </div>
        </div>
      </div>

    </div>
  )
}

export default SubscriptionProductionCard;