import { FaRegBuilding } from "react-icons/fa";
import { CiUser } from "react-icons/ci";

export const SignUpTab1 = (props) => {
  return (
    <div className='sign-up-tab-container sign-up-tab1'>
      <div className="sign-up-category-button business" onClick={()=>{props.setStep(1); props.setTarget("business");}}>
        <FaRegBuilding/>
        <p className="sign-up-category-button-title"><strong>사업자</strong> 회원 가입</p>
        <p className="sign-up-category-button-desc">충전소와 충전기를 <strong>관리하고 싶어요</strong></p>
      </div>
      <div className="sign-up-category-button normal" onClick={()=>{props.setStep(1); props.setTarget("normal");}}>
        <CiUser/>
        <p className="sign-up-category-button-title"><strong>일반</strong> 회원 가입</p>
        <p className="sign-up-category-button-desc">혹시 <strong>초대받은</strong> 이력이 있으신가요?</p>
      </div>
    </div>
  )
}
export default SignUpTab1;