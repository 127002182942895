import React, { useRef, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { IoSend } from "react-icons/io5";
import { IoIosCall } from "react-icons/io";
import { putBreakdownPostStatus, putBreakdown } from '../../api/cs/csApi.js';

import { getCpoId } from '../../util/util.js';

export const BreakdownItem = (props) => {
  let cpoMemberId = getCpoId();
  const[clicked, setClicked]=useState(false);
  
  const textRef = useRef();
  // textarea height 조정
  const handleResizeHeight = useCallback(() => {
    textRef.current.style.height = "";
    textRef.current.style.height = textRef.current.scrollHeight + "px";
  }, []);

  // input 값 state
  const [ReplyContents, setReplyContents] = useState(props.data.ReplyContents);

  // 첫 렌더링 시 서버에 답변등록이 안 되어 있는 경우
  useEffect(()=>{
    // 수정모드 설정
    if(props.data.ReplyContents == null) setIsPatch(true);
  },[])

  // 수정모드 여부
  const [IsPatch, setIsPatch] = useState(false);

  const [timeStringArr1, setTimeStringArr1] = useState([]);
  const [timeStringArr2, setTimeStringArr2] = useState([]);
  const [diffSeconds, setDiffSeconds] = useState(null);

  const getDiff = async()=>{
    let tempArr1=[], tempArr2 = [];
    if(props.data.PostDate !== "0001-01-01T00:00:00"){
      let tmp1 = new Date(props.data.PostDate);
      tmp1.setHours(tmp1.getHours() + 9);
      tempArr1 = tmp1.toLocaleString().replaceAll(".","").split(' ');

      let now = new Date();
      let diff = Math.abs(now.getTime() - tmp1.getTime());
      let result = Math.ceil(diff/1000);
      let val = renderPostTime(result, tempArr1);
      setDiffSeconds(val);
    }
    if(props.data.ReplyDate !== "0001-01-01T00:00:00"){
      let tmp2 = new Date(props.data.ReplyDate);
      tmp2.setHours(tmp2.getHours() + 9);
      tempArr2 = tmp2.toLocaleString().replaceAll(".","").split(' ');
    }

    // 0001-01-01T00:00:00
    setTimeStringArr1(tempArr1);
    setTimeStringArr2(tempArr2);
  }

  useEffect(()=>{
    getDiff();
  },[props.data.ReplyContents])

  const renderPostTime =(val, tempArr1)=>{
    if (0<val && val<60){ return `${val}초전`;}
    else if (60<=val && val<3600){return `${parseInt(val / (60))}분전`;}
    else if (3600<=val && val<86400){return `${parseInt(val / (60 * 60))}시간전`;}
    else if (86400<=val && val<691200){return `${parseInt(val / (60 * 60 * 24))}일전`;}
    else if(691200<=val){return `${tempArr1[1]}/${tempArr1[2]}`;}
    return '-';
  }

  // {
  //   "BreakdownPostId": 1,
  //   "ChargerCodeNo": "MOTA12345",
  //   "ChargerOwnerId": "kassid",
  //   "PhoneNumber": "01012341234",
  //   "Contents": "고장신고합니다.",
  //   "Status": "접수중",
  //   "ReplyContents": null,
  //   "PostDate": "2024-07-16T05:52:07.21208",
  //   "ReceptionDate": "0001-01-01T00:00:00",
  //   "ReplyDate": "0001-01-01T00:00:00"
  // },

  const receipt = async()=>{
    let result = await putBreakdownPostStatus(props.data.BreakdownPostId);
    props.reqGetBreakdownList(cpoMemberId);
  }
  
  const send = async()=>{
    if(ReplyContents.trim() == "") return;
    let reqObj = {
      "breakdownPostId": props.data.BreakdownPostId,
      "replyContents": "처리할게요"
    };
    let result = await putBreakdown(reqObj);
    props.reqGetBreakdownList(cpoMemberId);
    setIsPatch(false);
  }

  return (
    <>
      {
        props.category == props.data.Category || props.category == "전체"
        ?
        <div className={"cpo-breakdown-item-container " +`${clicked ? "clicked":""}`} onClick={()=>{setClicked(!clicked);}}>
          <div className='cpo-breakdown-item-comment-bool-container'>
            <div className={"cpo-breakdown-item-comment-bool "+`${props.data.Status == "처리완료" ? "done":props.data.Status == "접수완료"? "recept":"none"}`}>
              <p>{props.data.Status}</p>
            </div>
          </div>
          <div>
            <p>{props.data.ChargerCodeNo}</p>
          </div>
          <div className={"cpo-breakdown-item-content-container " +`${clicked ? "clicked":""}`}>
            <pre>
              {
                props.data.Contents
              }
            </pre>
          </div>
          <div className='cpo-breakdown-item-date-open-container'>
            <p>{diffSeconds}</p>
          </div>
          <div className={'cpo-breakdown-item-comment-container '+`${clicked ? "clicked":""}`} onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
            {/* {props.data.Status == "접수중" &&(<div className='cpo-breakdown-item-recept-button' onClick={(e)=>{e.stopPropagation(); e.preventDefault();receipt();}}><p>접수하기</p></div>)} */}
            
            <div className="cpo-breakdown-customer-info-container">
              <p>신고자</p>
              <IoIosCall/>
              <p>{props.data.PhoneNumber}</p>  
              <p>{ timeStringArr1[0] !== undefined ?  `${timeStringArr1[0]}`+"."+`${timeStringArr1[1]?.toString().padStart(2,'0')}`+"."+`${timeStringArr1[2]?.toString().padStart(2,'0')} `+`${timeStringArr1[3]} `+`${timeStringArr1[4]}` : null}</p>
            </div>

            {
              IsPatch ?
              <div className={'cpo-breakdown-item-comment '}>
                <textarea ref={textRef} placeholder={"답변 입력하기.."} readOnly={false} onInput={handleResizeHeight}  value={ReplyContents} onChange={(e)=>{if(!IsPatch )return;  setReplyContents(e.target.value)}}></textarea>
              </div>
              :
              <div>
                <div className='cpo-breakdown-answer-info-container'>
                  <p>답변</p>
                  <p>{ timeStringArr2[0] !== undefined ?  `${timeStringArr2[0]}`+"."+`${timeStringArr2[1]?.toString().padStart(2,'0')}`+"."+`${timeStringArr2[2]?.toString().padStart(2,'0')} `+`${timeStringArr2[3]} `+`${timeStringArr2[4]}` : null}</p>
                </div>
                <div className={'cpo-breakdown-item-comment ' + `${!IsPatch ? "reply":""}` }>
                  <textarea ref={textRef} placeholder="답변 입력하기.." readOnly={true} onInput={handleResizeHeight}  value={ReplyContents} onChange={(e)=>{if(!IsPatch )return;  setReplyContents(e.target.value)}}></textarea>
                </div>
              </div>

            }
            <div className="cpo-breakdown-item-comment-send-button-wrapper">
              <div></div>
              {
                props.data.Status == "접수중" ?
                <div className='cpo-breakdown-item-comment-send-button' onClick={()=>{send();}}><p>답변하기</p><IoSend/></div>
                :
                props.data.Status == "접수완료" ?
                <div className={'cpo-breakdown-item-comment-send-button ' +`${!ReplyContents ? "none":""}` } onClick={()=>{receipt()}}><p>접수하기</p><IoSend/></div>
                :
                <></>
              }
            </div>

          </div>

        </div>
        :<></>
      }
    </>
  )
}
export default BreakdownItem;