import React, { useState, useEffect } from 'react';


export const ChargingFeeModalStationItem = (props) => {

  // checked 상태
  const [checked, setChecked] = useState(false);
  useEffect(()=>{
    // 리스트에서 인덱스 찾기
    let itemIdx = props.checkStationList.indexOf(props.idx);
    // check true인 경우
    if(checked){
      if(itemIdx !== -1){
        return;
      }
      props.setCheckStationList([...props.checkStationList, props.idx]);
    }
    else{
      // false 인 경우
      
      // 만약 없는 경우
      if(itemIdx === -1){
        return;
      }
      // 있는 경우
      else{
        let temp = [...props.checkStationList];
        temp.splice(itemIdx,1);
        props.setCheckStationList(temp);
      }
    }
  },[checked])



  useEffect(()=>{
    let itemIdx = props.checkStationList.indexOf(props.idx);
    if(itemIdx !== -1 && !checked){
      setChecked(true);
    }
    else if(itemIdx === -1 && checked){
      setChecked(false);
    }
  },[props.checkStationList])

  return (
    <div className='charging-fee-modal-charger-item'>
      <div>
        <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
          <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
          <span className="checkbox_icon"></span>
        </label>
      </div>
      <div><p>{props.data.ChargingStationName}</p></div>
      <div><p>{`${props.data?.Address?props.data?.Address:""} ${props.data?.DetailedAddress?props.data?.DetailedAddress:""}`}</p></div>
    </div>
  )
}
export default ChargingFeeModalStationItem;


const timeConverter = (start, end)=>{
  if(!start&& !end ){
    return '설정된 기간 없음';
  }
  else{
    return `${start} ~ ${end}`;
  }
}