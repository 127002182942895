import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import { useBlocker, useNavigate, Routes, Route, Link, Switch, useParams } from "react-router-dom";
import { useLocation, unstable_usePrompt } from 'react-router-dom';
import axios from 'axios';
import {setNum} from '../../store.js'
import { FaChevronLeft } from "react-icons/fa";
import Blocker from '../../components/alerts/Blocker.js';
import Alert from '../../components/alerts/Alert.js';

export const CpoNoticeEdit = ({match}) => {
  let navigator = useNavigate();
  const params = useParams();
  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();
  
  // from navigator
  const location = useLocation();

  window.addEventListener("beforeunload", (event) => {

  });

  // let noticeList = {
  //   "NoticePostId": 1,
  //   "Title": "공지입니다.",
  //   "Contents": `공지글`,
  //   "OwnerId": "kassid",
  //   "PostDate" ""2000.00.00",
  // }
  
  // 수정 요청인가?
  const[isPatch, setIsPatch] = useState(false);

  // value
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  // 라우팅 가능 여부(등록버튼 클릭 시)
  const [routeEnable, setRouteEnable] = useState(false);

  // blocker
  const blocker = useBlocker(
    ({currentLocation,nextLocation}) =>
      ((title !== '' || content !== '' )&&
      currentLocation.pathname !== nextLocation.pathname) && !routeEnable
  );

  useEffect(()=>{
    // 페이지 설정
    dispatch(setNum({id:11, idx:2, edit:true}));
    // 수정모드 / 새글 등록모드 설정
    if(location.state !== null){
      setIsPatch(true);
      setTitle(location.state.Title);
      setContent(location.state.Contents);
    }
  },[])

  // 등록하기 버튼 상태
  const [enrollBtnStatus, setEnrollBtnStatus] = useState(false);
  // input validation
  useEffect(()=>{
    if(title.trim() == "" || content.trim() == ""){setEnrollBtnStatus(false);}
    else{
      setEnrollBtnStatus(true);
    }
  },[title, content])

  // alert 상태
  const [alertOnOff, setAlertOnOff] = useState(false);

  // 등록하기 / 수정하기
  const enroll = async()=>{
    // if(title.trim() == "" || content.trim() == "" || tabSelection == "")return;
    // if(isPatch){
    //   await axios.post(`/cs/api/Faq`,{
    //     "title": title.trim(),
    //     "category": tabSelection.name,
    //     "contents": content.trim(),
    //     "visibilityLevel": "회원종속",
    //     "ownerId": "kassid"
    //   }, 
    //   {
    //     headers: { 'content-type': 'application/json-patch+json' },
    //   }
    //   ).then((res)=>{
    //     getFaqList();
    //   })
    // }
    setRouteEnable(true);
  }
  
  useEffect(()=>{
    if(routeEnable)navigator(-1);
  },[routeEnable])


  return (
    // <div>CpoNoticeDetail + {`${params.id}`}</div>
    <div className='cpo-customer-service-container'>
      <div className="cpo-customer-service-inner notice">
        <div className="cpo-notice-container edit">
          <div className={"cpo-notice-detail-header edit"}>
            <div className='cpo-notice-detail-back-button edit' onClick={()=>{navigator(-1)}}><FaChevronLeft/><p>뒤로가기</p></div>
            <p className='cpo-notice-edit-header-title'>공지사항</p>
            <div className={'cpo-notice-append-button edit ' + `${enrollBtnStatus ? "" : "disabled"}`} onClick={()=>{setAlertOnOff(true)}}>
              <p>{isPatch ? "수정하기" : "등록하기"}</p>
            </div>
          </div>

          <div className='cpo-notice-edit-content-container edit'>
            <p>제목 란</p>
            <div className='cpo-notice-edit-title-wrapper'>
              <div className="cpo-notice-detail-title edit">
                <input type="text" placeholder='제목' value={title} onChange={(e)=>{setTitle(e.target.value);}}/>
              </div>
            </div>
            <p>내용 란</p>
            <div className="cpo-notice-detail-content-container edit">
              <textarea value={content} placeholder='내용' onChange={(e)=>{setContent(e.target.value);}}></textarea>
            </div>
          </div>
        </div>
      </div>
      {blocker.state === 'blocked' && (
        <Blocker blocker={blocker}/>
      )}
      {alertOnOff &&(<Alert title={'글을 등록하시겠습니까?'} contents={'확인버튼을 누르면 글이 등록됩니다.'} setOnOff={setAlertOnOff} trigger={enroll}/>)}
    </div>
  )
}
export default CpoNoticeEdit;


