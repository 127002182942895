import React, { useState } from 'react';
import { timestampFormat } from '../../util/util';
import { FaChevronDown } from "react-icons/fa6";


export const CpoLogReportErrorItem = (props) => {
  
  const [tabOpen, setTabOpen] = useState(false);
  const [checked, setChecked] = useState(null);


  return (
    <div className={'cpo-log-report-content-item log-tab3 '+`${tabOpen ? "open":""}`}>
      <div className={'cpo-log-report-content '+`${props.deleteMode?"deleteMode ":""}` +`${checked?"checked":""}`} onClick={()=>{setTabOpen(!tabOpen)}}>
        <div><p>{props.data.charger.displaySerialNumber}</p></div>
        <div><p>{props.data.connectorId}</p></div>
        <div><p>{timestampFormat(new Date(props.data.timestamp))}</p></div>
        <div><p>{props.data.errorCode}</p></div>
        <div><p>{props.data.info}</p></div>
        <div><p>{tabOpen ? "닫기":"펼치기"}</p><FaChevronDown/></div>
      </div>
      <div className='cpo-log-report-content-hidden'>
        <div><p>벤더 에러코드</p>{props.data.vendorErrorCode}</div>
        <div><p>벤더 아이디</p>{props.data.vendorId}</div>
      </div>
    </div>
  )
}
export default CpoLogReportErrorItem;