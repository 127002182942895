import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import {setNum} from '../../store.js'
import axios from 'axios';
import { setCookie, getCookie } from '../../util/util';
import { IoMdAddCircleOutline } from "react-icons/io";
import PaymentCardEnrollModal from '../../components/modals/PaymentCardEnrollModal.js';
import PaymentItem from '../../components/list/PaymentItem.js';
import PaymentFunctionModal from '../../components/modals/PaymentFunctionModal.js';

import { getCardList } from '../../api/payment/paymentApi.js';

const CpoPaymentMethod = (props) => {
  // redux
  let dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setNum({id:13, idx:0}));
    reqGetCardList();
  },[])
  
  const reqGetCardList = async()=>{
    let result = await getCardList();
    if(result.status === 200){
      setCardList([...result.data]);
    }
    else{
      // TODO
    }
  }
  const [cardList, setCardList] = useState([]);

  const [enrollModalOn, setEnrollModalOn] = useState(false);
  const [functionModalOn, setFunctionModalOn] = useState(false);
  const [functionRequest, setFunctionRequest] = useState(null);
  return (
    <div className="cpo-account-management-container">
      {enrollModalOn&&<PaymentCardEnrollModal getCardList={reqGetCardList} setOnOff={setEnrollModalOn} cardCount={cardList?.length}/>}
      {functionModalOn&&<PaymentFunctionModal setOnOff={setFunctionModalOn} data={functionRequest} getCardList={reqGetCardList}/>}
      <div className="cpo-account-management-inner">
        <div className="cpo-payment-method-container">
          <div className="cpo-payment-method-header">
            <div className='cpo-payment-method-title'>
              <p>
                등록된 카드
              </p>
              <p>{cardList.length}</p>
            </div>
            
            <div className='cpo-payment-method-add-button'onClick={()=>{setEnrollModalOn(true)}}><IoMdAddCircleOutline/><p>카드 등록하기</p></div>
          </div>
        </div>
        <div className="cpo-payment-method-content">
          <div className='cpo-payment-method-content-title'><p>기본 결제 카드</p></div>
          {
            cardList.map((it,idx)=>{
              if(!it.isDefault)return;
              return(
                <PaymentItem data={it} setFunctionModalOn={setFunctionModalOn} setFunctionRequest={setFunctionRequest} isDefault={true}/>
              )
            })
          }
          <div className='cpo-payment-method-content-title second'><p>결제 카드</p></div>
          {
            cardList.map((it,idx)=>{
              if(it.isDefault)return;
              return(
                <PaymentItem data={it} setFunctionModalOn={setFunctionModalOn} setFunctionRequest={setFunctionRequest}/>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}
export default CpoPaymentMethod;

/*
acquirerCode

cardType
createdDate
isDeleted
isValid
issuerCode
modifiedDate

name
number
ownerType
paymentCardId
paymentCardStatus
paymentGatewayType
*/