import React, { useRef, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { IoSend } from "react-icons/io5";

export const QnaItem = (props) => {
  const[clicked, setClicked]=useState(false);
  // {
  //   "EnquiryPostId": 1,
  //   "Category": "기타",
  //   "Contents": "기타적인 질문입니다.",
  //   "CallbackEmail": "edeward@gmail.com",
  //   "PostDate": "0001-01-01T00:00:00",
  //   "CallbackDate": "2024-07-08T21:22:26.317261",
  //   "CallbackContents": "문의에 대한 답변을 추가한다."
  // }
  const send = async()=>{
    if(CallbackContents.trim() === "") return;
    await axios.put(`/cs/api/Enquiry`,
    {
      "enquiryPostId": props.data.EnquiryPostId,
      "callbackContents": CallbackContents
    }, 
    {
      headers: { 'content-type': 'application/json-patch+json' },
    }
    ).then((res)=>{
      props.getQnaList();
      setIsPatch(false);
    })
  }
  const textRef = useRef();
  // textarea height 조정
  const handleResizeHeight = useCallback(() => {
    textRef.current.style.height = "";
    textRef.current.style.height = textRef.current.scrollHeight + "px";
  }, []);

  // input 값 state
  const [CallbackContents, setCallbackContents] = useState(props.data.CallbackContents);

  // 첫 렌더링 시 서버에 답변등록이 안 되어 있는 경우
  useEffect(()=>{
    // 수정모드 설정
    if(props.data.CallbackContents == null) setIsPatch(true);
  },[])

  // 수정모드 여부
  const [IsPatch, setIsPatch] = useState(false);

  const [timeStringArr1, setTimeStringArr1] = useState([]);
  const [timeStringArr2, setTimeStringArr2] = useState([]);
  const [diffSeconds, setDiffSeconds] = useState(null);

  const getDiff = async()=>{
    let tempArr1=[], tempArr2 = [];
    if(props.data.PostDate !== "0001-01-01T00:00:00"){
      let tmp1 = new Date(props.data.PostDate);
      console.log(tmp1);
      tmp1.setHours(tmp1.getHours() + 9);
      console.log(tmp1);
      tempArr1 = tmp1.toLocaleString().replaceAll(".","").split(' ');
      console.log(tempArr1)
      let now = new Date();
      let diff = Math.abs(now.getTime() - tmp1.getTime());
      let result = Math.ceil(diff/1000);
      let val = renderPostTime(result, tempArr1);
      setDiffSeconds(val);
    }
    if(props.data.CallbackDate !== "0001-01-01T00:00:00"){
      let tmp2 = new Date(props.data.CallbackDate);
      tmp2.setHours(tmp2.getHours() + 9);
      tempArr2 = tmp2.toLocaleString().replaceAll(".","").split(' ');
    }

    // 0001-01-01T00:00:00
    setTimeStringArr1(tempArr1);
    setTimeStringArr2(tempArr2);
  }

  useEffect(()=>{
    getDiff();
  },[props.data.CallbackContents])

  const renderPostTime =(val, tempArr1)=>{
    if (0<val && val<60){ return `${val}초전`;}
    else if (60<=val && val<3600){return `${parseInt(val / (60))}분전`;}
    else if (3600<=val && val<86400){return `${parseInt(val / (60 * 60))}시간전`;}
    else if (86400<=val && val<691200){return `${parseInt(val / (60 * 60 * 24))}일전`;}
    else if(691200<=val){return `${tempArr1[1]}/${tempArr1[2]}`;}
    return '-';
  }

  return (
    <>
      {
        props.category == props.data.Category || props.category == "전체"
        ?
        <div className={"cpo-qna-item-container " +`${clicked ? "clicked":""}`} onClick={()=>{setClicked(!clicked);}}>
          <div className='cpo-qna-item-comment-bool-container'>
            <div className={"cpo-qna-item-comment-bool "+`${props.data.CallbackContents !== null ? "exist":"none"}`}>
            {props.data.CallbackContents !== null ?
              <div className="cpo-faq-item-comment-bool exist"><p>응답완료</p></div>
              :
              <div className="cpo-faq-item-comment-bool none"><p>미응답</p></div>
            }
            </div>
          </div>
          {/* <div className="cpo-qna-item-category-title-container"><p>{`${props.data.Contents}`}</p></div> */}
          <div className={"cpo-qna-item-content-container " +`${clicked ? "clicked":""}`}>
            <pre>
              {
                props.data.Contents
              }
            </pre>
          </div>
          <div className="cpo-qna-item-category-container"><p>{props.data.Category}</p></div>
          <div className='cpo-qna-item-date-open-container'>
            <p>{diffSeconds}</p>
          </div>
          <div className={'cpo-qna-item-comment-container '+`${clicked ? "clicked":""}`} onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
            <div className="cpo-qna-questioner-info-container">
              <p>질문자</p>
              <p>{props.data.CallbackEmail}</p>
              <p>{ timeStringArr1[0] !== undefined ?  `${timeStringArr1[0]}`+"."+`${timeStringArr1[1]?.toString().padStart(2,'0')}`+"."+`${timeStringArr1[2]?.toString().padStart(2,'0')} `+`${timeStringArr1[3]} `+`${timeStringArr1[4]}` : null}</p>
            </div>
            
            

            {
              IsPatch ?
              <div className={'cpo-qna-item-comment ' + `${!IsPatch ? "reply":""}` }>
                <textarea ref={textRef} placeholder="답변 입력하기.." readOnly={!IsPatch ? true: false} onInput={handleResizeHeight}  value={CallbackContents} onChange={(e)=>{if(!IsPatch )return;  setCallbackContents(e.target.value)}}></textarea>
              </div>
              :
              <div className="cpo-qna-answer-container">
                <div className='cpo-qna-item-comment-time-container'>
                  <p>답변</p>
                  <p>{ timeStringArr2[0] !== undefined ?  `${timeStringArr2[0]}`+"."+`${timeStringArr2[1]?.toString().padStart(2,'0')}`+"."+`${timeStringArr2[2]?.toString().padStart(2,'0')} `+`${timeStringArr2[3]} `+`${timeStringArr2[4]}` : null}</p>
                </div>
                <textarea ref={textRef} readOnly={true} onInput={handleResizeHeight}  value={CallbackContents}></textarea>
              </div>
            }

            {
              IsPatch ?
              <div className='cpo-qna-item-comment-send-button-wrapper'>
                <div className={'cpo-qna-item-comment-send-button ' + `${!CallbackContents ? "none":""}`} onClick={()=>{send()}}><p>답변하기</p><IoSend/></div>
              </div>
              :<></>
            }
          </div>

        </div>
        :<></>
      }
    </>
  )
}
export default QnaItem;

