import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';

import { setCookie, getCookie } from '../../util/util';

import { TfiClose } from "react-icons/tfi";
import ChargerCardEventTab0 from '../tabs/ChargerCardEventTab0';
import ChargerCardEventTab1 from '../tabs/ChargerCardEventTab1';
import ChargerCardEventTab2 from '../tabs/ChargerCardEventTab2';
import ChargerCardEventTab3 from '../tabs/ChargerCardEventTab3';
import ChargerCardEventTab4 from '../tabs/ChargerCardEventTab4';
import ChargerCardEventTab5 from '../tabs/ChargerCardEventTab5';

import CpoInstallEnrollModal from '../../components/modals/CpoInstallEnrollModal.js';
import CpoInstallAdminEnrollModal from '../../components/modals/CpoInstallAdminEnrollModal.js';
import CpoAppendFirmWareFileModal from '../modals/CpoAppendFirmWareFileModal.js';


export const CpoChargerCardEventModal = (props) => {
  const signedToken =  getCookie('signedToken')
  
  const [appendFirmwareModalOn, setAppendFirmWareModalOn] = useState(false);
  const [companyEnrollModalOn,setCompanyEnrollModalOn] = useState(false);
  const [adminEnrollModalOn,setAdminEnrollModalOn] = useState(false);
  return (
    <>
    <div className="cpo-group-invitation-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); }}>
      <div className="cpo-group-invitation-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-group-invitation-modal-header">
          <title>
            {
              props.idx==0?
              "기기사양"
              :
              props.idx==1?
              "담당자"
              :
              props.idx==2?
              "연결 테스트"
              :
              props.idx==3?
              "펌웨어 업데이트"
              :
              props.idx==4?
              "로그 조회"
              :              
              props.idx==5?
              "삭제"
              :
              ""
            }
          </title>
          <div className='cpo-group-invitation-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        {
          props.idx ==0?
          <ChargerCardEventTab0 data={props.data}/>
          :
          props.idx ==1?
          <ChargerCardEventTab1 data={props.data} setCompanyEnrollModalOn={setCompanyEnrollModalOn} setAdminEnrollModalOn={setAdminEnrollModalOn}/>
          :
          props.idx ==2?
          <ChargerCardEventTab2 data={props.data}/>
          :
          props.idx ==3?
          <ChargerCardEventTab3 data={props.data} setModalOn={setAppendFirmWareModalOn}/>
          :
          props.idx ==4?
          <ChargerCardEventTab4 data={props.data}/>
          :
          props.idx ==5?
          <ChargerCardEventTab5 data={props.data} getChargerList={props.getChargerList} setOnOff={props.setOnOff}/>
          :
          <></>
        }
        
      </div>
    </div>
    {appendFirmwareModalOn&&<CpoAppendFirmWareFileModal setOnOff={setAppendFirmWareModalOn}/>}
    {companyEnrollModalOn&&<CpoInstallEnrollModal setOnOff={setCompanyEnrollModalOn} />}
    {adminEnrollModalOn&&<CpoInstallAdminEnrollModal setOnOff={setAdminEnrollModalOn} />}
    </>
  )
}
export default CpoChargerCardEventModal


/*
{
    "ChargerId": "97685398-bcf1-4b58-b0fb-32903d03ae32",
    "DisplaySerialNumber": "R76991",
    "ChargerModel": {
        "Id": 5,
        "Manufacturer": {
            "ManufacturerId": 2,
            "Company": {
                "Id": "df1fb269-b2e2-4f75-aba6-7c461f2d339a",
                "Name": "충전기제조A"
            }
        },
        "Name": "new모델",
        "ChargingSpeed": 50,
        "ChargerType": "FAST_CHARGER",
        "ChargingPower": 100,
        "Voltage": 220,
        "ElectricCurrent": 100,
        "ConnectorType": "DC_Chademo_AC_ThreePhase_DC_Combo",
        "ActualRegistrationDate": "2024-09-20 04:55:16",
        "IsUsed": true,
        "Remarks": "이무브 제작",
        "DetailedSpec": "크기정보: 80*60*180(cm)",
        "CreatedBy": {
            "Id": "48438d62-6de0-4534-acef-e4a52cc6faee",
            "Name": "이남경"
        },
        "CreatedDate": "2024-09-20 05:00:05",
        "LastModifiedBy": {
            "Id": "48438d62-6de0-4534-acef-e4a52cc6faee",
            "Name": "이남경"
        },
        "LastModifiedDate": "2024-09-20 05:05:06",
        "IsDeleted": false,
        "DeletedDate": null
    },
    "ChargingStation": {
        "ChargingStationId": 26,
        "Cpo": {
            "CpoId": "c87afec3-a3fc-4abe-88c9-d6eacf23a901",
            "Name": "이남경",
            "SerialNumber": "AC"
        },
        "ProjectName": "null",
        "ChargingStationName": "새로운 충전소1",
        "OpStatus": "운영중지"
    },
    "Protocol": "OCPP_1_6",
    "ChargePointSerialNumber": "3fdsdc3",
    "ChargeBoxSerialNumber": null,
    "CommonCategory": null,
    "InstallationDate": null,
    "OperationStartDate": null,
    "AuthenticationKey": null,
    "KEPCOCustomerNumber": null,
    "ParentCustomerNumber": null,
    "CreatedBy": {
        "Id": "48438d62-6de0-4534-acef-e4a52cc6faee",
        "Name": "이남경"
    },
    "CreatedDate": "2024-08-21 05:34:51",
    "LastModifiedBy": {
        "Id": "48438d62-6de0-4534-acef-e4a52cc6faee",
        "Name": "이남경"
    },
    "LastModifiedDate": "2024-09-20 05:05:25",
    "IsDeleted": false,
    "DeletedDate": null
}
*/