import React, { useState } from 'react';
import { TfiClose } from "react-icons/tfi";

import { postStation, getStationNameDuplicationCheck } from '../../api/infra/infraApi.js';
import { Loading } from '../alerts/Loading';
export const StationEnrollModal = (props) => {

  const reqPostStation = async()=>{
    if(!nameContent)return;
    setLoadingOn(true);
    setIsError(false);
    let result = await postStation(nameContent);
    setLoadingOn(false);
    // console.log(result);
    if(parseInt(result.status/100)===2){
      props.getStationList();
      props.setOnOff(false);
    }
    else {
      setIsError(true);
    }
  }
  const [nameContent, setNameContent] = useState();
  const [nameContentValidation, setNameContentValidation] = useState(null);
  const [nameContentDuplication, setNameContentDuplication] = useState(null);
  
  const reqGetStationNameDuplicationCheck = async()=>{
    let result = await getStationNameDuplicationCheck(nameContent);
    if(result){
      setNameContentDuplication(true);
    }
    else{
      setNameContentDuplication("disable");
    }
  }

  const [isError, setIsError] = useState(false);
  const [loadingOn, setLoadingOn] = useState(false);
  return (
    <div className="cpo-station-enroll-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); }}>
      <div className="cpo-station-enroll-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-station-enroll-modal-header">
          <title>충전소 등록</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className="fast-enroll-input-container name">
      
      <input type="text" placeholder='충전소 명을 입력해주세요' value={nameContent} onChange={(e)=>{ setNameContent(e.target.value); setNameContentDuplication(null);setIsError(false);}} onClick={(e)=>{e.stopPropagation(); }}/>
          <div className={`fast-enroll-station-name-validation ` + `${nameContent===""||!nameContent?"disable":""}`} onClick={()=>{setIsError(false); reqGetStationNameDuplicationCheck()}}><p>중복확인</p></div>
          {
            isError === true ? 
              <p className={'fast-enroll-input-validation-msg alert'}>충전소 등록에 실패하였습니다.</p>
            :
              nameContentDuplication === "disable" ? 
                <p className={'fast-enroll-input-validation-msg alert'}>중복된 충전소명 입니다.</p>
              :
                nameContentDuplication ? 
                  <p className={'fast-enroll-input-validation-msg able'}>사용가능한 충전소명 입니다.</p>
                :
                  nameContent === "" || nameContent == null ?
                    nameContentValidation === "invalid" ?<p className={'fast-enroll-input-validation-msg alert'}>충전소명을 입력해주세요.</p>:<></>
                  :
                  <p className={'fast-enroll-input-validation-msg alert'}>중복확인 버튼을 눌러주세요.</p>
          }
        </div>
        <div>
          <div></div>
          <div className={'cpo-station-modal-enroll-button '+ `${nameContent!=="" && nameContentDuplication !== "disable" && nameContentDuplication  ?"":"none"}`} onClick={()=>{reqPostStation()}}>{loadingOn ? <Loading/> : <p>등록</p>}</div>
        </div>
      </div>
    </div>
  )
}
export default StationEnrollModal;