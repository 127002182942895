import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { setCookie, getCookie } from '../../util/util';

import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";

import CloudAlertRight from '../../components/alerts/CloudAlertRight';

export const SimpleLogin = () => {
  let navigator = useNavigate();
  
  const [passwordVisible, setPasswordVisible] = useState(false);

  const signedExpires = new Date();
  const refreshExpires = new Date();

  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);


  // 이메일 패턴 검사
  const [emailPatternValidate, setEmailPatternValidate] = useState(null); // false : 유효하지않은 이메일 형식, true: 이메일 형식

  // Validation Pattern
  const emailInput = (target)=>{
    const pattern = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-za-z0-9\-]+/;
    if(pattern.test(target.value) === false) {setEmailPatternValidate(false); }
    else { setEmailPatternValidate(true); }
  }

  const requestLogin = async()=>{
    console.log(email, password, checked);
    if(!email||!password){
      return;
    }
    if(!emailPatternValidate){
      return;
    }
    await axios.post("/account/api/NearCpoAuth/signin", 
    {
      "email": email,
      "password": password,
      "rememberMe": checked
    },{withCredentials : true})
    .then(async(res)=>{
      console.log(res);
      // 쿠키에 토큰 저장
      signedExpires.setMinutes(signedExpires.getMinutes() + 14);
      refreshExpires.setDate(refreshExpires.getDate()+7);

      setCookie('refreshToken', res.data.RefreshToken, {
        path: '/',
        secure: '/',
        httpOnly: true,
        expires: refreshExpires
      });
      setCookie('signedToken', res.data.SignedToken, {
        path: '/',
        secure: '/',
        expires: signedExpires
      });
      
    })
    .then(()=>{
      // navigator('/cpo')
      console.log(getCookie('signedToken'));
      console.log(getCookie('refreshToken'));
      }
    )
    .catch((err)=>{
      console.log(err)
      // setErrorAlertVisible(true);
    })
  }
  const [cloudAlertOn, setCloudAlertOn] = useState(false);
  useEffect(()=>{
    if(cloudAlertOn){
      setTimeout(() => {
        setCloudAlertOn(false);
      }, 2000);
    }
  },[cloudAlertOn])
  return (
    <div className='login-page-container'>
      <div className="login-page-wrapper">
        <title>간편로그인</title>
        <p className="login-input-title">이메일</p>
        <div className='login-input-container email'><input placeholder='이메일을 입력하세요' type="email" value={email} onChange={(e)=>{emailInput(e.target); setEmail(e.target.value)}}/></div>
        {emailPatternValidate===false&&<p className='login-input-validation'>이메일 형식을 입력해주세요.</p>}
        <div className='login-button simple' onClick={(e)=>{e.stopPropagation();e.preventDefault();setCloudAlertOn(true)}}>
          <p>로그인</p>
          {cloudAlertOn&&<CloudAlertRight data={"업데이트 예정인 기능입니다."}/>}
        </div>
      </div>
    </div>
  )
}
export default SimpleLogin;