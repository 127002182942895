import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import {setNum} from '../../store.js'

const CpoStationZoom = () => {

    let page = useSelector((state) => state.page);
    let dispatch  = useDispatch();

    useEffect(()=> {
        dispatch(setNum({id:101}))
    },[dispatch]);

    return (
        <div className='cpo-station-zoom-page-container'>
            Under Construction.
        </div>
    )
}

export default CpoStationZoom;