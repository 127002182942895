import React, {useState} from 'react'

import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";


import topBg from '../../img/servicePricingBg_1.png'
import topBg2 from '../../img/servicePricingBg_2.png'

import cpoServiceSlider_1 from '../../img/cpoServiceSlider_1.png'
import cpoServiceSlider_2 from '../../img/cpoServiceSlider_2.png'
import cpoServiceSlider_3 from '../../img/cpoServiceSlider_3.png'


import ocppServiceFlow from '../../img/ocppServiceFlow.svg'
import ocppCheck from '../../img/ocppCheck.svg'
import ocppServiceUsage from '../../img/ocppServiceUsage.svg'


export const ServicePricing = () => {

  const [sliderIdx, setSliderIdx] = useState(0);
  
  const [sliderImageList, setSliderImageList] = useState([cpoServiceSlider_1, cpoServiceSlider_2, cpoServiceSlider_3]);
  const test = <p>123</p>
  return (
    <div className='main-introduce-container'>
      {/* header */}
      <div className='main-introduce-header pricing'>
        <div className="main-introduce-bg">
          <img src={topBg} alt="" />
        </div>
        <div className="main-introduce-title-container">
          <h1>EMOOV 핵심 구독 상품</h1>
          <p>Free부터 Core Pack까지, 단계별로 준비된 맞춤형 혜택 서비스<br/>당신의 비즈니스에 최적화된 상품을 찾아보세요</p>
        </div>
      </div>
      {/* content */}
      <div className="main-introduce-content-section">
        <div className='main-introduce-pricing-container'>
          <PricingItem data={{title:"FREE",price:"0 원",quotes:[3], desc:"충전사업자를 준비하는 사업자 또는\n충전기 시험을 위한 개발자를 위한 상품",
            options:[
              "브랜드 이미지/이름 변경 가능",
              "안드로이드 및 애플 앱 스토어에서 고유의\n모바일 앱으로 등록 및 설치 가능",
              "유지보수 계약을 통한 앱 관리 가능",
              `초기개발 및 배포 비용<color>1 </color> 별도 (000원/1회)`,
            ]}}/>
          
          <PricingItem data={{title:"Starter Pack",price:"5 만원",quotes:[3], desc:"5대 미만의 충전기를 운영하고자 하는\n충전사업자를 위한 상품",
            options:[
              "브랜드 이미지/이름 변경 가능",
              "안드로이드 및 애플 앱 스토어에서 고유의\n모바일 앱으로 등록 및 설치 가능",
              "유지보수 계약을 통한 앱 관리 가능",
              `초기개발 및 배포 비용<color>1 </color> 별도 (000원/1회)`,
              "스타터팩을 구독하고 혜택을 경험해보세요",
            ]}}/>
          
          <PricingItem data={{title:"Core Pack",price:"50 만원부터", monthly:true, quotes:[3], desc:"본격적으로 100대 미만의 충전기를\n운영하고자 하는 충전사업자를 위한 상품",
            options:[
              "브랜드 이미지/이름 변경 가능",
              "안드로이드 및 애플 앱 스토어에서 고유의\n모바일 앱으로 등록 및 설치 가능",
              "유지보수 계약을 통한 앱 관리 가능",
              `초기개발 및 배포 비용<color>1 </color> 별도 (000원/1회)`,
              "스타터팩을 구독하고 혜택을 경험해보세요",
              "누구보다 쉽게 전기 충전기 사업자가 될 수\n있도록 이무브가 도와드려요"
            ]}}/>
          
        </div>
        <div className='main-introduce-pricing-quotes-container'>
          <p>(1) 초기개발비용에 포함된 내용: 안드로이드 개발자 계정 등록비용, iOS 개발자 계정 등록비용, 브랜드화 개발 비용</p>
        </div>
      </div>



      {/* header */}
      <div className='main-introduce-header pricing'>
        <div className="main-introduce-bg">
          <img src={topBg} alt="" />
        </div>
        <div className="main-introduce-title-container">
          <h1>모바일 앱 상품</h1>
          <p>Free부터 Core Pack까지, 단계별로 준비된 맞춤형 혜택 서비스<br/>당신의 비즈니스에 최적화된 상품을 찾아보세요</p>
        </div>
      </div>
      {/* content */}
      <div className="main-introduce-content-section">
        <div className='main-introduce-pricing-container'>
          <PricingItem data={{title:"FREE",price:"0 원",quotes:[3], desc:"충전사업자를 준비하는 사업자 또는\n충전기 시험을 위한 개발자를 위한 상품",
            options:[
              "브랜드 이미지/이름 변경 가능",
              "안드로이드 및 애플 앱 스토어에서 고유의\n모바일 앱으로 등록 및 설치 가능",
              "유지보수 계약을 통한 앱 관리 가능",
              `초기개발 및 배포 비용<color>1 </color> 별도 (000원/1회)`,
            ]}}/>
          
          <PricingItem data={{title:"White Labeling",price:"5 만원",quotes:[3], desc:"피처 및 디자인 수정 없이 자체 브랜드로\n다운로드 가능한 모바일 앱이 필요한 사업\n자를 위한 상품",
            options:[
              "브랜드 이미지/이름 변경 가능",
              "안드로이드 및 애플 앱 스토어에서 고유의\n모바일 앱으로 등록 및 설치 가능",
              "유지보수 계약을 통한 앱 관리 가능",
              `초기개발 및 배포 비용<color>1 </color> 별도 (000원/1회)`,
              "스타터팩을 구독하고 혜택을 경험해보세요",
            ]}}/>
          
          <PricingItem data={{title:"Brand New App",price:null, desc:"고유의 앱 디자인이나 기능을 필요로 하는\n사업자를 위한 상품",
            options:[]}}/>
          
        </div>
        <div className='main-introduce-pricing-quotes-container'>
          <p>(1) 초기개발비용에 포함된 내용: 안드로이드 개발자 계정 등록비용, iOS 개발자 계정 등록비용, 브랜드화 개발 비용</p>
        </div>
      </div>







      {/* header */}
      <div className='main-introduce-header pricing'>
        <div className="main-introduce-bg">
          <img src={topBg2} alt="" />
        </div>
        <div className="main-introduce-title-container">
          <h1>모바일 앱 상품</h1>
          <p>Free부터 Core Pack까지, 단계별로 준비된 맞춤형 혜택 서비스<br/>당신의 비즈니스에 최적화된 상품을 찾아보세요</p>
        </div>
      </div>
      {/* content */}
      <div className="main-introduce-content-section">
        <div className='main-introduce-pricing-container'>
          <PricingItem data={{title:"FREE",price:"0 원",quotes:[3], desc:"충전사업자를 준비하는 사업자 또는\n충전기 시험을 위한 개발자를 위한 상품",
            options:[
              "브랜드 이미지/이름 변경 가능",
              "안드로이드 및 애플 앱 스토어에서 고유의\n모바일 앱으로 등록 및 설치 가능",
              "유지보수 계약을 통한 앱 관리 가능",
              `초기개발 및 배포 비용<color>1 </color> 별도 (000원/1회)`,
            ]}}/>
          
          <PricingItem data={{title:"White Labeling",price:"5 만원",quotes:[3], desc:"피처 및 디자인 수정 없이 자체 브랜드로\n다운로드 가능한 모바일 앱이 필요한 사업\n자를 위한 상품",
            options:[
              "브랜드 이미지/이름 변경 가능",
              "안드로이드 및 애플 앱 스토어에서 고유의\n모바일 앱으로 등록 및 설치 가능",
              "유지보수 계약을 통한 앱 관리 가능",
              `초기개발 및 배포 비용<color>1 </color> 별도 (000원/1회)`,
              "스타터팩을 구독하고 혜택을 경험해보세요",
            ]}}/>
          
          <PricingItem data={{title:"Brand New App",price:null, desc:"고유의 앱 디자인이나 기능을 필요로 하는\n사업자를 위한 상품",
            options:[]}}/>
          
        </div>
        <div className='main-introduce-pricing-quotes-container'>
          <p>(1) 초기개발비용에 포함된 내용: 안드로이드 개발자 계정 등록비용, iOS 개발자 계정 등록비용, 브랜드화 개발 비용</p>
        </div>
      </div>



      

      <div className='main-introduce-dis-spacer'></div>



    </div>
  )
}
export default ServicePricing;




export const PricingItem = (props)=>{
  return(
    <div className={'main-introduce-pricing-item'}>
      <div className='main-introduce-pricing-item-header'>
        <p>{props.data.title}</p>
        <pre>{props.data.desc}</pre>
      </div>
      <div className='main-introduce-pricing-item-price-info'>
        {
          props.data.price === null ?
          <p className=' empty'>문의 필요</p>
          :
          <>
            <p>{props.data.price}</p>
            {props.data.monthly&&<p>/월</p>}
          </>
        }
      </div>
      <div className='main-introduce-pricing-item-option-container'>
        {props.data.options?.map((it,idx)=>{
          return(
            <>
              <div className='main-introduce-pricing-item-option'>
                <img alt="" />
                { props.data.quotes?.filter((it)=>it == idx)[0] ?
                  <pre dangerouslySetInnerHTML={{ __html: it }}></pre>
                  :<pre>{it}</pre>
                }
              </div>
            </>
          )
        })}
      </div>
      <div className='main-introduce-pricing-item-button'>지금 시작하기</div>
    </div>
  )
}