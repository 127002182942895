import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams, useLocation } from "react-router-dom";

import {setNum} from '../../store.js'
import { timestampFormat} from '../../util/util';
import { PiSlidersHorizontal } from "react-icons/pi";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";


export const CpoSystemChas = () => {
  const navigator = useNavigate();


  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setNum({id:12, idx:1}));
  },[])  

  let systemChasList = [
    {
        "actionSetId": 31,
        "name": "신세계향 액션셋",
        "description": "신세계향 충전기 연동 액션셋",
        "cpoId": "61db09c0-ee06-4f68-a5d2-592c8a7c2581",
        "createdDate": "2024-10-29 05:14:27",
        "lastModifiedDate": "2024-10-29 06:38:35",
        "version" : "0.0.1",
        "ocpp" : "1.6",
    },
    {
        "actionSetId": 32,
        "name": "LG e-centric 액션셋",
        "description": "LG e-centric 액션셋",
        "cpoId": "61db09c0-ee06-4f68-a5d2-592c8a7c2581",
        "createdDate": "2024-10-31 08:03:31",
        "lastModifiedDate": "2024-10-31 08:08:28",
        "version" : "0.0.1",
        "ocpp" : "1.6",
    }
  ];

  return (
    <div className="cpo-system-chas-container">
      <div className="cpo-system-chas-inner">
        <div className="cpo-system-chas-content-container">
          <div className="cpo-system-chas-content-item-list-container">
            <div className="cpo-system-chas-content-item-column">
              <div>CHAS명</div>
              <div>버전</div>
              <div>OCPP</div>
              <div>상세정보</div>
            </div>

            {
              systemChasList&&systemChasList.map((it, idx)=>{
                return(
                  <>
                    <div className='cpo-system-chas-content-item'>
                      <div><p>{it.name}</p></div>
                      <div><p>{it.version}</p></div>
                      <div><p>{it.ocpp}</p></div>
                      <div><p className='cpo-system-chas-detail-button'>자세히보기</p></div>
                    </div>
                  </>
                );
              })
            }
            
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoSystemChas;