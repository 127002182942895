import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import {setNum} from '../../store.js'
import { useNavigate, useLocation } from "react-router-dom";

import { FaRegTrashAlt, FaChevronLeft } from "react-icons/fa";
import { FiEdit3 } from "react-icons/fi";
import { getChas, getActions, putActionOfMessageList, getActionOfMessageList } from '../../api/controlhub/controlhubApi.js';
import { FaCheck, FaTimes } from "react-icons/fa";

import { Loading } from '../../components/alerts/Loading.js';
import CustomChasActionModal from '../../components/modals/CustomChasActionModal.js';
import CustomChasActionDataTransferModal from '../../components/modals/CustomChasActionDataTransferModal.js';
import DataTransferFunctionModal from '../../components/modals/DataTransferFunctionModal.js';
const CpoCustomChasEdit = () => {
  let navigator = useNavigate();

  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();
  const location = useLocation();
  // 불러오기인 경우 액션셋 데이터
  const data = location.state?.data;
  // new : 새로만들기, dup: 복제, edit: 수정
  const editMode = location.state?.mode;
  // OCPP 1.6
  const chasOcpp = location.state?.ocpp;
  
  const actionSetId = location.state.actionSetId;
  useEffect(()=>{
    dispatch(setNum({id:12, idx:2, detail:true, postFix:" 편집"}));
  },[])

  const messageTypeList=[
    {name:"AUTHORIZE_REQ", desc:"인증 요청 메세지"},
    {name:"START_TRANSACTION_REQ", desc:"시작 메세지"},
    {name:"STOP_TRANSACTION_REQ", desc:"종료 메세지"},
    {name:"REMOTE_START_TRANSACTION_REQ", desc:"원격 시작 메세지"},
    {name:"REMOTE_STOP_TRANSACTION_REQ", desc:"원격 종료 메세지"},
  ]

  const [chasInfo, setChasInfo] = useState(null);
  const [actions, setActions] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);

  const [actionListByMessage, setActionListByMessage] = useState(null);

  const reqGetChas = async()=>{
    if(!actionSetId)return;
    let result = await getChas(actionSetId);
    if(result.status === 200){
      setChasInfo(result.data);
    }
  }
  const reqGetActions = async()=>{
    if(!actionSetId)return;
    let result = await getActions();
    if(result.status === 200){
      setActions(result.data);
    }
  }
  const reqGetActionListByMessage = async()=>{
    if(!actionSetId)return;
    let tempList=[];
    for await(const it of messageTypeList){
      let result = await getActionOfMessageList(actionSetId, it.name);
      if(result.status === 200){
        let tempData = {name: it.name, actionList: result.data};
        tempList.push(tempData);
      }
    }
    setActionListByMessage(tempList);
  }


  useEffect(()=>{
    reqGetChas();
  },[])
  useEffect(()=>{
    reqGetActions();
  },[])
  useEffect(()=>{
    reqGetActionListByMessage();
    setSelectedMessage("AUTHORIZE_REQ")
  },[])
  useEffect(()=>{
    if(!chasInfo)return;
    getDataTransfer();
  },[chasInfo])
  

  const [dataTransferMsgNameList, setDataTransferMsgNameList] = useState([])
  const [dataTransferMsgList, setDataTransferMsgList] = useState([])
  const [selectedDataTransferMsg, setSelectedDataTransferMsg] = useState(null);
  const getDataTransfer = async()=>{
    let temp = [];
    let temp2 = [];
    for await(const it of chasInfo?.messageControlHubActions){
      if(it.messageType == 'DATA_TRANSFER_REQ'){
        let msg = it.messageType + "_" + it.dataTransferMessageId;
        temp.push(msg);
        temp2.push(it)
      }
    }
    setDataTransferMsgNameList(temp);
    setDataTransferMsgList(temp2);
    if(temp.length !== 0 && selectedDataTransferMsg === null)setSelectedDataTransferMsg(temp[0]);
  }

  
  useEffect(()=>{
    if(chasInfo===null)return;
    // reqGetChas();
  },[chasInfo])

  const [actionModalon, setActionModalOn] = useState(false);
  const [tabIdx, setTabIdx] = useState(0)
  // 클릭 이벤트 함수
  const tabClick = (idx)=>{
    if(tabIdx !== idx){
      setTabIdx(idx);
    }
  }

  const [dataTransferFunctionModalOn, setDataTransferFunctionModalOn] = useState(false);
  const [dataTransferFunctionMode, setDataTransferFunctionMode] = useState(null);
  
  return (
    <div className='cpo-custom-chas-edit-container'>
      {actionModalon && tabIdx ===0 && <CustomChasActionModal setOnOff={setActionModalOn} reqGetActionListByMessage={reqGetActionListByMessage} actionSetId={actionSetId} actions={actions} message={selectedMessage} description={messageTypeList.filter(it=>it.name===selectedMessage)[0].desc} actionList={actionListByMessage?.filter(item=> item.name === selectedMessage)[0].actionList}/>}
      {actionModalon && tabIdx ===1 && <CustomChasActionDataTransferModal setOnOff={setActionModalOn} reqGetChas={reqGetChas} actionSetId={actionSetId} actions={actions} message={selectedDataTransferMsg} description={""} actionList={dataTransferMsgList?.filter(item=> "DATA_TRANSFER_REQ_"+item.dataTransferMessageId === selectedDataTransferMsg)}/>}
      {dataTransferFunctionModalOn && tabIdx ===1 && <DataTransferFunctionModal setOnOff={setDataTransferFunctionModalOn} mode={dataTransferFunctionMode} reqGetChas={reqGetChas} dataTransferMsgNameList={dataTransferMsgNameList} actionSetId={actionSetId}/>}
      <div className='cpo-custom-chas-edit-inner'>
        <div className='cpo-custom-chas-edit-back-button' onClick={()=>{navigator(-1)}}>
          <FaChevronLeft/>
          뒤로가기
        </div>
        <div className="cpo-custom-chas-edit-header">
          <div className='cpo-chas-title-container'>
            <p>{chasInfo?.name}</p>
            <div className='cpo-chas-title-function-container'>
              <div>
                <div className="cpo-chas-title-function-button" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); }}>
                  <div><FiEdit3/></div>
                </div>
              </div>

              <div>
                <div className="cpo-chas-title-function-button delete" onClick={(e)=>{e.stopPropagation(); e.preventDefault();
                  // setDeleteModalData({action:chasSelect}); setDeleteModalContent("actionset"); setDeleteModalOn(true)
                }}>
                  <div><FaRegTrashAlt/></div>
                </div>
              </div>
            </div>
          </div>
          <div className='cpo-custom-chas-edit-save-button'></div>
        </div>
        <div className='cpo-custom-chas-tab-container'>
          <div className={tabIdx == 0 ? "custom-chas-tab-button selected":"custom-chas-tab-button"} onClick={()=>{tabClick(0);}}><p>일반 메세지</p></div>
          <div className={tabIdx == 1 ? "custom-chas-tab-button selected":"custom-chas-tab-button"} onClick={()=>{tabClick(1); console.log(dataTransferMsgNameList, dataTransferMsgList)}}><p>DataTransfer 메세지</p></div>
          <div className={'custom-chas-tab-bar '+'tab'+tabIdx}></div>
        </div>

        <div className="cpo-custom-chas-edit-content-container">
          <div className='cpo-custom-chas-edit-left-content'>
            <div className='cpo-custom-chas-edit-content-header'>메세지</div>
            {tabIdx===1&&
              <div className='cpo-custom-chas-edit-message-append-button-container'>
                <div className='cpo-custom-chas-edit-message-append-button' onClick={()=>{setDataTransferFunctionModalOn(true); setDataTransferFunctionMode("append");}}>DataTransfer 메세지 추가</div>
              </div>
            }
            <div className="cpo-custom-chas-edit-content-card-list">
            {
              tabIdx === 0 &&
              messageTypeList.map((it, idx)=>{
                return(
                  <div className={`cpo-custom-chas-edit-message-card-container ${selectedMessage === it.name ? "selected":""}`} onClick={()=>{setSelectedMessage(it.name);}}>
                    <div className={`cpo-custom-chas-edit-message-card-status ${actionListByMessage?.filter(item=> item.name === it.name)[0].actionList.length === 0 ? "invalid":"valid"}`}>
                      <div>
                        {
                          actionListByMessage?.filter(item=> item.name === it.name)[0].actionList.length === 0 ?
                          <FaTimes/>
                          :
                          <FaCheck/>
                        }
                      </div>
                    </div>
                    <div className='cpo-custom-chas-edit-message-card-content'>
                      <div className='cpo-custom-chas-edit-message-card-title'>{it.name}</div>
                      <div className='cpo-custom-chas-edit-message-card-description'>{it.desc}</div>
                    </div>
                    
                  </div>
                )
              })
            }
            {
              tabIdx === 1 &&
              dataTransferMsgNameList.map((it, idx)=>{
                return(
                  <div className={`cpo-custom-chas-edit-message-card-container ${selectedDataTransferMsg === it ? "selected":""}`} onClick={()=>{setSelectedDataTransferMsg(it);}}>
                    <div className={`cpo-custom-chas-edit-message-card-status ${dataTransferMsgList?.filter(item=> "DATA_TRANSFER_REQ_"+item.dataTransferMessageId === it)[0]?.chControlHubAction ? "valid":"invalid"}`}>
                      <div>
                        {
                          dataTransferMsgList?.filter(item=> "DATA_TRANSFER_REQ_"+item.dataTransferMessageId === it)[0]?.chControlHubAction ?
                          <FaCheck/>
                          :
                          <FaTimes/>
                        }
                      </div>
                    </div>
                    <div className='cpo-custom-chas-edit-message-card-content'>
                      <div className='cpo-custom-chas-edit-message-card-title'>{it}</div>
                      <div className='cpo-custom-chas-edit-message-card-description'>{dataTransferMsgList?.filter(item=> "DATA_TRANSFER_REQ_"+item.dataTransferMessageId === it)[0]?.description}</div>
                    </div>
                    
                  </div>
                )
              })
            }

            </div>
          </div>
          <div className='cpo-custom-chas-edit-right-content'>
            <div className='cpo-custom-chas-edit-content-header'>액션</div>
            {
            tabIdx === 1 && 
              <div className='cpo-custom-chas-edit-content-payload-container'>
                <div className="cpo-custom-chas-edit-content-payload">
                  PAYLOAD
                </div>
              </div>
            }
            <div className='cpo-custom-chas-edit-content-action-function'>
              <div className='cpo-custom-chas-edit-content-action-index-container'>
                <div>순서</div>
                <div>액션</div>
              </div>
              <div className='cpo-custom0chas-edit-content-modal-button' onClick={()=>{setActionModalOn(true)}}>액션편집</div>
            </div>
            <div className="cpo-custom-chas-edit-content-card-list">
            {
            tabIdx === 0 &&actionListByMessage && selectedMessage && actionListByMessage?.filter(item=> item.name === selectedMessage)[0].actionList?.map((it, idx)=>{
              return(
                <div className='cpo-custom-chas-edit-action-card-container'>
                  <div className='cpo-custom-chas-edit-action-order'>
                    <div>
                      {it?.order + 1}
                    </div>
                    {actionListByMessage?.filter(item=> item.name === selectedMessage)[0].actionList.length !== it.order+1 &&<div className='cpo-custom-chas-edit-action-flow-bar'></div>}
                  </div>
                  <div className='cpo-custom-chas-edit-action-card'>
                    <div className='cpo-custom-chas-edit-action-card-title'>{it?.chControlHubAction?.name}</div>
                    <div className='cpo-custom-chas-edit-action-card-description'>{it?.chControlHubAction?.description}</div>
                  </div>
                </div>
              )
            })
            }
            {
              tabIdx === 1 && dataTransferMsgList && selectedDataTransferMsg && dataTransferMsgList?.filter(item=> "DATA_TRANSFER_REQ_"+item.dataTransferMessageId === selectedDataTransferMsg)?.map((it, idx)=>{
                return(
                  <div className='cpo-custom-chas-edit-action-card-container'>
                    <div className='cpo-custom-chas-edit-action-order'>
                      <div>
                        1
                      </div>
                    </div>
                    
                    <div className='cpo-custom-chas-edit-action-card'>
                      <div className='cpo-custom-chas-edit-action-card-title'>{it?.chControlHubAction?.name}</div>
                      <div className='cpo-custom-chas-edit-action-card-description'>{it?.chControlHubAction?.description}</div>
                    </div>
                  </div>
                )
              })
            
            }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoCustomChasEdit;