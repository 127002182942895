import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'

import ChargerItem from "../../components/list/ChargerItem.js";
import ChargerDetailModal from "../../components/modals/ChargerDetailModal.js";
import ChargerEnrollModal from '../../components/modals/ChargerEnrollModal.js';
import ChargerDeleteModal from '../../components/modals/ChargerDeleteModal.js';
import SearchSelectorNotCreatable from '../../components/buttons/SearchSelectorNotCreatable.js'

import { getCookie } from '../../util/util.js';

import { getStationList } from '../../api/infra/infraApi.js';
import { exceptDeletedStation } from '../../util/util.js';

const CpoChargerManagement = () => {
  // redux
  let dispatch = useDispatch();
  let signedToken = getCookie("signedToken");


  // 진입 시 redux에 page 적용
  useEffect(()=>{
    dispatch(setNum({id:3}));
  },[dispatch])


  // 충전소 데이터 Array<Object>
  const [stationInfo, setStationInfo]=useState(null);
  // 충전기 데이터 Array<Object>
  const [chargerInfo, setChargerInfo]=useState(null);
  // 충전기 controlhub 데이터 Array<Object>
  const [chargerHubInfo, setChargerHubInfo]=useState(null);
  // 충전기 selector label 리스트
  const [labelList, setLabelList] = useState();


  useEffect(()=>{
    reqGetStationList();
  },[])

  // cpo가 소유한 station GET
  const reqGetStationList = async(id)=>{  
    let result = await getStationList();
    if(result.status === 200){
      let temp = exceptDeletedStation(result);
      setStationInfo(temp);
    }
    setCheckChargerList([]);
  }

// chargerHub 정보 API GET
const getChargerHubList = async()=>{
  let temp = [];
  for await(const it of stationInfo){
    await axios.get(`/controlhub/api/cpouser/v1/Chargers?chargingStationId=${it.ChargingStationId}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      temp.push(res.data);
    })
  }
  setChargerHubInfo(temp);
}

// charger 정보 API GET
const getChargerList = async()=>{
  let temp = [];
  for await(const it of stationInfo){
    await axios.get(`/infra/api/cpouser/v1/Chargers?ChargingStationId=${it.ChargingStationId}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      temp.push(res.data);
    })
  }
  console.log(temp);
  setChargerInfo(temp);
}

// label 리스트 get
const getLabelList = ()=>{
  let temp = [];
  stationInfo.map((it, idx)=>{
    let val = it.ChargingStationName;
    temp.push({value: val, label: val, idx:idx});
  })
  setLabelList(temp);
}
// station 데이터 받은 후 chargerHub, charger 정보 GET 트리거
useEffect(() => {
  if(!stationInfo)return;
  getChargerHubList();
  getChargerList();
  getLabelList();
}, [stationInfo])

const [chargerTypeStatus, setChargerTypeStatus] = useState({FAST_CHARGER:0, SLOW_CHARGER:0});
const [chargerCategoryStatus, setChargerCategoryStatus] = useState({'완전공용':0, '부분공용':0, '비공용':0});

useEffect(()=>{
  if(!chargerInfo)return;
  let chargerTypeTemp = {FAST_CHARGER:0, SLOW_CHARGER:0, NONE:0}; //TODO : 중속??????
  let ChargerCategoryTemp = {'완전공용':0, '부분공용':0, '비공용':0, '미설정':0};
  for (const chargerList of chargerInfo){
    for (const charger of chargerList.Results){
      if(charger.IsDeleted)continue;
      if(charger.ChargerModel?.ChargerType){
        chargerTypeTemp[charger?.ChargerModel.ChargerType] = chargerTypeTemp[charger.ChargerModel.ChargerType]+1;
      }
      else{
        chargerTypeTemp["NONE"]=chargerTypeTemp.NONE+1;
      }

      if(charger.CommonCategory){
        ChargerCategoryTemp[charger.CommonCategory] = ChargerCategoryTemp[charger.CommonCategory]+1;
      }
      else{
        ChargerCategoryTemp['미설정']=ChargerCategoryTemp['미설정']+1;
      }
    }
  }
  setChargerTypeStatus(chargerTypeTemp);
  setChargerCategoryStatus(ChargerCategoryTemp);
},[chargerInfo])


  // station list Ref[]
  const chargerRef = useRef([]);
  

  // 클릭한 charger list item의 idx state
  const [clickedCharger, setClickedCharger] = useState(null);

  // 상세 페이지 Modal
  const [detailModalOpen, setDetailModalOpen] = useState(false);

  // 클릭 시 상세 모달창 open
  useEffect(()=>{
    if(clickedCharger == -1 || clickedCharger == null || clickedCharger == undefined ){
      setDetailModalOpen(false);
    }
    else{
      setDetailModalOpen(true);
    }
  },[clickedCharger])



  // selector에서 클릭한 label (Station)
  const [dataLabel, setDataLabel] = useState();

  // 클릭한 station idx
  const [selected, setSelected] = useState(0);
  useEffect(()=>{
    if(labelList){
      let station = localStorage.getItem('selectedStationCM');
      let stationLabel = labelList.filter(it=>it.value === station)[0]
      if(station && stationLabel){
        setDataLabel(stationLabel)
      }
      else setDataLabel(labelList[0])
    }
  },[labelList])
  useEffect(()=>{
    if(dataLabel){
      setSelected(dataLabel.idx)
    }
  },[dataLabel])
  useEffect(()=>{
    if(clickedCharger !== null) setClickedCharger(-1);
    setCheckChargerList([]);
    setChecked(false)
  },[selected])

  useEffect(()=>{
    if(!dataLabel)return;
    localStorage.setItem("selectedStationCM", dataLabel.value);
  },[dataLabel])
  

  // check한 charger list
  const [checkChargerList, setCheckChargerList] = useState([]);

  // checked state (모든 아이템 check)
  const [checked, setChecked] = useState(false);

  useEffect(()=>{
    if(checked){
      let temp = new Array(chargerInfo[selected].Results.length).fill(0).map((_, i) => i)
      setCheckChargerList(temp);
    }
    else{
      setCheckChargerList([]);
    }
  },[checked])

  const [enrollModalOn, setEnrollModalOn] = useState(false);
  const [deleteModalOn, setDeleteModalOn] = useState(false);






  return (
    <div className='cpo-charger-management-container'>
      {enrollModalOn&&<ChargerEnrollModal setOnOff={setEnrollModalOn} stationInfo={stationInfo[selected]} chargerCount={chargerInfo?.length} getStationList={reqGetStationList}/>}
      {deleteModalOn&&<ChargerDeleteModal setOnOff={setDeleteModalOn} checkChargerList={checkChargerList} chargerList={chargerInfo[selected].Results} getStationList={reqGetStationList}/>}
      <div className="cpo-charger-management-inner">
        {chargerInfo!==null&&chargerHubInfo!==null&&chargerInfo.length!==0 ? <ChargerDetailModal getChargerHubList={getChargerHubList} getChargerList={getChargerList} data={chargerInfo[selected]?.Results[clickedCharger]} chasData={chargerHubInfo[selected][clickedCharger]} detailModalOpen={detailModalOpen} setDetailModalOpen={setDetailModalOpen} clickedCharger={clickedCharger} setClickedCharger={setClickedCharger} lastIdx={chargerInfo[selected].Total}/>:<></>}
        
        <div className="cpo-charger-management-category-outer">
          <div className='cpo-charger-management-category-container'>
            <p>충전기 타입</p>
            <div className="cpo-charger-management-category-type-container">
              <div className="cpo-charger-management-category-type">
                <div className="cpo-charger-management-category-type-title">
                  <p>고속</p>
                </div>
                <div className="cpo-charger-management-category-type-content">
                  <p>{chargerTypeStatus.FAST_CHARGER}</p>
                </div>
              </div>
              <div className="cpo-charger-management-category-type">
                <div className="cpo-charger-management-category-type-title">
                  <p>중속</p>
                </div>
                <div className="cpo-charger-management-category-type-content">
                  <p>0</p>
                </div>
              </div>
              <div className="cpo-charger-management-category-type">
                <div className="cpo-charger-management-category-type-title">
                  <p>완속</p>
                </div>
                <div className="cpo-charger-management-category-type-content">
                  <p>{chargerTypeStatus.SLOW_CHARGER}</p>
                </div>
              </div>
              <div className="cpo-charger-management-category-type">
                <div className="cpo-charger-management-category-type-title">
                  <p>미설정</p>
                </div>
                <div className="cpo-charger-management-category-type-content">
                  <p>{chargerTypeStatus.NONE}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='cpo-charger-management-category-container'>
            <p>충전기 분류</p>
            <div className="cpo-charger-management-category-class-container">

              <div className="cpo-charger-management-category-class">
                <div className="cpo-charger-management-category-class-title">완전공용</div>
                <p>{chargerCategoryStatus.완전공용}</p>
              </div>

              <div className="cpo-charger-management-category-class">
                <div className="cpo-charger-management-category-class-title">부분공용</div>
                <p>{chargerCategoryStatus.부분공용}</p>
              </div>
              <div className="cpo-charger-management-category-class">
                <div className="cpo-charger-management-category-class-title">비공용</div>
                <p>{chargerCategoryStatus.비공용}</p>
              </div>
              <div className="cpo-charger-management-category-class">
                <div className="cpo-charger-management-category-class-title">미설정</div>
                <p>{chargerCategoryStatus.미설정}</p>
              </div>
            </div>
          </div>

          <div className={detailModalOpen ? 'cpo-charger-management-charger-list-container open':'cpo-charger-management-charger-list-container' }>
            <div className='cpo-charger-management-charger-list-header'>
              <div className="cpo-charger-management-station-selector-container">
                <SearchSelectorNotCreatable placeholder="충전소를 선택하세요" options={labelList} value={dataLabel} setValue={setDataLabel} />
              </div>
              <div className={"cpo-charger-management-charger-list-button-container " + `${detailModalOpen? "open":""}`}>
                <div className={'delete '+ `${checkChargerList.length == 0 ? "none":""}`} onClick={()=>{setDeleteModalOn(true);}}><p>삭제</p></div>
                <div onClick={()=>{setEnrollModalOn(true)}}><p>등록</p></div>
              </div>
            </div>

            <div className="cpo-charger-management-charger-list">
              <div className="cpo-charger-management-charger-list-column">
                <div className='cpo-charger-management-charger-list-column-item'>
                  <label className="checkbox_label">
                    <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
                    <span className="checkbox_icon"></span>
                  </label>
                </div>
                <div className='cpo-charger-management-charger-list-column-item'><p>식별번호</p></div>
                <div className='cpo-charger-management-charger-list-column-item'><p>모델명</p></div>
                <div className='cpo-charger-management-charger-list-column-item'><p>충전기타입</p></div>
                <div className='cpo-charger-management-charger-list-column-item'><p>커넥터타입</p></div>
                <div className='cpo-charger-management-charger-list-column-item'><p>운영여부</p></div>
                <div className='cpo-charger-management-charger-list-column-item'><p>OCPP</p></div>
              </div>
              <div className='cpo-charger-management-list-content'>
                {
        //{"DisplaySerialNumber": "MOTP80985","modelName": "ModelB","ChargerType": "중속","ConnectorTypes": "차데모","status": false,"Protocol": "2.0.1"},
                chargerInfo&&chargerInfo.length !== 0 &&
                  (chargerInfo[selected].Results.map((ia,idx)=>{
                    if(ia.IsDeleted)return;
                    return(
                      <ChargerItem key={'charger- '+idx}  idx={idx} chargerRef={chargerRef} clickedCharger={clickedCharger} setClickedCharger={setClickedCharger} checkChargerList={checkChargerList} setCheckChargerList={setCheckChargerList} data={ia}/>
                  )
                  }))
                }
              </div>
              
            </div>
          </div>
        </div>




      </div>
    </div>
  )
}
export default CpoChargerManagement;