import { useState, useRef, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'
import Toggle from '../buttons/Toggle.js';
import Selector from '../buttons/Selector.js';
import Selector2 from '../buttons/Selector2.js';
import { FaBolt } from "react-icons/fa6";

const StationDetailTab3 = (props) => {

  // 선택자 클릭 여부
  const [ChargerInstallationCompanyID, setChargerInstallationCompanyID] = useState("");
  const [ChargerInstallationCompanyIDVal, setChargerInstallationCompanyIDVal] = useState("");
  const [ChargerInstallationCompanyIDActive, setChargerInstallationCompanyIDActive] = useState(null);

  const [ElectricalConstructionCompanyID, setElectricalConstructionCompanyID] = useState("");
  const [ElectricalConstructionCompanyIDVal, setElectricalConstructionCompanyIDVal] = useState("");
  const [ElectricalConstructionCompanyIDActive, setElectricalConstructionCompanyIDActive] = useState(null);

  const [WinchManufacturerID, setWinchManufacturerID] = useState("");
  const [WinchManufacturerIDVal, setWinchManufacturerIDVal] = useState("");
  const [WinchManufacturerIDActive, setWinchManufacturerIDActive] = useState(null);

  const [FaucetMethod, setFaucetMethod] = useState("");
  const [FaucetMethodVal, setFaucetMethodVal] = useState("");
  const [FaucetMethodActive, setFaucetMethodActive] = useState(null);

  const [VoltageType, setVoltageType] = useState("");
  const [VoltageTypeVal, setVoltageTypeVal] = useState("");
  const [VoltageTypeActive, setVoltageTypeActive] = useState(null);

  const[ContractPower, setContractPower]=useState("");
  
  const activeReset = ()=>{
    setChargerInstallationCompanyIDActive(false);
    setElectricalConstructionCompanyIDActive(false);
    setWinchManufacturerIDActive(false);
    setFaucetMethodActive(false);        
    setVoltageTypeActive(false);        
  }


  useEffect(()=>{
    console.log(
      props.ChargerInstallationCompanyIDList,
      props.ElectricalConstructionCompanyIDList,
      props.WinchManufacturerIDList,
    )
  },[])
  const FaucetMethodList = [
    {id:'-',name:''},
    {id:'a',name:'지중인입'},
    {id:'b',name:'가공인입'},
  ];
  const VoltageTypeList = [
    {id:'-',name:''},
    {id:"a",name:"고압"},
    {id:"b",name:"저압"},
  ];

  useEffect(()=>{
    if(!props.open)return;
    let temp = localStorage.getItem(`${props.name}`+'_StationDetailTab3');
    if(temp){
      temp = JSON.parse(temp);
      setChargerInstallationCompanyID(JSON.parse(temp['ChargerInstallationCompanyID']));
      setChargerInstallationCompanyIDVal(props.ChargerInstallationCompanyIDList.filter(it=>it.Id == JSON.parse(temp['ChargerInstallationCompanyID']))[0]);
      setElectricalConstructionCompanyID(JSON.parse(temp['ElectricalConstructionCompanyID']));
      setElectricalConstructionCompanyIDVal(props.ElectricalConstructionCompanyIDList.filter(it=>it.Id == JSON.parse(temp['ElectricalConstructionCompanyID']))[0]);
      setWinchManufacturerID(JSON.parse(temp['WinchManufacturerID']));
      setWinchManufacturerIDVal(props.WinchManufacturerIDList.filter(it=>it.Id == JSON.parse(temp['WinchManufacturerID']))[0]);
      setFaucetMethod(JSON.parse(temp['FaucetMethod']));
      setFaucetMethodVal(FaucetMethodList.filter(it=>it.name == JSON.parse(temp['FaucetMethod']))[0]);
      setVoltageType(JSON.parse(temp['VoltageType']));
      setVoltageTypeVal(VoltageTypeList.filter(it=>it.name == JSON.parse(temp['VoltageType']))[0]);
      setContractPower(JSON.parse(temp['ContractPower']));
    }
  },[])
  useEffect(()=>{
    if(!props.open)return;
    let temp = localStorage.getItem(`${props.name}`+'_StationDetailTab3');
    if(!temp){
      setChargerInstallationCompanyID(props.data.ChargerInstallationCompanyID);
      setChargerInstallationCompanyIDVal(props.ChargerInstallationCompanyIDList.filter(it=>it.Name == props.data.ChargerInstallationCompanyID)[0]);
      setElectricalConstructionCompanyID(props.data.ElectricalConstructionCompanyID);
      setElectricalConstructionCompanyIDVal(props.ElectricalConstructionCompanyIDList.filter(it=>it.Name == props.data.ElectricalConstructionCompanyID)[0]);
      setWinchManufacturerID(props.data.WinchManufacturerID);
      setWinchManufacturerIDVal(props.WinchManufacturerIDList.filter(it=>it.Name == props.data.WinchManufacturerID)[0]);
      setFaucetMethod(props.data.FaucetMethod);
      setFaucetMethodVal(FaucetMethodList.filter(it=>it.name == props.data.FaucetMethod)[0]);
      setVoltageType(props.data.VoltageType);
      setVoltageTypeVal(VoltageTypeList.filter(it=>it.name == props.data.VoltageType)[0]);
      setContractPower(props.data.ContractPower);
    }
  },[])
  useEffect(()=>{
    if(!ChargerInstallationCompanyIDVal)return;
    console.log(ChargerInstallationCompanyIDVal)
    setChargerInstallationCompanyID(ChargerInstallationCompanyIDVal.Id);
  },[ChargerInstallationCompanyIDVal])
  useEffect(()=>{
    if(!ElectricalConstructionCompanyIDVal)return;
    setElectricalConstructionCompanyID(ElectricalConstructionCompanyIDVal.Id);
  },[ElectricalConstructionCompanyIDVal])
  useEffect(()=>{
    if(!WinchManufacturerIDVal)return;
    setWinchManufacturerID(WinchManufacturerIDVal.Id);
  },[WinchManufacturerIDVal])
  useEffect(()=>{
    if(!FaucetMethodVal)return;
    setFaucetMethod(FaucetMethodVal.name);
  },[FaucetMethodVal])
  useEffect(()=>{
    if(!VoltageTypeVal)return;
    setVoltageType(VoltageTypeVal.name);
  },[VoltageTypeVal])

  useEffect(()=>{
    if(!props.open)return;
    if(ChargerInstallationCompanyID == ''&& ElectricalConstructionCompanyID =='' && WinchManufacturerID=='' && FaucetMethod=='' && VoltageType=='' && ContractPower=='')return;
    if(ChargerInstallationCompanyID == props.data.ChargerInstallationCompanyID && ElectricalConstructionCompanyID ==props.data.ElectricalConstructionCompanyID && WinchManufacturerID==props.data.WinchManufacturerID && FaucetMethod==props.data.FaucetMethod && VoltageType==props.data.VoltageType && ContractPower==props.data.ContractPower)return;
    localStorage.setItem(`${props.name}`+'_StationDetailTab3', JSON.stringify( {ChargerInstallationCompanyID:JSON.stringify(ChargerInstallationCompanyID?ChargerInstallationCompanyID:""), ElectricalConstructionCompanyID:JSON.stringify(ElectricalConstructionCompanyID?ElectricalConstructionCompanyID:""), WinchManufacturerID:JSON.stringify(WinchManufacturerID?WinchManufacturerID:""), FaucetMethod:JSON.stringify(FaucetMethod?FaucetMethod:""), VoltageType:JSON.stringify(VoltageType?VoltageType:""), ContractPower:JSON.stringify(ContractPower?ContractPower:"")}))
    props.setSaveBtnStatus(true);
  },[ChargerInstallationCompanyID, ElectricalConstructionCompanyID, WinchManufacturerID, FaucetMethod, VoltageType, ContractPower])


  const tempList =[
    {id:'-',name:''},
    {id:'a',name:'기능 업데이트 예정입니다.'},
    // {id:'b',name:'김길동'},
    // {id:'c',name:'이길동'},
  ];

  const [tempSelect1, setTempSelect1]=useState();
  const [tempSelect2, setTempSelect2]=useState();
  const [tempSelect3, setTempSelect3]=useState();

  function numberInput(target) {
    target.value = target.value.replace(/[^0-9]/g, '');
  }

  return (
    <div className='station-detail-tab tab3'>
      <div>
        <div>
          <div className='station-detail-tab-selector-container'>
            <p className='station-detail-input-title'>충전기설치업체</p>
            {/* {
              props.ChargerInstallationCompanyIDList ?
              <Selector2 name={"Name"} append={true} dataList={props.ChargerInstallationCompanyIDList} select={ChargerInstallationCompanyIDVal} setSelect={setChargerInstallationCompanyIDVal} active={ChargerInstallationCompanyIDActive} setActive={setChargerInstallationCompanyIDActive} activeReset={activeReset}/>
              :<></>
            } */}
            <Selector append={false} dataList={tempList} select={tempSelect1} setSelect={setTempSelect1} active={ChargerInstallationCompanyIDActive} setActive={setChargerInstallationCompanyIDActive} activeReset={activeReset}/>
          </div>

          <div className='station-detail-tab-selector-container'>
            <p className='station-detail-input-title'>전력기설치업체</p>
            {/* {
              props.ElectricalConstructionCompanyIDList ?
              <Selector2 name={"Name"} append={true} dataList={props.ElectricalConstructionCompanyIDList} select={ElectricalConstructionCompanyIDVal} setSelect={setElectricalConstructionCompanyIDVal} active={ElectricalConstructionCompanyIDActive} setActive={setElectricalConstructionCompanyIDActive} activeReset={activeReset}/>
              :<></>
            } */}
            <Selector append={false} dataList={tempList} select={tempSelect2} setSelect={setTempSelect2} active={ElectricalConstructionCompanyIDActive} setActive={setElectricalConstructionCompanyIDActive} activeReset={activeReset}/>
          </div>
          <div className='station-detail-tab-selector-container'>
            <p className='station-detail-input-title'>윈치 제조사</p>
            {/* {
              props.WinchManufacturerIDList ?
              <Selector2 name={"Name"} append={true} dataList={props.WinchManufacturerIDList} select={WinchManufacturerIDVal} setSelect={setWinchManufacturerIDVal} active={WinchManufacturerIDActive} setActive={setWinchManufacturerIDActive} activeReset={activeReset}/>
              :<></>
            } */}
            <Selector append={false} dataList={tempList} select={tempSelect3} setSelect={setTempSelect3} active={WinchManufacturerIDActive} setActive={setWinchManufacturerIDActive} activeReset={activeReset}/>
          </div>
        </div>
        <div>
          <div className='station-detail-tab-selector-container'>
            <p className='station-detail-input-title'>수전 방식</p>
            <Selector dataList={FaucetMethodList} select={FaucetMethodVal} setSelect={setFaucetMethodVal} active={FaucetMethodActive} setActive={setFaucetMethodActive} activeReset={activeReset}/>
          </div>
          <div className='station-detail-tab-selector-container'>
            <p className='station-detail-input-title'>전압종류</p>
            <Selector dataList={VoltageTypeList} select={VoltageTypeVal} setSelect={setVoltageTypeVal} active={VoltageTypeActive} setActive={setVoltageTypeActive} activeReset={activeReset}/>
          </div>
          <div>
            <p className='station-detail-input-title'>계약전력 (kW)</p>
            <div className="station-detail-info-input-container mapTab">
              <input type="text" placeholder='계약전력' value={ContractPower} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{setContractPower(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/> 
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default StationDetailTab3