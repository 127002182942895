
import React, { useState } from 'react';
import { MdFileUpload } from "react-icons/md";
import { TiDelete } from "react-icons/ti";
import { useSelector, useDispatch } from "react-redux"
import { LuDownload } from "react-icons/lu";


const FileInfo = ({setUploadedInfo, uploadedInfo }) => (
  <div className="preview_info" onClick={(e)=>{e.preventDefault();e.stopPropagation();}}>
    <div className='file-info-container'>
      <div>
        <div><p className="info_value">{uploadedInfo.name}</p></div>
        <div><p className='info_value'>{uploadedInfo.size}</p></div>
      </div>
      <div>
        <TiDelete className='file-delete-icon' onClick={(e)=>{e.preventDefault();e.stopPropagation(); setUploadedInfo(null);}}/>
      </div>
    </div>
    {/* {Object.entries(uploadedInfo).map(([key, value]) => (
    ))} */}
  </div>
);
const blobToDataUrl = (blob) => {
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = () => resolve(fileReader.result);
    fileReader.onerror = () => resolve(null);

    fileReader.readAsDataURL(blob);
    console.log()
    console.log(fileReader);
    const element = document.createElement('a');
    element.href = URL.createObjectURL(blob);
    element.download = '';
    element.click();
  });
};

export const UploadBox = () => {
  // redux
  let page = useSelector((state) => state.page );
  let detail = useSelector((state)=>state.detail);
  let dispatch = useDispatch();
  const [isActive, setActive] = useState(false);
  const [uploadedInfo, setUploadedInfo] = useState(null);
  const [file, setFile] = useState(null);
  
  const handleDragStart = () => setActive(true);
  const handleDragEnd = () => setActive(false);
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const setFileInfo = (file) => {
    if(!file)return;
    console.log(file);
    setFile(file)
    // const { name, size: byteSize, type } = file;
    const { name, size: byteSize } = file;
    const size = (byteSize / (1024 * 1024)).toFixed(2) + 'MB';
    // setUploadedInfo({ name, size, type }); // name, size, type 정보를 uploadedInfo에 저장
    setUploadedInfo({ name, size }); // name, size, type 정보를 uploadedInfo에 저장
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setActive(false);

    const file = event.dataTransfer.files[0];
    setFileInfo(file);
  };

  const handleUpload = ({ target }) => {
    const file = target.files[0];
    setFileInfo(file);
  };

  return (
    <div className='file-upload-wrapper' onClick={(e)=>{e.stopPropagation();}}>
      <label
        className={`preview${isActive ? ' active' : ''}${uploadedInfo ? ' uploaded' : ''}`}
        onDragEnter={handleDragStart}
        onDragOver={handleDragOver}
        onDragLeave={handleDragEnd}
        onDrop={handleDrop}
      >
        <input type="file" className="file" onChange={handleUpload} />
      </label>
      {uploadedInfo &&<div className='file-uploaded-wrapper'>
        <div className='fileBtn' onClick={(e)=>{e.preventDefault(); e.stopPropagation(); blobToDataUrl(file)}}>
          <LuDownload/>
        </div>
        <FileInfo setUploadedInfo={setUploadedInfo} uploadedInfo={uploadedInfo} />
      </div>}
      {!uploadedInfo && (
        <div className='preview-container'>
          <MdFileUpload className='file-icon'/>
          <p className="preview_msg">클릭하거나 파일을 드롭하여 업로드</p>
        </div>
      )}
    </div>
  );
};
export default UploadBox;