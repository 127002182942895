import React, { useState, useEffect } from 'react';
import {occpConverter, chargerTypeConverter, connectorTypeConverter} from "../../util/util.js";

export const ChargerItem = (props) => {
  // checked 상태
  const [checked, setChecked] = useState(false);

  const clickEvent = ()=>{
    // 현재 클릭된 것이 해당 객체가 아닌 경우
    if(props.clickedCharger !== props.idx){
      props.setClickedCharger(props.idx)
    }
    else{
      props.setClickedCharger(-1)
    } 
  }

  useEffect(()=>{
    // 리스트에서 인덱스 찾기
    let itemIdx = props.checkChargerList.indexOf(props.idx);
    // check true인 경우
    if(checked){
      if(itemIdx !== -1){
        return;
      }
      props.setCheckChargerList([...props.checkChargerList, props.idx]);
    }
    else{
      // false 인 경우
      
      // 만약 없는 경우
      if(itemIdx == -1){
        return;
      }
      // 있는 경우
      else{
        let temp = [...props.checkChargerList];
        temp.splice(itemIdx,1);
        props.setCheckChargerList(temp);
      }
    }
  },[checked])
  

  useEffect(()=>{
    let itemIdx = props.checkChargerList.indexOf(props.idx);
    if(itemIdx !== -1 && !checked){
      setChecked(true);
    }
    else if(itemIdx == -1 && checked){
      setChecked(false);
    }
  },[props.checkChargerList])


  return (
    <div className={"cpo-charger-management-list-content-item " +`${props.clickedCharger == props.idx ? "clicked":""}`}
    ref={(el)=>{props.chargerRef.current[props.idx]=el}}
    onClick={()=>{clickEvent()}}
    >
      <div>
        <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
          <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
          <span className="checkbox_icon"></span>
        </label>
      </div>
      <div><p>{props.data.DisplaySerialNumber}</p></div>
      <div><p>{props.data.ChargerModel?.Name ? props.data.ChargerModel?.Name : "-"}</p></div>
      <div><p onDrag={(e)=>{e.stopPropagation();}}>{props.data.ChargerModel?.ChargerType ? chargerTypeConverter(props.data.ChargerModel?.ChargerType) : "-"}</p></div>
      <div>
        {
          props.data.ChargerModel&&connectorTypeConverter(props.data.ChargerModel?.ConnectorType)?.map((it)=>{
            return(
              <div className="cpo-charger-management-connector-badge">
                <p onDrag={(e)=>{e.stopPropagation();}}>{it}</p>
              </div>
            )
          })
        }
      </div>
      <div>
        <div className={'cpo-charger-management-status-item ' +`${props.data.status ? "":"none"}`}>
          <p>{props.data.status ? 'YES' : "NO"}</p>
        </div>
      </div>
      <div><p>{occpConverter(props.data.Protocol)}</p></div>
    </div>
  )
}
export default ChargerItem;