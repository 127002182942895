import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from "react-redux"
import {setNum} from '../../store.js'
import { numberInput } from '../../util/util';

import { getStationList, getChargerByStationId } from '../../api/infra/infraApi.js';
import { getChargerHub } from '../../api/controlhub/controlhubApi.js';

import { exceptDeletedStation } from '../../util/util';
import { MdInfoOutline } from "react-icons/md";

import SearchSelectorNotCreatable from '../../components/buttons/SearchSelectorNotCreatable.js'
import { timestampFormat, dateFormat2 } from '../../util/util';

import ChargingFeeSettingModal from '../../components/modals/ChargingFeeSettingModal.js';
import SettingDefaultChargingRateModal from '../../components/modals/SettingDefaultChargingRateModal.js';
import { Loading } from '../../components/alerts/Loading.js';

import { patchDefaultPricingPlan, putDefaultPricingPlan, getDefaultPricingPlan, getAllPricingEvent } from '../../api/chargerate/chargerateApi.js';

import { Tooltip } from 'react-tooltip'

const CpoChargingEventManagement = (props) => {
  // redux
  let dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setNum({id:7}));
  },[dispatch])

  // 충전소 데이터 Array<Object>
  const [stationInfo, setStationInfo]=useState(null);
  // 충전기 데이터 Array<Object>
  const [chargerInfo, setChargerInfo]=useState(null);
  // 충전기 controlhub 데이터 Array<Object>
  const [chargerHubInfo, setChargerHubInfo]=useState(null);

  useEffect(()=>{
    // getDefaultRate();
    // getChargerRateList();
    reqGetDefaultPricingPlan();
    reqGetStationList();
  },[])


  // cpo가 소유한 station GET
  const reqGetStationList = async()=>{  
    let result = await getStationList();
    if(result.status === 200){
      let temp = exceptDeletedStation(result);
      setStationInfo(temp);
    }
  }

  // chargerHub 정보 API GET
  const reqGetChargerHubList = async()=>{
    let result = [];
    for await(const it of stationInfo){
      let temp = await getChargerHub(it.ChargingStationId);
      result.push(temp.data);
    }
    setChargerHubInfo(result);
  }

  // charger 정보 API GET
  const reqGetChargerList = async()=>{
    let result = [];
    for await(const it of stationInfo){
      let temp = await getChargerByStationId(it.ChargingStationId);
      result.push(temp.data); 
    }
    setChargerInfo(result);
  }

  // station 데이터 받은 후 chargerHub, charger 정보 GET 트리거
  useEffect(() => {
    if(!stationInfo)return;
    reqGetChargerHubList();
    reqGetChargerList();
  }, [stationInfo])




  const [chargerRateList, setChargerRateList] = useState([]);


  const [membershipRate, setMembershipRate] = useState();
  const [nonMembershipRate, setNonMembershipRate] = useState();
  
  const [initMembershipRate, setInitMembershipRate] = useState();
  const [initNonMembershipRate, setInitNonMembershipRate] = useState();

  const [membershipRateUnitList, setMembershipRateUnitList] = useState();
  const [nonMembershipRateUnitList, setNonMembershipRateUnitList] = useState();

  const [modalOff, setModalOff] = useState(false);
  
  const [defaultModalOn, setDefaultModalOn] = useState(false);

  const [moreInfoOn, setMoreInfoOn] = useState(false);


  const [tabIdx, setTabIdx] = useState(0)
  // 클릭 이벤트 함수
  const tabClick = (idx)=>{
    if(tabIdx !== idx){
      setTabIdx(idx);
    }
  }

  const month = [1,2,3,4,5,6,7,8,9,10,11,12];
  const hour = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23];
  // 24x12 테이블을 구성
  const cols = 12;
  const rows = 24;

  // 각 셀을 관리할 ref 배열 생성 (배열로 초기화)
  const cellRefs = useRef(Array(rows * cols).fill(null));

  // 셀 클릭 시 호출되는 함수
  const handleCellClick = (row, col) => {
    console.log(cellRefs.current);
    console.log(`Row: ${row}, Column: ${col}`);
  };

  // 테이블 셀 렌더링
  const renderCells = () => {
    const cells = [];
    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < cols; col++) {
        const index = row * cols + col; // 각 셀에 대한 고유 인덱스
        const cellData = cellState[index];
        cells.push(
          <div
            key={`${row}-${col}`}
            ref={(el) => (cellRefs.current[index] = el)} // 각 셀의 ref를 배열에 할당
            className={`cell row-${row} col-${col}}`}
            onClick={() => handleCellClick(row, col)}
          >
            {/* {row},{col} */}
            {cellData?.unitPrice}
            {cellData?.hasTooltip && <Tooltip anchorSelect={`.cell.row-${row}.col-${col}`} />}
            
          </div>
        );
      }
    }
    return cells;
  };

  useEffect(()=>{
    if(tabIdx === 0 && membershipRateUnitList){
      console.log(membershipRateUnitList);
      fillCell();
    }
    else if(tabIdx === 1 && nonMembershipRateUnitList){
      console.log(nonMembershipRateUnitList)
      fillCell();
    }
  },[tabIdx, membershipRateUnitList, nonMembershipRateUnitList])
  

  const fillCell = async () => {
    let target;
    let pivot;

    if (tabIdx === 0) {
      target = membershipRateUnitList;
      pivot = initMembershipRate;
    } else {
      target = nonMembershipRateUnitList;
      pivot = initNonMembershipRate;
    }

    const newCellState = Array(rows * cols).fill(null); // 상태 초기화

    for (let item of target) {
      const index = parseInt((item.hour - 1) * 12 + (item.month - 1));
      newCellState[index] = {
        unitPrice: item.unitPrice,
        className: parseFloat(item.unitPrice) > parseFloat(pivot) ? "high" : "low",
        hasTooltip: parseFloat(item.unitPrice) !== parseFloat(pivot), // 조건에 따라 Toggle 추가
      };
    }

    setCellState(newCellState);
  };
  
  const [cellState, setCellState] = useState(Array(rows * cols).fill(null)); // 각 셀의 상태 저장

  
  // 기본요금 세팅
  const [loadingOn, setLoadingOn] = useState(false);

  const reqGetDefaultPricingPlan = async()=>{
    let result = await getDefaultPricingPlan();
    if(result.status === 200){
      setMembershipRate(result.data.memberPlan?.defaultUnitPrice);
      setNonMembershipRate(result.data.nonMemberPlan?.defaultUnitPrice);
      setInitMembershipRate(result.data.memberPlan?.defaultUnitPrice);
      setInitNonMembershipRate(result.data.nonMemberPlan?.defaultUnitPrice);
      setMembershipRateUnitList(result.data.memberPlan?.unitPrices);
      setNonMembershipRateUnitList(result.data.nonMemberPlan?.unitPrices);
    }
    else {
    }
    console.log(result);
  }

  const reqPatchDefaultPricingPlan = async()=>{
    setLoadingOn(true);
    let reqObj = {
      "pricingType": tabIdx===0?"Member":"NonMember",
      "currency": "KRW",
      "defaultUnitPrice": tabIdx===0?membershipRate:nonMembershipRate
    }
    console.log(reqObj);

    let result = await patchDefaultPricingPlan(reqObj);
    if(result.status === 200){
      setSaveResultMsg("저장되었습니다.");
      setTimeout(() => {
        setSaveResultMsg("");
      }, 3000);
    }
    else if(result.status === 400){
      if(result.data.ErrorCode ==="PLAN_NOT_EXIST"){
        await reqPutDefaultPricingPlan();
      }
    }
    reqGetDefaultPricingPlan();
    setLoadingOn(false);
  }
  const reqPutDefaultPricingPlan = async()=>{
    setLoadingOn(true);
    let reqObj = {
      "pricingType": tabIdx===0?"Member":"NonMember",
      "currency": "KRW",
      "defaultUnitPrice": tabIdx===0?membershipRate:nonMembershipRate,
      "unitPrices":[]
    }

    let result = await putDefaultPricingPlan(reqObj);
    if(result.status === 200){
      setSaveResultMsg("저장되었습니다.");
      setTimeout(() => {
        setSaveResultMsg("");
      }, 3000);
    }
    else if(result.status === 400){
      console.log(result);
      
    }
    reqGetDefaultPricingPlan();
    setLoadingOn(false);
  }

  const [saveResultMsg, setSaveResultMsg] = useState("");



  // 이벤트요금 관리
  useEffect(()=>{
    reqGetAllPricingEvent();
  },[])

  const [pricingEventList, setPricingEventList] = useState([]);
  useEffect(()=>{
    if(!pricingEventList)return;
    getLabelList();
  },[pricingEventList])

  const reqGetAllPricingEvent = async()=>{
    let result = await getAllPricingEvent();
    if(result.status === 200){ 
      console.log(result);
      setPricingEventList(result.data?.results);
    }
    else{
      console.log(result);
    }
  }

  // 충전기 selector label 리스트
  const [labelList, setLabelList] = useState();

  // label 리스트 get
  const getLabelList = ()=>{
    let temp = [];
    pricingEventList.map((it, idx)=>{
      let val = it.name;
      temp.push({value: val, label: val, idx:idx});
    })
    setLabelList(temp);
  }


  // selector에서 클릭한 label (Station)
  const [dataLabel, setDataLabel] = useState();

  
  const [selected, setSelected] = useState(0);
  useEffect(()=>{
    if(labelList){
      setDataLabel(labelList[0])
    }
  },[labelList])
  useEffect(()=>{
    if(dataLabel){
      setSelected(dataLabel.idx)
      console.log(dataLabel);
    }
  },[dataLabel]) 








  return (
    <div className="cpo-charging-fee-management-page-container">
      {modalOff &&
        <ChargingFeeSettingModal
        // getChargerRateList={getChargerRateList}
        reqGetAllPricingEvent={reqGetAllPricingEvent}
        chargerRateList={chargerRateList} stationInfo={stationInfo} chargerInfo={chargerInfo} chargerHubInfo={chargerHubInfo} setOnOff={setModalOff} 
      />}
      {defaultModalOn&&<SettingDefaultChargingRateModal setOnOff={setDefaultModalOn} pricingType={tabIdx===0 ? "Member":"NonMember"} reqGetDefaultPricingPlan={reqGetDefaultPricingPlan}/>}

      <div className="cpo-charging-fee-management-inner">
        <div className="cpo-charging-fee-management-container">
          <div>


            
          </div>
          <div>
            <div className='cpo-charging-fee-management-title-container'>
              <title>이벤트요금 관리</title>
            </div>
            <div className='cpo-charger-management-station-selector-wrapper'>
              <div className="cpo-charger-management-station-selector-container selector-container">
                <SearchSelectorNotCreatable placeholder="이벤트를 선택하세요" options={labelList} value={dataLabel} setValue={setDataLabel} />
              </div>
              <div className='cpo-charging-fee-management-event-setting-button' onClick={()=>{setModalOff(true)}}><p>이벤트요금 설정</p></div>
            </div>
            <div className='cpo-charging-fee-management-event-content-container'>
            {
              pricingEventList&&dataLabel&&pricingEventList[dataLabel?.idx].chargingStations.map((it, idx)=>{
                return(<>
                <div className='cpo-charging-fee-management-event-content-item'>
                  <div>
                    {it.chargingStationName}
                  </div>
                  <div>{dateFormat2(new Date(pricingEventList[dataLabel?.idx]?.startDateTime))}</div>
                  <div>{dateFormat2(new Date(pricingEventList[dataLabel?.idx]?.endDateTime))}</div>
                  <div>{"회원 : "+pricingEventList[dataLabel?.idx]?.memberUnitPrice}</div>
                  <div>{"비회원 : "+pricingEventList[dataLabel?.idx]?.nonMemberUnitPrice}</div>
                </div>
                </>)
              })
            }
            </div>
          </div>
        </div>
        
      </div>
    </div>
  )
}
export default CpoChargingEventManagement;