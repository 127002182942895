import React, {useState} from 'react'

import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";


import topBg from '../../img/test.png'
import cpoService_1 from '../../img/cpoService_1.svg'
import cpoService_2 from '../../img/cpoService_2.svg'
import cpoService_3 from '../../img/cpoService_3.svg'
import cpoService_4 from '../../img/cpoService_4.svg'
import cpoService_5 from '../../img/cpoService_5.png'
import cpoService_6 from '../../img/cpoService_6.png'
import cpoService_7 from '../../img/cpoService_7.png'
import cpoService_8 from '../../img/cpoService_8.png'
import cpoService_9 from '../../img/cpoService_9.png'
import cpoService_10 from '../../img/cpoService_10.png'
import cpoServiceBg from '../../img/cpoServiceBg.png'

import cpoMobileSlider_1 from '../../img/cpoServiceMobileSlider_1.png'
import cpoMobileSlider_2 from '../../img/cpoServiceMobileSlider_2.png'
import cpoMobileSlider_3 from '../../img/cpoServiceMobileSlider_3.png'
import cpoMobileSlider_4 from '../../img/cpoServiceMobileSlider_4.png'
import cpoMobileSlider_5 from '../../img/cpoServiceMobileSlider_5.png'
import cpoMobileSlider_6 from '../../img/cpoServiceMobileSlider_6.png'
import cpoMobileSlider_7 from '../../img/cpoServiceMobileSlider_7.png'
import cpoMobileSlider_8 from '../../img/cpoServiceMobileSlider_8.png'

import cpoServiceSlider_1 from '../../img/cpoServiceSlider_1.png'
import cpoServiceSlider_2 from '../../img/cpoServiceSlider_2.png'
import cpoServiceSlider_3 from '../../img/cpoServiceSlider_3.png'
import cpoService_diagram from '../../img/cpoService_diagram.png'

import LG_logo from '../../img/LG_logo.avif';
import SSGInC_logo from '../../img/SSGInC_logo.png';


export const CpoService = () => {

  const [sliderIdx, setSliderIdx] = useState(0);
  const [mobileSliderIdx, setMobileSliderIdx] = useState(0);
  
  const [sliderImageList, setSliderImageList] = useState([cpoServiceSlider_1, cpoServiceSlider_2, cpoServiceSlider_3]);
  const [mobileSliderImageList, setMobileSliderImageList] = useState([cpoMobileSlider_1, cpoMobileSlider_2, cpoMobileSlider_3, cpoMobileSlider_4, cpoMobileSlider_5, cpoMobileSlider_6, cpoMobileSlider_7, cpoMobileSlider_8]);
  let navigator = useNavigate();
  
  return (
    <div className='main-introduce-container'>
      {/* header */}
      <div className='main-introduce-header'>
        <div className="main-introduce-bg">
          <img src={topBg} alt="" />
        </div>
        <div className="main-introduce-title-container">
          <h1>공유형 CPO 서비스</h1>
          <p>Charger Station Management Subscriptions Service</p>
          <div className="main-introduce-subscription-button" onClick={()=>{navigator("/service-pricing");}}><p>구독 상품 보러 가기</p></div>
        </div>
      </div>
      {/* content */}
      <div className="main-introduce-content-section">

        <div className="main-introduce-text-title-block-container">
          <p>공유형 CPO  서비스</p>
          <h2>
            시스템 개발 없이<br/>더 빠르게 충전 사업자가 되는 방법
          </h2>

        </div>
        <div className="main-introduce-text-block-container">
          <h3>
            장치에서 소프트웨어까지 CPO사업을 위해<br/>
            필요한 모든 피처를 제공하는 <color>No.1 서비스 플랫폼</color>
          </h3>
        </div>

        <div className="main-introduce-image-block-grid">
          <ImageBlock data={{imgUrl:cpoService_1, content:<p>스마트한 <color>충전기</color></p>}} oneLine={true}/>
          <ImageBlock data={{imgUrl:cpoService_2, content:<p>충전기와 운영시스템 사이의<br/>느슨한 <color>인터페이스 모듈</color></p>}}/>
          <ImageBlock data={{imgUrl:cpoService_3, content:<p>EV사용자를 위한<br/><color>모바일 앱</color></p>}}/>
          <ImageBlock data={{imgUrl:cpoService_4, content:<p>변경에 유연한<br/><color>운영시스템</color></p>}}/>
        </div>

        <div className='main-introduce-spacer'></div>

        <div className="main-introduce-text-block-container">
          <h3>
            공유형 <color>CPO 서비스</color>
          </h3>
        </div>
        <div className="main-introduce-service-block-grid">
          <ServiceBlock data={{imgUrl:cpoService_5, title:"프론트 엔드", content:"CPO 사용자 웹 앱 (React App)"}}/>
          <ServiceBlock data={{imgUrl:cpoService_6, title:"충전소관제 서비스 백 엔드", content:"충전 인프라 운영 (컨트롤 허브), 고객 서비스 관리,\nOCCP 16 통신 미들 웨어, OCCP 20 통신 미들 웨어,\n공지 관리, 로밍 관리, 충전 인프라 관리    "}}/>
          <ServiceBlock data={{imgUrl:cpoService_7, title:"부가서비스 백 엔드", content:"포인트 관리, 광고 관리"}}/>
          <ServiceBlock data={{imgUrl:cpoService_8, title:"AI서비스 백 엔드", content:"충전소 입지 추천 서비스, 충전기 고장 예측 서비스"}}/>
          <ServiceBlock data={{imgUrl:cpoService_9, title:"코어서비스 백 엔드", content:"CPO 계정관리/EV사용자 계정 관리/인증,\n서비스 구독 관리, 지불관리 및 PG연동"}}/>
          <ServiceBlock data={{imgUrl:cpoService_10, title:"DBMS(MySQL)", content:""}}/>
        </div>
        
        <div className='main-introduce-bg-content-container'>
          <div className="main-introduce-bg">
            <img src={cpoServiceBg} alt="" />
          </div>
        <div className='main-introduce-spacer'></div>

          <div className="main-introduce-text-title-block-container">
            <p>공유형 CPO  서비스</p>
            <h2>
            운영에 필수적인 CSMS, EV 사용자앱<br/>소프트웨어를 부담없는 가격으로 구독하고<br/>바로 충전사업을 시작
            </h2>
          </div>
          
          <div className='main-introduce-image-slider-container'>
            <div className='main-introduce-image-slider-button left'><BsChevronCompactLeft onClick={()=>{setSliderIdx( sliderIdx-1==-1?sliderImageList.length-1:sliderIdx-1 )}}/></div>
            <div className='main-introduce-image-slider'>
              <img src={sliderImageList[sliderIdx]} alt="" />
            </div>
            <div className='main-introduce-image-slider-button right'><BsChevronCompactRight onClick={()=>{setSliderIdx( sliderIdx+1==sliderImageList.length?0:sliderIdx+1  )}}/></div>
            
            <div className='main-introduce-image-slider-indicator-container'>
              <div className={'main-introduce-image-slider-indicator ' + `${sliderIdx == 0 ? "selected":""}`} onClick={()=>{setSliderIdx(0)}}></div>
              <div className={'main-introduce-image-slider-indicator ' + `${sliderIdx == 1 ? "selected":""}`} onClick={()=>{setSliderIdx(1)}}></div>
              <div className={'main-introduce-image-slider-indicator ' + `${sliderIdx == 2 ? "selected":""}`} onClick={()=>{setSliderIdx(2)}}></div>
            </div>
          </div>

          <div className='main-introduce-three-text-block-container'>
            <div className="main-introduce-three-text-block-item">
              <h4>간편한 새로운 충전소/충전기 등록</h4>
              <pre>{"복잡한 과정없이 충전소와 충전기를\n간단히 등록하고 운영을 시작 할 수 있습니다"}</pre>
            </div>
            <div className="main-introduce-three-text-block-item">
              <h4>자유로운 OCPP 통신 규격 설정</h4>
              <pre>{"기본으로 제공되는 통신규격을 변경하고 싶다면\n자유롭게 설정하여 변경할 수 있습니다."}</pre>
            </div>
            <div className="main-introduce-three-text-block-item">
              <h4>충전기 대수 별 구독</h4>
              <pre>{"운영하는 충전기의 대수에 맞게 구독하여\n소규모 충전소 운영 비용을 절약할 수 있습니다."}</pre>
            </div>
          </div>
        </div>

        <div className='main-introduce-spacer'></div>

        <div className='main-introduce-flex-wrapper'>
          <div className='main-introduce-divide'>
            
          </div>
          <div className='main-introduce-application-info-container'>
            <div className='main-introduce-application-info-title'>
              <p>EV User Mobile App</p>
              <h2>EV 사용자 <color>모바일 앱</color></h2>
            </div>
            <div className='main-introduce-application-info-content'>
              <p>iOS/Android 모두 지원</p>
              <pre>{'애플과 안드로이드 운영체제 상관없이 동일한 모바일\n앱을 사용할 수 있습니다.'}</pre>
            </div>
            <div className='main-introduce-application-info-content'>
              <p>지속적인 앱 관리 지원</p>
              <pre>{'끊임없이 변경되는 애플과 안드로이드의 정책변경과\n플랫폼 버전업에 따른 변경관리에 대해 더는 신경쓰지\n않아도 됩니다.'}</pre>
            </div>
            <div className='main-introduce-application-info-content'>
              <p>화이트라벨링 지원</p>
              <pre>{'사업체에 고유의 앱이 필요하다면 앱 이름과 상표 등이\n변경된 모바일 앱의 배포와 관리를 지원합니다.'}</pre>
            </div>
            <div className='main-introduce-application-info-button'>상세문의</div>
          </div>
          <div className='main-introduce-phone-image-slider-container'>
            <div className='main-introduce-phone-image-slider-button'><BsChevronCompactLeft onClick={()=>{setMobileSliderIdx( mobileSliderIdx-1==-1?mobileSliderImageList.length-1:mobileSliderIdx-1 )}}/></div>
            <div className='main-introduce-phone-image-slider'>
              <img src={mobileSliderImageList[mobileSliderIdx]} alt="" />
            </div>
            <div className='main-introduce-phone-image-slider-button'><BsChevronCompactRight  onClick={()=>{setMobileSliderIdx( mobileSliderIdx+1==mobileSliderImageList.length?0:mobileSliderIdx+1 )}}/></div>
            <div className='main-introduce-phone-image-slider-indicator-container'>
              <div className={'main-introduce-phone-image-slider-indicator ' + `${mobileSliderIdx == 0 ? "selected":""}`} onClick={()=>{setMobileSliderIdx(0)}}></div>
              <div className={'main-introduce-phone-image-slider-indicator ' + `${mobileSliderIdx == 1 ? "selected":""}`} onClick={()=>{setMobileSliderIdx(1)}}></div>
              <div className={'main-introduce-phone-image-slider-indicator ' + `${mobileSliderIdx == 2 ? "selected":""}`} onClick={()=>{setMobileSliderIdx(2)}}></div>
              <div className={'main-introduce-phone-image-slider-indicator ' + `${mobileSliderIdx == 3 ? "selected":""}`} onClick={()=>{setMobileSliderIdx(3)}}></div>
              <div className={'main-introduce-phone-image-slider-indicator ' + `${mobileSliderIdx == 4 ? "selected":""}`} onClick={()=>{setMobileSliderIdx(4)}}></div>
              <div className={'main-introduce-phone-image-slider-indicator ' + `${mobileSliderIdx == 5 ? "selected":""}`} onClick={()=>{setMobileSliderIdx(5)}}></div>
              <div className={'main-introduce-phone-image-slider-indicator ' + `${mobileSliderIdx == 6 ? "selected":""}`} onClick={()=>{setMobileSliderIdx(6)}}></div>
              <div className={'main-introduce-phone-image-slider-indicator ' + `${mobileSliderIdx == 7 ? "selected":""}`} onClick={()=>{setMobileSliderIdx(7)}}></div>
            </div>
          </div>
        </div>


        <div className="main-introduce-text-title-block-container">
          <p>왜 공유형 CPO 서비스를 사용해야 할까?</p>
          <h2>
            충전사업에 필요한 다양한 컨설팅 및 산업표준 지원,<br/>
            차별화 된 기술 플랫폼 제공으로<br/>
            <color>누구나 쉽게 될 수 있는</color> 충전사업자.
          </h2>
        </div>
        {/* <div className="main-introduce-diagram-container">
          <img src={cpoService_diagram} alt="" />
        </div> */}

        <div className='main-introduce-spacer'></div>

        <div className="main-introduce-text-block-container">
          <h3>
            <color>EMOOV</color>와 함께합니다
          </h3>
          <p>여러 협력사들이 믿고 선택한 EMOOV, 언제나 신뢰할 수 있는 기술력으로 최선을 다하겠습니다.</p>
        </div>

        <div className='main-introduce-company-auto-slider'>
          <div className="main-introduce-slide-track front">
            <div className="main-introduce-company-auto-slider-item">
              <img src={LG_logo} alt="" />
            </div>
            <div className="main-introduce-company-auto-slider-item">
              <img src={SSGInC_logo} alt="" />
            </div>
            <div className="main-introduce-company-auto-slider-item">
              <img src={LG_logo} alt="" />
            </div>
            <div className="main-introduce-company-auto-slider-item">
              <img src={SSGInC_logo} alt="" />
            </div>
            <div className="main-introduce-company-auto-slider-item">
              <img src={LG_logo} alt="" />
            </div>
            <div className="main-introduce-company-auto-slider-item">
              <img src={SSGInC_logo} alt="" />
            </div>
          </div>
          <div className="main-introduce-slide-track back">
            <div className="main-introduce-company-auto-slider-item">
              <img src={LG_logo} alt="" />
            </div>
            <div className="main-introduce-company-auto-slider-item">
              <img src={SSGInC_logo} alt="" />
            </div>
            <div className="main-introduce-company-auto-slider-item">
              <img src={LG_logo} alt="" />
            </div>
            <div className="main-introduce-company-auto-slider-item">
              <img src={SSGInC_logo} alt="" />
            </div>
            <div className="main-introduce-company-auto-slider-item">
              <img src={LG_logo} alt="" />
            </div>
            <div className="main-introduce-company-auto-slider-item">
              <img src={SSGInC_logo} alt="" />
            </div>
          </div>
        </div>

        <div className='main-introduce-spacer'></div>

        <div className='main-introduce-go-to-subscription-container'>
          <pre>{'당신에게 딱 맞는 구독서비스를\n지금 선택해보세요.'}</pre>
          <div className='main-introduce-go-to-subscription-button' onClick={()=>{navigator("/service-pricing");}}>구독 상품 확인</div>
        </div>

      </div>
    </div>
  )
}
export default CpoService;



export const ImageBlock = (props)=>{
  return(
    <div className={'main-introduce-image-block-container '+`${props.oneLine ? "oneline":""}`}>
      <img src={props.data.imgUrl} />
      <>{props.data.content}</>
    </div>
  )
}


export const ServiceBlock = (props)=>{
  return(
    <div className={'main-introduce-service-block-container '}>
      <div className='main-introduce-service-block-image-header'>
        <img src={props.data.imgUrl} />
      </div>
      <div className='main-introduce-service-block-content-container'>
        <p>{props.data.title}</p>
        <pre>{props.data.content}</pre>
      </div>
    </div>
  )
}