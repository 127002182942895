import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'
import { useNavigate } from "react-router-dom";
import BreakdownItem from '../../components/list/BreakdownItem.js';

import { getBreakdownList, putBreakdownPostStatus, putBreakdown } from '../../api/cs/csApi.js';
import { getCpoId } from '../../util/util.js';
export const CpoBreakdown = () => {
  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();

  let cpoMemberId = getCpoId();

  // 진입 시 redux에 page 적용
  useEffect(()=>{
    dispatch(setNum({id:11, idx:3}));
  },[])

  let navigator = useNavigate();

  const [BreakdownList, setBreakdownList] = useState([]);
  
  const reqGetBreakdownList = async(cpoMemberId)=>{
    let result = await getBreakdownList(cpoMemberId);
    if(result.status === 200){
      setBreakdownList([...result.data]);
    }
    else{

    }
  }
  useEffect(()=>{
    reqGetBreakdownList(cpoMemberId);
  },[])
  return (
    <div className='cpo-customer-service-container'>
      <div className="cpo-customer-service-inner notice">
        <div className="cpo-notice-container">
          <div className={"cpo-notice-header "}>
            <div className='cpo-faq-tab-container'>
              <div className={"faq-tab-button selected"}><p>고장신고</p></div>
              <div className={'faq-tab-bar '+'tab0'}></div>
            </div>
          </div>
          <div className="cpo-notice-list-container">
            {
              BreakdownList.map((it, idx)=>{
                return(<BreakdownItem key={idx} data={it} reqGetBreakdownList={reqGetBreakdownList}/>);
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoBreakdown;