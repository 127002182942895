import React, { useState } from 'react';

import { TbDotsVertical } from "react-icons/tb";
import { FaRegTrashAlt } from "react-icons/fa";
import { FiEdit3 } from "react-icons/fi";

export const CpoInstallAdminItem = (props) => {
  const[btnOpen, setBtnOpen] = useState(false);
  return (
    <div className='cpo-install-list-item-admin-item'>
      <p>{props.data.adminName}</p>
      <p>{props.data.role}</p>
      <p>{props.data.phoneNumber}</p>
      <p>{props.data.department}</p>
      <div className="cpo-install-list-item-function-button-container" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); setBtnOpen(!btnOpen)}}>
        <div className={"cpo-install-list-item-function-button-list " + `${btnOpen ? "open":""}`}>
          <div>
            <div className="cpo-install-list-item-function-button" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
              <div><FiEdit3/></div>
            </div>
          </div>

          <div>
            <div className="cpo-install-list-item-function-button delete" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
              <div><FaRegTrashAlt/></div>
            </div>
          </div>

        </div>
        <TbDotsVertical/>
      </div>
    </div>
  )
}
export default CpoInstallAdminItem;