import React, { useRef, useState, useEffect } from 'react';
import { getCookie } from '../../util/util';
import { TfiClose } from "react-icons/tfi";
import Selector2 from '../buttons/Selector2';
import { numberInput } from '../../util/util';
import CloudAlertRight from '../alerts/CloudAlertRight.js'
import { postChargerModel } from '../../api/infra/infraApi';


export const CpoChargerModelEnrollModal = (props) => {
  
  const [companyCategory, setCompanyCategory] = useState(null);
  const [name, setName] = useState(null);
  const [chargerType, setChargerType] = useState(null);
  const [ChargingSpeed, setChargingSpeed] = useState(null);
  const [ChargingPower, setChargingPower] = useState(null);
  const [Voltage, setVoltage] = useState(null);
  const [ElectricCurrent, setElectricCurrent] = useState(null);
  
  const [ActualRegistrationDate, setActualRegistrationDate] = useState(null);
  
  const [Remarks, setRemarks] = useState(null);
  const [DetailedSpec, setDetailedSpec] = useState(null);
  

  const [companyCategoryActive, setCompanyCategoryActive] = useState();

  const [chargerTypeActive, setChargerTypeActive] = useState();

  const activeReset = ()=>{
    setCompanyCategoryActive(false);
    setChargerTypeActive(false);
  }

  let companyCategoryList=[
    {name:"업데이트 예정인 기능입니다."},
  ];
  let chargerTypeList=[
    {name:"고속"},
    {name:"중속"},
    {name:"저속"},
  ];

  const [checked0, setChecked0] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);



  const [IsUsed, setIsUsed] = useState(null);

  useEffect(()=>{
    switch (chargerType?.name) {
      case "고속":
        setChecked3(false);
        break;
      case "중속":
        setChecked3(false);
        break;
      case "저속":
        setChecked0(false);
        setChecked2(false);
        break;
      default:
        break;
    }
  },[chargerType])

  const [enrollState, setEnrollState] = useState(false);

  useEffect(()=>{
    if(companyCategory&&name&&chargerType&&ChargingSpeed&&ChargingPower&&Voltage&&ElectricCurrent&&ActualRegistrationDate&&(checked0||checked1||checked2||checked3) && (IsUsed!==null) ){
      setEnrollState(true);
    }
    else{
      setEnrollState(false);
    }
  })
  
  // 
  // "DC_Chademo", -DC차데모
  // "AC_Slow", - AC완속
  // "DC_Chademo_AC_ThreePhase", DC차데모+AC3상
  // "DC_Combo", - DC콤보
  // "DC_Chademo_DC_Combo", - DC차데모+DC콤보
  // "DC_Chademo_AC_ThreePhase_DC_Combo", DC차데모+AC3상+DC콤보
  // "AC_ThreePhase", AC3상
  // "DC_Combo_Slow" - DC콤보완속?


  const reqPostChargerModel = async()=>{
    let modelInfo = {
    "manufacturerId": companyCategory,
    "chargingSpeed": ChargingSpeed,
    "name": name,
    "chargerType": chargerType ==="고속"?"FAST_CHARGER":"SLOW_CHARGER",
    "chargingPower": ChargingPower,
    "voltage": Voltage,
    "electricCurrent": ElectricCurrent,
    "connectorType": "DC_Chademo", //!TODO 커넥터종류 Enum 관련 질문하기
    "actualRegistrationDate": new Date(ActualRegistrationDate),
    "isUsed": IsUsed,
    "imageIds": [],
    "remarks": Remarks,
    "detailedSpec": DetailedSpec
  }
    let result = await postChargerModel(modelInfo);
    console.log(result);
  }
  const [cloudAlertOn, setCloudAlertOn] = useState(false);
  useEffect(()=>{
    if(cloudAlertOn){
      setTimeout(() => {
        setCloudAlertOn(false);
      }, 2000);
    }
  },[cloudAlertOn])
  return (
    <div className="cpo-charger-model-enroll-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
      <div className="cpo-charger-model-enroll-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-charger-model-enroll-modal-header">
          <title>충전기모델 신규등록</title>
          <div className='cpo-charger-model-enroll-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className="cpo-charger-model-enroll-modal-content">
          <div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper'>
              <p className='cpo-charger-model-content-title required'>충전기 제조사 업체명 </p>
              <div className="cpo-charger-model-enroll-modal-content-selector-container">
                <Selector2 name={'name'} dataList={companyCategoryList} select={companyCategory} setSelect={setCompanyCategory} active={companyCategoryActive} setActive={setCompanyCategoryActive} activeReset={activeReset}/>
              </div>
            </div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper marginTop'>
              <p className='cpo-charger-model-content-title required'>모델이름</p>
              <div className="cpo-charger-model-enroll-modal-content-input-container">
                <input type="text" placeholder='모델명을 입력해주세요' value={name} onChange={(e)=>{setName(e.target.value)}} />
              </div>
            </div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper triple marginTop'>
              <div className='cpo-charger-model-enroll-modal-content-wrapper'>
                <p className='cpo-charger-model-content-title required'>출력</p>
                <div className="cpo-charger-model-enroll-modal-content-input-container postFix">
                  <input type="text" placeholder='0' onInput={(e)=>{numberInput(e.target)}} value={ChargingPower} onChange={(e)=>{setChargingPower(e.target.value)}} />
                  <p>kWh</p>
                </div>
              </div>
              <div className='cpo-charger-model-enroll-modal-content-wrapper'>
                <p className='cpo-charger-model-content-title required'>전압</p>
                <div className="cpo-charger-model-enroll-modal-content-input-container postFix">
                  <input type="text" placeholder='0' onInput={(e)=>{numberInput(e.target)}} value={Voltage} onChange={(e)=>{setVoltage(e.target.value)}} />
                  <p>V</p>
                </div>
              </div>
              <div className='cpo-charger-model-enroll-modal-content-wrapper'>
                <p className='cpo-charger-model-content-title required'>전류</p>
                <div className="cpo-charger-model-enroll-modal-content-input-container postFix">
                  <input type="text" placeholder='0' onInput={(e)=>{numberInput(e.target)}} value={ElectricCurrent} onChange={(e)=>{setElectricCurrent(e.target.value)}} />
                  <p>A</p>
                </div>
              </div>
            </div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper marginTop'>
              <p className='cpo-charger-model-content-title required'>등록년월</p>
            </div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper triple date'>
              <div className="cpo-charger-model-enroll-modal-content-input-container date">
                <input type="date" placeholder='날짜를 선택해주세요' onSubmit={()=>{return false}} value={ActualRegistrationDate} onChange={(e)=>{setActualRegistrationDate(e.target.value)}} onClick={(e)=>{e.stopPropagation();}} />
              </div>
            </div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper marginTop'>
              <p className='cpo-charger-model-content-title'>설명</p>
              <div className="cpo-charger-model-enroll-modal-content-textarea-container">
                <textarea type="text" placeholder='설명을 입력해주세요' value={Remarks} onChange={(e)=>{setRemarks(e.target.value)}} />
              </div>
            </div>
          </div>
          <div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper'>
              <p className='cpo-charger-model-content-title required'>충전속도</p>
              <div className="cpo-charger-model-enroll-modal-content-input-container postFix">
                <input type="text" placeholder='0' onInput={(e)=>{numberInput(e.target)}} value={ChargingSpeed} onChange={(e)=>{setChargingSpeed(e.target.value)}} />
                <p>kW</p>
              </div>
            </div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper marginTop'>
              <p className='cpo-charger-model-content-title required'>충전기 종류</p>
              <div className="cpo-charger-model-enroll-modal-content-selector-container">
                <Selector2 name={'name'} dataList={chargerTypeList} select={chargerType} setSelect={setChargerType} active={chargerTypeActive} setActive={setChargerTypeActive} activeReset={activeReset}/>
              </div>
            </div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper divide marginTop'>
              <div className='cpo-charger-model-enroll-modal-content-wrapper'>
                <p className='cpo-charger-model-content-title required'>커넥터 타입</p>
                <div className="cpo-charger-model-enroll-modal-checkbox-container">
                  <div>
                    <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                      <input className={`cpo-charger-model-check ${chargerType?.name==="저속" ? "none":""}`} type="checkbox" checked={checked0} onChange={()=>{setChecked0(!checked0)}} />
                      <span className="checkbox_icon"></span>
                    </label>
                    <p>DC차데모</p>
                  </div>
                  <div>
                    <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                      <input className={`cpo-charger-model-check `} type="checkbox" checked={checked1} onChange={()=>{setChecked1(!checked1)}} />
                      <span className="checkbox_icon"></span>
                    </label>
                    <p>AC3상</p>
                  </div>
                  <div>
                    <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                      <input className={`cpo-charger-model-check ${chargerType?.name==="저속" ? "none":""}`} type="checkbox" checked={checked2} onChange={()=>{setChecked2(!checked2)}} />
                      <span className="checkbox_icon"></span>
                    </label>
                    <p>DC콤보</p>
                  </div>
                  <div>
                    <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                      <input className={`cpo-charger-model-check ${chargerType?.name==="고속"||chargerType?.name==="중속" ? "none":""}`} type="checkbox" checked={checked3} onChange={()=>{setChecked3(!checked3)}} />
                      <span className="checkbox_icon"></span>
                    </label>
                    <p>AC완속</p>
                  </div>
                </div>
              </div>
              <div className='cpo-charger-model-enroll-modal-content-wrapper'>
                <p className='cpo-charger-model-content-title required'>중고여부</p>
                <div className="cpo-charger-model-enroll-modal-radio-container">
                  <div>
                    <div className='radio-button-container'>
                      <div className={'radio-button '+`${IsUsed == 0 ? "clicked":""}`} onClick={()=>{setIsUsed(0)}}></div>
                      <p>중고</p>
                    </div>
                  </div>
                  <div>
                    <div className='radio-button-container'>
                      <div className={'radio-button '+`${IsUsed == 1 ? "clicked":""}`} onClick={()=>{setIsUsed(1)}}></div>
                      <p>해당없음</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper marginTop'>
              <p>상세사양</p>
              <div className="cpo-charger-model-enroll-modal-content-textarea-container">
                <textarea type="text" placeholder='상세사양을 입력해주세요' value={DetailedSpec} onChange={(e)=>{setDetailedSpec(e.target.value)}} />
              </div>
            </div>
          </div>
          
          
          

        </div>
        <div className='cpo-charger-model-enroll-modal-button-container'>
          <div className={`cpo-charger-model-enroll-modal-button ${enrollState?"":"none"}`} onClick={()=>{setCloudAlertOn(true)}}>등록하기
          {cloudAlertOn&&<CloudAlertRight data={"업데이트 예정인 기능입니다."}/>}
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoChargerModelEnrollModal
