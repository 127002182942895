import axios from 'axios';

import { getCookie, signedOut } from '../../util/util';

export const getSendMagicLinkEmail = async(email)=>{
  const signedToken =  getCookie('signedToken')
  let temp;
  await axios.get(`/account/api/NearCpoAuth/send-magic-link-email?email=${email}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res.data;
  })
  .catch((err)=>{
    console.log(err);
  })
  return temp;
}

export const getSendEmailList = async()=>{
  const signedToken =  getCookie('signedToken')
  let temp;
  await axios.get(`/account/api/cpoadmin/CpoInvitations`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res.data;
  })
  .catch((err)=>{
    console.log(err);
  })
  return temp;
}

export const postSendEmail = async(email)=>{
  const signedToken =  getCookie('signedToken')
  let temp;
  await axios.post(`/account/api/cpoadmin/CpoInvitations`,{
    "invitedEmail": email
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    console.log(err);
    temp = err.response;
  })
  return temp;
}

export const postRemoveAccount = async(email)=>{
  const signedToken =  getCookie('signedToken')
  let temp;
  await axios.post(`/account/api/NearCpoAuth/remove-account`,{
    email
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    console.log(err);
    temp = err.response;
  })
  return temp;
}

export const postSignOut = async()=>{
  const signedToken =  getCookie('signedToken');
  let temp;
  await axios.post(`/account/api/NearCpoAuth/signout`,{},{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{temp=res})
  .catch((err)=>{temp=err.response})
  return temp;
}

export const deleteAccount = async(email)=>{
  const signedToken =  getCookie('signedToken');
  let temp;
  await axios.delete(`/account/api/cpouser/Account`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then(async(res)=>{
    temp = res;
    await signedOut();
  })
  .catch((err)=>{
    console.log(err);
    temp = err.response;
  })
  return temp;
}


// CPO관리자 회원가입
export const postSignUpCpoAdmin = async(data)=>{
  let temp;
  let {email, password, businessRegistrationNumber, businessName, managerPhoneNumber, managerName} = data;
  await axios.post("/account/api/NearCpoAuth/CpoSignUp", 
  {
    "email": email,
    "password": password,
    "businessRegistrationNumber": businessRegistrationNumber,
    "businessName": businessName,
    "managerPhoneNumber": managerPhoneNumber,
    "managerName": managerName 
  },{withCredentials : true})
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

export const postSignUpCpoUser = async(data)=>{
  let temp;
  let {email, password, realName} = data;
  await axios.post("/account/api/NearCpoAuth/personal-signup", 
  {
    "email": email,
    "password": password,
    "realName": realName 
  },{withCredentials : true})
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}