import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from "react-redux"
import {setNum} from '../../store.js'
import { numberInput } from '../../util/util';

import { getStationList, getChargerByStationId } from '../../api/infra/infraApi.js';
import { getChargerHub } from '../../api/controlhub/controlhubApi.js';

import { exceptDeletedStation } from '../../util/util';
import { MdInfoOutline } from "react-icons/md";

import SearchSelectorNotCreatable from '../../components/buttons/SearchSelectorNotCreatable.js'
import { timestampFormat, dateFormat2 } from '../../util/util';
// import ChargingFeeStationItem from '../../components/list/ChargingFeeStationItem.js';
// import ChargingFeeSearchModal from '../../components/modals/ChargingFeeSearchModal.js';
import ChargingFeeSettingModal from '../../components/modals/ChargingFeeSettingModal.js';
import SettingDefaultChargingRateModal from '../../components/modals/SettingDefaultChargingRateModal.js';
import { Loading } from '../../components/alerts/Loading.js';

import { patchDefaultPricingPlan, putDefaultPricingPlan, getDefaultPricingPlan, getAllPricingEvent } from '../../api/chargerate/chargerateApi.js';

import { Tooltip } from 'react-tooltip'
import { RiDeleteBin2Line } from "react-icons/ri";
import { FaArrowRotateLeft } from "react-icons/fa6";

const CpoChargingFeeManagement = (props) => {
  // redux
  let dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setNum({id:6}));
  },[dispatch])

  // 충전소 데이터 Array<Object>
  const [stationInfo, setStationInfo]=useState(null);
  // 충전기 데이터 Array<Object>
  const [chargerInfo, setChargerInfo]=useState(null);
  // 충전기 controlhub 데이터 Array<Object>
  const [chargerHubInfo, setChargerHubInfo]=useState(null);

  useEffect(()=>{
    // getDefaultRate();
    // getChargerRateList();
    reqGetDefaultPricingPlan();
    reqGetStationList();
  },[])


  // cpo가 소유한 station GET
  const reqGetStationList = async()=>{  
    let result = await getStationList();
    if(result.status === 200){
      let temp = exceptDeletedStation(result);
      setStationInfo(temp);
    }
  }

  // chargerHub 정보 API GET
  const reqGetChargerHubList = async()=>{
    let result = [];
    for await(const it of stationInfo){
      let temp = await getChargerHub(it.ChargingStationId);
      result.push(temp.data);
    }
    setChargerHubInfo(result);
  }

  // charger 정보 API GET
  const reqGetChargerList = async()=>{
    let result = [];
    for await(const it of stationInfo){
      let temp = await getChargerByStationId(it.ChargingStationId);
      result.push(temp.data); 
    }
    setChargerInfo(result);
  }

  // station 데이터 받은 후 chargerHub, charger 정보 GET 트리거
  useEffect(() => {
    if(!stationInfo)return;
    reqGetChargerHubList();
    reqGetChargerList();
  }, [stationInfo])




  const [chargerRateList, setChargerRateList] = useState([]);


  const [membershipRate, setMembershipRate] = useState();
  const [nonMembershipRate, setNonMembershipRate] = useState();
  
  const [initMembershipRate, setInitMembershipRate] = useState();
  const [initNonMembershipRate, setInitNonMembershipRate] = useState();

  const [membershipRateUnitList, setMembershipRateUnitList] = useState();
  const [nonMembershipRateUnitList, setNonMembershipRateUnitList] = useState();

  const [modalOff, setModalOff] = useState(false);
  
  const [defaultModalOn, setDefaultModalOn] = useState(false);

  const [moreInfoOn, setMoreInfoOn] = useState(false);



  let a=[
    {
        "month": 5,
        "hour": 14,
        "unitPrice": 380.3
    },
    {
        "month": 11,
        "hour": 15,
        "unitPrice": 380.3
    },
    {
        "month": 11,
        "hour": 14,
        "unitPrice": 380.3
    },
    {
        "month": 5,
        "hour": 16,
        "unitPrice": 380.3
    },
    {
        "month": 5,
        "hour": 15,
        "unitPrice": 380.3
    },
    {
        "month": 11,
        "hour": 16,
        "unitPrice": 380.3
    }
]

  const [tabIdx, setTabIdx] = useState(0)
  // 클릭 이벤트 함수
  const tabClick = (idx)=>{
    if(tabIdx !== idx){
      setTabIdx(idx);
    }
  }

  const month = [1,2,3,4,5,6,7,8,9,10,11,12];
  const hour = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23];
  // 24x12 테이블을 구성
  const cols = 12;
  const rows = 24;

  // 각 셀을 관리할 ref 배열 생성 (배열로 초기화)
  const cellRefs = useRef(Array(rows * cols).fill(null));

  // 셀 클릭 시 호출되는 함수

  useEffect(()=>{
    if(tabIdx === 0 && membershipRateUnitList){
      console.log(membershipRateUnitList);
      fillCell();
    }
    else if(tabIdx === 1 && nonMembershipRateUnitList){
      console.log(nonMembershipRateUnitList)
      fillCell();
    }
  },[tabIdx, membershipRateUnitList, nonMembershipRateUnitList])
  
  // const fillCell = async()=>{
  //   let target;
  //   let pivot;
  //   if(tabIdx === 0){
  //     target = membershipRateUnitList;
  //     pivot = initMembershipRate;
  //   }
  //   else{
  //     target = nonMembershipRateUnitList;
  //     pivot = initNonMembershipRate;

  //   }
  //   for await(let item of cellRefs.current){
  //     item.innerText = "";
  //     item.classList = "cell";
  //   }
  //   for await(let item of target){
  //     if(parseFloat(item.unitPrice) > parseFloat(pivot)){
  //       cellRefs.current[parseInt((item.hour-1)* 12 + (item.month-1))].classList += " high";
  //       const childElement = document.createElement("Tooltip");
  //       <Tooltip anchorSelect={`.cell.row-${row}.col-${col}`} clickable>
  //             <button>You can click me!</button>
  //           </Tooltip>
  //     }
  //     else{
  //       cellRefs.current[parseInt((item.hour-1)* 12 + (item.month-1))].classList += " low";
  //     }
  //     cellRefs.current[parseInt((item.hour-1)* 12 + (item.month-1))].innerText = (item.unitPrice).toString();
  //   }
  // }

  const fillCell = async () => {
    let target;
    let pivot;

    if (tabIdx === 0) {
      target = membershipRateUnitList;
      pivot = initMembershipRate;
    } else {
      target = nonMembershipRateUnitList;
      pivot = initNonMembershipRate;
    }

    const newCellState = Array(rows * cols).fill(null); // 상태 초기화

    for (let item of target) {
      const index = parseInt((item.hour) * 12 + (item.month - 1));
      newCellState[index] = {
        unitPrice: item.unitPrice,
        month: item.month,
        hour: item.hour,
        className: parseFloat(item.unitPrice) > parseFloat(pivot) ? `high month-${item.month} hour-${item.hour}` : `low month-${item.month} hour-${item.hour}`,
        hasTooltip: parseFloat(item.unitPrice) !== parseFloat(pivot), // 조건에 따라 Toggle 추가
      };
    }

    setCellState(newCellState);
  };
  
  const [cellState, setCellState] = useState(Array(rows * cols).fill(null)); // 각 셀의 상태 저장

  
  
  // 기본요금 세팅
  const [loadingOn, setLoadingOn] = useState(false);

  const reqGetDefaultPricingPlan = async()=>{
    let result = await getDefaultPricingPlan();
    if(result.status === 200){
      setMembershipRate(result.data.memberPlan?.defaultUnitPrice);
      setNonMembershipRate(result.data.nonMemberPlan?.defaultUnitPrice);
      setInitMembershipRate(result.data.memberPlan?.defaultUnitPrice);
      setInitNonMembershipRate(result.data.nonMemberPlan?.defaultUnitPrice);
      setMembershipRateUnitList(result.data.memberPlan?.unitPrices);
      setNonMembershipRateUnitList(result.data.nonMemberPlan?.unitPrices);
    }
    else {
    }
    console.log(result);
  }

  const reqPatchDefaultPricingPlan = async()=>{
    setLoadingOn(true);
    let reqObj = {
      "pricingType": tabIdx===0?"Member":"NonMember",
      "currency": "KRW",
      "defaultUnitPrice": tabIdx===0?membershipRate:nonMembershipRate
    }
    console.log(reqObj);

    let result = await patchDefaultPricingPlan(reqObj);
    if(result.status === 200){
      setSaveResultMsg("저장되었습니다.");
      setTimeout(() => {
        setSaveResultMsg("");
      }, 3000);
    }
    else if(result.status === 400){
      if(result.data.ErrorCode ==="PLAN_NOT_EXIST"){
        await reqPutDefaultPricingPlan();
      }
    }
    reqGetDefaultPricingPlan();
    setLoadingOn(false);
  }
  const reqPutDefaultPricingPlan = async()=>{
    setLoadingOn(true);
    let reqObj = {
      "pricingType": tabIdx===0?"Member":"NonMember",
      "currency": "KRW",
      "defaultUnitPrice": tabIdx===0?membershipRate:nonMembershipRate,
      "unitPrices":[]
    }

    let result = await putDefaultPricingPlan(reqObj);
    if(result.status === 200){
      setSaveResultMsg("저장되었습니다.");
      setTimeout(() => {
        setSaveResultMsg("");
      }, 3000);
    }
    else if(result.status === 400){
      console.log(result);
      
    }
    reqGetDefaultPricingPlan();
    setLoadingOn(false);
  }

  const [saveResultMsg, setSaveResultMsg] = useState("");


  // 이벤트요금 관리
  useEffect(()=>{
    reqGetAllPricingEvent();
  },[])

  const [pricingEventList, setPricingEventList] = useState([]);
  useEffect(()=>{
    if(!pricingEventList)return;
    getLabelList();
  },[pricingEventList])

  const reqGetAllPricingEvent = async()=>{
    let result = await getAllPricingEvent();
    if(result.status === 200){ 
      console.log(result);
      setPricingEventList(result.data?.results);
    }
    else{
      console.log(result);
    }
  }

  // 충전기 selector label 리스트
  const [labelList, setLabelList] = useState();

  // label 리스트 get
  const getLabelList = ()=>{
    let temp = [];
    pricingEventList.map((it, idx)=>{
      let val = it.name;
      temp.push({value: val, label: val, idx:idx});
    })
    setLabelList(temp);
  }


  // selector에서 클릭한 label (Station)
  const [dataLabel, setDataLabel] = useState();

  
  const [selected, setSelected] = useState(0);
  useEffect(()=>{
    if(labelList){
      setDataLabel(labelList[0])
    }
  },[labelList])
  useEffect(()=>{
    if(dataLabel){
      setSelected(dataLabel.idx)
      console.log(dataLabel);
    }
  },[dataLabel]) 


  // 초기화 
  const reqPutDefaultPricingPlanForInitial = async()=>{
    setLoadingOn(true);
    let reqObj = {
      "pricingType": tabIdx===0?"Member":"NonMember",
      "currency": "KRW",
      "defaultUnitPrice": tabIdx===0?membershipRate:nonMembershipRate,
      "unitPrices":[]
    }

    let result = await putDefaultPricingPlan(reqObj);
    if(result.status === 200){
      // setSaveResultMsg("저장되었습니다.");
      // setTimeout(() => {
      //   setSaveResultMsg("");
      // }, 3000);
    }
    else if(result.status === 400){
      // console.log(result);
      
    }
    reqGetDefaultPricingPlan();
    setLoadingOn(false);
  }
  
  // 초기화 
  const reqPutDefaultPricingPlanForDelete = async(month, hour)=>{
    setLoadingOn(true);
    let unitList = [];
    if(tabIdx===0){
      unitList = membershipRateUnitList.filter(it=>(it.month!==month || it.hour!== hour))
    }
    else{
      unitList = nonMembershipRateUnitList.filter(it=>it.month!==month || it.hour!== hour)
    }
    let reqObj = {
      "pricingType": tabIdx===0?"Member":"NonMember",
      "currency": "KRW",
      "defaultUnitPrice": tabIdx===0?membershipRate:nonMembershipRate,
      "unitPrices":unitList
    }
    

    let result = await putDefaultPricingPlan(reqObj);
    if(result.status === 200){
      // setSaveResultMsg("저장되었습니다.");
      // setTimeout(() => {
      //   setSaveResultMsg("");
      // }, 3000);
    }
    else if(result.status === 400){
      // console.log(result);
      
    }
    reqGetDefaultPricingPlan();
    setLoadingOn(false);
  }




  return (
    <div className="cpo-charging-fee-management-page-container">
      {modalOff &&
        <ChargingFeeSettingModal
        // getChargerRateList={getChargerRateList}
        reqGetAllPricingEvent={reqGetAllPricingEvent}
        chargerRateList={chargerRateList} stationInfo={stationInfo} chargerInfo={chargerInfo} chargerHubInfo={chargerHubInfo} setOnOff={setModalOff} 
      />}
      {defaultModalOn&&<SettingDefaultChargingRateModal setOnOff={setDefaultModalOn} pricingType={tabIdx===0 ? "Member":"NonMember"} reqGetDefaultPricingPlan={reqGetDefaultPricingPlan}/>}

      <div className="cpo-charging-fee-management-inner">
        <div className="cpo-charging-fee-management-container">
          <div>
            <div className="cpo-charging-fee-management-header">
              <div className='cpo-charging-fee-management-more-info'>more info<MdInfoOutline onClick={()=>{setMoreInfoOn(!moreInfoOn)}}/></div>
              {moreInfoOn&&<ChargingFeeMoreInfo/>}
              <div className='cpo-charging-fee-management-title-container'>
                <title>기본 충전요금 관리</title>
                <p>(원 kWh, 부가세 포함)</p>
              </div>
              {/* <div className='cpo-charging-fee-management-event-period-setting-button' onClick={()=>{setModalOff(true)}}><p>이벤트 기간 설정</p></div> */}
            </div>
            <div className='cpo-charging-fee-management-rate-header'>
              <div className='cpo-charging-fee-management-tab-container'>
                <div className={tabIdx === 0 ? "charging-fee-management-tab-button selected":"charging-fee-management-tab-button"} onClick={()=>{tabClick(0)}}><p>회원</p></div>
                <div className={tabIdx === 1 ? "charging-fee-management-tab-button selected":"charging-fee-management-tab-button"} onClick={()=>{tabClick(1)}}><p>비회원</p></div>
                <div className={'charging-fee-management-tab-bar '+'tab'+tabIdx}></div>
              </div>
              
            </div>
            <div>
              <p className='cpo-charging-fee-management-content-title default'>기본요금 설정 
                <color>{saveResultMsg}</color>
              </p>
              
              <div className='cpo-charging-fee-management-input-wrapper'>
                {
                  tabIdx === 0 ?
                  <>
                  <div className="cpo-setting-default-charging-rate-input-container">
                    <input type="text" placeholder='요금을 입력해 주세요' onInput={(e)=>{numberInput(e.target)}} value={membershipRate} onChange={(e)=>{setMembershipRate(e.target.value)}} />
                  </div>
                  <div className={`cpo-setting-default-charging-rate-input-save-button ${parseFloat(initMembershipRate) !== parseFloat(membershipRate) ?"":"none"}`} onClick={()=>{reqPatchDefaultPricingPlan();}}>
                    {loadingOn ? <Loading/>:"저장"}
                  </div>
                  </>
                  :
                  <>
                  <div className="cpo-setting-default-charging-rate-input-container">
                    <input type="text" placeholder='요금을 입력해 주세요' onInput={(e)=>{numberInput(e.target)}} value={nonMembershipRate} onChange={(e)=>{setNonMembershipRate(e.target.value)}} />
                  </div>
                  <div className={`cpo-setting-default-charging-rate-input-save-button ${parseFloat(initNonMembershipRate) !== parseFloat(nonMembershipRate) ?"":"none"}`} onClick={()=>{reqPatchDefaultPricingPlan();}}>
                    {loadingOn ? <Loading/>:"저장"}
                  </div>
                  </>
                }
                

              </div>
            </div>
            <div className='cpo-charging-fee-management-content-header'>
              <p className='cpo-charging-fee-management-content-title'>시간별 설정 <color>설정되지 않은 셀은 기본요금이 적용됩니다.</color></p>
              <div className='cpo-charging-fee-management-rate-setting-button-wrapper'> 
                <div className='cpo-charging-fee-management-rate-initial-button' onClick={()=>{reqPutDefaultPricingPlanForInitial();}}><FaArrowRotateLeft/>초기화</div>
                <div className='cpo-charging-fee-management-rate-setting-button' onClick={()=>{setDefaultModalOn(true)}}><p>시간별 요금 설정</p></div>
              </div>
            </div>
            <div className='cpo-charging-fee-management-calendar-container'>
              <div className='cpo-charging-fee-management-calendar-row'>
                <div></div>
                {hour.map((it, idx)=>{
                  return(<div>{`${it} - ${it+1}시`}</div>)
                })}
              </div>
              <div>
                <div className="cpo-charging-fee-management-calendar-column">
                  {month.map((it, idx)=>{
                    return(<div>{`${it}월`}</div>)
                  })}
                </div>
                <div className="cpo-charging-fee-management-calendar-table-container">
                  {/* {renderCells()} */}
                  {Array.from({ length: rows * cols }).map((_, index) => {
                    const cellData = cellState[index]; // 상태에서 셀 데이터를 가져옴

                    return (
                      <div
                        key={index}
                        ref={(el) => (cellRefs.current[index] = el)} // 각 셀의 참조 저장
                        className={`cell ${cellData?.className || ""}`} // 상태 기반 클래스 추가
                      >
                        {cellData?.unitPrice}
                        {cellData?.hasTooltip && <Tooltip border="2px solid var(--textRed1)" anchorSelect={`.cell.month-${cellData?.month}.hour-${cellData?.hour}`} className='tooltip' clickable>
                          <div className='tooltip-content' onClick={()=>{console.log(membershipRateUnitList);console.log(cellData);reqPutDefaultPricingPlanForDelete(cellData?.month, cellData?.hour);}}>
                            <div>
                              <RiDeleteBin2Line/>
                            </div>
                            삭제하기
                          </div>
                        </Tooltip>} {/* 조건에 따라 Toggle 컴포넌트 추가 */}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

          </div>
        </div>
        
      </div>
    </div>
  )
}
export default CpoChargingFeeManagement;


const ChargingFeeMoreInfo = ()=>{
  return(
    <>
      <div className='cpo-charging-fee-more-info-modal-container'>
        <div className="cpo-charging-fee-management-table">
          <div className='cpo-charging-fee-management-table-main-title'>회원가</div>
          <div className='cpo-charging-fee-management-table-title column'>여름철(6월~8월)</div>
          <div className='cpo-charging-fee-management-table-title column'>봄/가을철</div>
          <div className='cpo-charging-fee-management-table-title column'>겨울철(11월~2월)</div>

          <div className='cpo-charging-fee-management-table-title'>경부하시간대</div>
          <div className='cpo-charging-fee-management-table-cell'>23~09시</div>
          <div className='cpo-charging-fee-management-table-cell'>23~09시</div>
          <div className='cpo-charging-fee-management-table-cell'>23~09시</div>

          <div className='cpo-charging-fee-management-table-title'>중간부하시간대</div>
          <div className='cpo-charging-fee-management-table-cell'>09~10시, 12~13시, 17~23시</div>
          <div className='cpo-charging-fee-management-table-cell'>09~10시, 12~13시, 17~23시</div>
          <div className='cpo-charging-fee-management-table-cell'>09~10시, 12~17시, 20~22시</div>
          
          <div className='cpo-charging-fee-management-table-title'>최대부하시간대</div>
          <div className='cpo-charging-fee-management-table-cell'>10~12시, 13~17시</div>
          <div className='cpo-charging-fee-management-table-cell'>10~12시, 13~17시</div>
          <div className='cpo-charging-fee-management-table-cell'>10~12시, 17~20시, 22~23시</div>
        </div>
      </div>
    </>
  );
}