import React from 'react';
import { useNavigate } from 'react-router-dom';

import { TfiClose } from "react-icons/tfi";

export const ChargerLimitAlert = (props) => {
  let navigator = useNavigate();

  return (
    <div className="cpo-charger-enroll-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); }}>
      <div className="cpo-charger-enroll-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-charger-enroll-modal-header">
          <title>충전기 등록</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <>
          <div className='cpo-charger-enroll-modal-alert-msg'>
            등록 가능한 충전기 수 한계에 도달하였습니다.
          </div>
          <div className='cpo-charger-enroll-modal-grid'>
            <div>
              <p>등록된 충전기 수</p>
              <p>{props.total}</p>
            </div>
            <div>
              <p>최대 충전기 수</p>
              <p>{props.limit}</p>
            </div>
          </div>
          <div>
            충전기를 삭제하거나 상위 구독으로 등록 개수를 늘려주세요.
          </div>
          <div>
            <div></div>
            <div className={'cpo-charger-modal-enroll-button subscription'} onClick={()=>{navigator("/cpo/subscription");}}><p>구독상품 보러가기</p></div>
          </div>
          </>
      </div>
    </div>
  )
}
export default ChargerLimitAlert