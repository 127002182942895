import { useState, useRef, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'
import {chargerTypeConverter, connectorTypeConverter} from "../../util/util.js"

import Selector2 from '../buttons/Selector2.js';


const ChargerDetailTab2 = (props) => {
  // 선택자 클릭 여부
  const [ChargerModel, setChargerModel] = useState('');
  const [ChargerModelVal, setChargerModelVal] = useState('');
  const [ChargerModelActive, setChargerModelActive] = useState(null);
  
  // 선택자 active reset
  const activeReset = ()=>{
    setChargerModelActive(false);
  }

  const [modelInfo, setModelInfo] = useState(null);
  
  useEffect(()=>{
    if(!ChargerModel)return;
    setModelInfo(props.modelList.filter(it=>it.Id == ChargerModel)[0]);
  },[ChargerModel])

  useEffect(()=>{
    if(!props.open)return;
    let temp = localStorage.getItem(`${props.number}`+'_ChargerDetailTab2');
    if(temp){
      temp = JSON.parse(temp);
      setChargerModel(JSON.parse(temp['ChargerModel']));
      setChargerModelVal(props.modelList.filter(it=>it.Id == JSON.parse(temp['ChargerModel']))[0]);
    }
  },[])
  useEffect(()=>{
    if(!props.open)return;
    if(!props.data?.ChargerModel)return;
    let temp = localStorage.getItem(`${props.number}`+'_ChargerDetailTab2');
    if(!temp){
      setChargerModel(props.data?.ChargerModel?.Id);
      setChargerModelVal(props.modelList.filter(it=>it?.Id == props.data?.ChargerModel?.Id)[0]);
    }
  },[])

  useEffect(()=>{
    if(!props.open)return;
    if(ChargerModel=='')return;
    if(ChargerModel==props.data?.ChargerModel?.Id)return;
    localStorage.setItem(`${props.number}`+'_ChargerDetailTab2', JSON.stringify( {ChargerModel:JSON.stringify(ChargerModel)}))
    props.setSaveBtnStatus(true);
  },[ChargerModel])

  useEffect(()=>{
    if(!ChargerModelVal)return;
    setChargerModel(ChargerModelVal.Id);
  },[ChargerModelVal])
  

  return (
    <div className='charger-detail-tab tab2'>
      <div>
        <div className='charger-detail-tab-selector-container'>
          <p className='charger-input-title' onClick={()=>{console.log(ChargerModel)}}>충전기 모델</p>
          <Selector2 append={true} name={"Name"} dataList={props.modelList} select={ChargerModelVal} setSelect={setChargerModelVal} active={ChargerModelActive} setActive={setChargerModelActive} activeReset={activeReset}/>
        </div>
        <div>
          <p className='charger-input-title' onClick={()=>{console.log(ChargerModel)}}>기본 정보</p>
          <div className='charger-detail-info-flex-wrapper'>
            <div className="charger-detail-info-container chargerTab2 longer">
              <p>충전기 종류</p>
              <div>
                <p className='cpo-charger-detail-info-chargerType'>{chargerTypeConverter(modelInfo?.ChargerType)}</p>
              </div>
            </div>
            <div className="charger-detail-info-container chargerTab2 longer">
              <p>커넥터 타입</p>
              <div className='charger-detail-info-connectorType'>
              {
                ChargerModelVal&&connectorTypeConverter(ChargerModelVal?.ConnectorType)?.map((it)=>{
                  return(
                    <div className="cpo-charger-detail-info-connector-badge">
                      <p onDrag={(e)=>{e.stopPropagation();}}>{it}</p>
                    </div>
                  )
                })
              }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p className='charger-input-title'>기기 정보</p>
        <div className='charger-model-info-grid'>
          <div className="charger-detail-info-container chargerTab2">
            <p>속도</p>
            <div>
              <p>{modelInfo?.ChargingSpeed}</p>
              <p className='postFix'>kWh</p>
            </div>
          </div>
          <div className="charger-detail-info-container chargerTab2">
            <p>출력</p>
            <div>
              <p>{modelInfo?.ChargingPower}</p>
              <p className='postFix'>kW</p>
            </div>
          </div>
          <div className="charger-detail-info-container chargerTab2">
            <p>전압</p>
            <div>
              <p>{modelInfo?.Voltage}</p>
              <p className='postFix'>V</p>
            </div>
          </div>
          <div className="charger-detail-info-container chargerTab2">
            <p>전류</p>
            <div>
              <p>{modelInfo?.ElectricCurrent}</p>
              <p className='postFix'>A</p>
            </div>
          </div>

          
        </div>
      </div>
      <div>
        <p className='charger-input-title'>상세 정보</p>
        <div className="charger-detail-info-container chargerTab2 longer">
          <p>등록년월</p>
          <div>
            <p>{modelInfo?.ActualRegistrationDate}</p>
          </div>
        </div>
        <div className="charger-detail-info-container chargerTab2 longer">
          <p>설명</p>
          <p>{modelInfo?.Remarks ? modelInfo?.Remarks:"-"}</p>
        </div>
        
      </div>
      <div>
        <div className="charger-detail-info-textarea-container">
          <p className='charger-input-title'>상세스펙</p>
          <textarea value={modelInfo?.DetailedSpec} readOnly/>
        </div>
      </div>
    </div>
  )
}
export default ChargerDetailTab2;
