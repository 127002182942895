import { useState, useRef, useEffect} from 'react';
import axios from 'axios';
import {setCookie, getCookie} from "../../util/util.js"
import { useLocation } from 'react-router-dom';

import Lottie from "react-lottie";
import animationSplash from '../../lotties/loading2.json'

export const AccountVerification = (props) => {
  const location = useLocation();
  const email = location.state.email;
  const [resendSuccess, setResendSuccess] = useState(false);
  const [resendError, setResendError] = useState(false);

  const [loadingOn, setLoadingOn] = useState(false);

  const resend = async()=>{
    setResendSuccess(false);
    setResendError(false);
    setLoadingOn(true);
    await axios.get(`/account/api/NearCpoAuth/resend-confirmation-email?email=${email}`,
    {},{withCredentials : true})
    .then((res)=>{
      console.log(res);
      setResendSuccess(true);
    })
    .catch((err)=>{
      setResendError(true);
    })
    setLoadingOn(false);
  }
  return (
    <>

      <div className='sign-up-page-container'>
        <div className="sign-up-page-wrapper">
          <title>이메일 인증</title>
          <div style={{textAlign: "center", fontSize: "1rem", marginTop:"50px"}}>
            {"<"+email+">"}로 인증 메일을 전송하였습니다. 인증 후 서비스를 이용해주세요!
          </div>
          <div className='email-verification-button-container'>
            {resendSuccess&&<p>인증 메일을 재전송하였습니다.</p>}
            {resendError&&<p className='error'>인증 메일 전송을 실패하였습니다. 다시 시도해주세요.</p>}
            <div className='email-verification-resend-button' onClick={()=>{resend()}}>
              {loadingOn ? <Loading/> : <p>재전송</p>}

            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default AccountVerification;



function Loading() {
  const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationSplash,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};	
return (
  <Lottie options={defaultOptions} />
)
}