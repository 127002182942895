import React, { useState } from 'react';

import SignUpTab1 from '../../components/tabs/SignUpTab1';
import SignUpTab2 from '../../components/tabs/SignUpTab2';
import SignUpTab3 from '../../components/tabs/SignUpTab3';

export const SingUp = () => {
  const RenderTab = ({idx})=>{
    return[<SignUpTab1 setStep={setStep} setTarget={setTarget}/>,<SignUpTab2 setEmail={setEmail} setStep={setStep} target={target}/>,<SignUpTab3 email={email} setStep={setStep}/>][idx];
  }  

  const [email, setEmail] = useState(null);

  const [step, setStep] = useState(0);
  const [target, setTarget] = useState(null);

  return (
    <div className='sign-up-page-container'>
      <div className="sign-up-page-wrapper">
        <title>회원가입</title>
        <div className='sign-up-step-container'>
          <div className="sign-up-step-wrapper">
            <div className={"sign-up-step " + `${step===0 ? 'current' : 'done' }`}><p>1</p><p className='sign-up-step-info'>가입 유형 선택</p></div>
            <div className={"sign-up-step " + `${step===1 ? 'current' : step===0?"":"done" }`}><p>2</p><p className='sign-up-step-info'>정보 입력</p></div>
            <div className={"sign-up-step " + `${step===2 ? 'current':""}`}><p>3</p><p className='sign-up-step-info'>가입 완료</p></div>
            <div className='sign-up-dash'></div>
          </div>
        </div>
        <RenderTab idx={step}/>
        {/* <p className="sign-up-input-title">이메일</p>
        <div className='sign-up-input-container email'><input placeholder='이메일을 입력하세요' type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}}/></div>
        <p className="sign-up-input-title">비밀번호</p>
        <div className='sign-up-input-container password'><input placeholder='비밀번호를 입력하세요' type={passwordVisible ? "text" : "password"} value={password} onChange={(e)=>{setPassword(e.target.value)}}/> {passwordVisible ? <IoMdEyeOff onClick={()=>setPasswordVisible(false)}/> : <IoMdEye onClick={()=>setPasswordVisible(true)}/>}</div> */}

        
        
      </div>
    </div>
  )
}
export default SingUp;

