import axios from 'axios';
import { getCookie } from '../../util/util';

const signedToken =  getCookie('signedToken')

// 카드 조회
export const getCardList = async(id)=>{
  const signedToken =  getCookie('signedToken')
  let temp;
  await axios.get(`/payment/api/cpouser/PaymentCards`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 카드 등록
export const postCard = async(data)=>{
  const signedToken =  getCookie('signedToken')
  let temp;
  let {cardNumber, cardYY, cardMM, cvc, customerIdentityNumber, partialPassword, customerName, customerEmail, cardName, defaultCard} = data;
  await axios.post(`/payment/api/cpouser/PaymentCards`,
    {
      "cardNumber": cardNumber,
      "cardYY": cardYY,
      "cardMM": cardMM,
      "cvc": cvc,
      "customerIdentityNumber": customerIdentityNumber,
      "partialPassword": partialPassword,
      "customerName": customerName,
      "customerEmail": customerEmail,
      "cardName": cardName,
      "defaultCard": defaultCard
    },
    {
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    }
  )
  .then((res)=>{temp = res})
  .catch((err)=>{temp = err.response})
  return temp;
}

// 카드 삭제
export const deleteCard = async(id)=>{
  let temp;
  await axios.delete(`/payment/api/cpouser/PaymentCards/${id}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 카드 이름 수정
export const putCardName = async(id, name)=>{
  let temp;
  await axios.put(`/payment/api/cpouser/PaymentCards/${id}`,{
    name : name
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  
  return temp;
}

// 기본 결제 카드 변경
export const putDefaultCard = async(id)=>{
  let temp;
  await axios.put(`/payment/api/cpouser/PaymentCards/${id}/DefaultCard`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  
  return temp;
}