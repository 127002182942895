import React from 'react'
import Select from 'react-select'

export const SearchSelectorNotCreatable = (props) => {

  return (
    <Select options={props.options}
    {...props}
    className="react-select-container"
    classNamePrefix="react-select"
    value={props.value}
    onChange={(newValue) => props.setValue(newValue)}
    />
  )
}
export default SearchSelectorNotCreatable;